import { useFetchWrapper } from '../_helpers'

export { useImageActions }

function useImageActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`
    const fetchWrapper = useFetchWrapper()

    return {
        get,
        getLatestMachineCameraImage,
    }

    function get(name: string) {
        return fetchWrapper.get(`${baseUrl}/images/${name}`)
    }
    function getLatestMachineCameraImage(machine: string, camera: number) {
        return fetchWrapper.get(`${baseUrl}/images/${machine}/${camera}/latest?t=${new Date().getTime()}`)
    }
}
