import React from 'react'
import { Stack, Button } from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'

interface IncrementDecrementButtonsProps {
    onIncrement: () => void
    onDecrement: () => void
    disableIncrement: boolean
    disableDecrement: boolean
    loading: boolean
}

const IncrementDecrementButtons: React.FC<IncrementDecrementButtonsProps> = ({
    onIncrement,
    onDecrement,
    disableIncrement,
    disableDecrement,
    loading,
}) => {
    return (
        <Stack spacing={2} direction='row' alignItems='center' sx={{ mt: 1 }}>
            <Button
                disabled={disableDecrement || loading}
                variant='contained'
                color='secondary'
                sx={{
                    height: '40px',
                    width: '100%',
                }}
                endIcon={<RemoveCircleOutlineIcon fontSize='large' />}
                onClick={onDecrement}
            >
                DEC
            </Button>
            <Button
                disabled={disableIncrement || loading}
                variant='contained'
                color='secondary'
                sx={{
                    height: '40px',
                    width: '100%',
                }}
                startIcon={<AddCircleOutlineIcon fontSize='large' />}
                onClick={onIncrement}
            >
                INC
            </Button>
        </Stack>
    )
}

export default IncrementDecrementButtons
