import { atom, selector } from 'recoil'
import { machineModelAtom } from './machine'
import { XavierStatus } from '../types'

const xavier1Atom = atom<number | null>({
    key: 'xavier1',
    default: 0,
})

const xavier2Atom = atom<number | null>({
    key: 'xavier2',
    default: 0,
})

const xavier3Atom = atom<number | null>({
    key: 'xavier3',
    default: 0,
})

const xavier4Atom = atom<number | null>({
    key: 'xavier4',
    default: 0,
})

const xavier7Atom = atom<number | null>({
    key: 'xavier7',
    default: 0,
})

const xavier8Atom = atom<number | null>({
    key: 'xavier8',
    default: 0,
})


const xaviersStatusSelector = selector<XavierStatus>({
    key: 'xavier_status',
    get: ({ get }) => {
        const status: XavierStatus = {
            xavier1: get(xavier1Atom) || 0,
            xavier2: get(xavier2Atom) || 0,
            xavier3: get(xavier3Atom) || 0,
            xavier7: get(xavier7Atom) || 0,
        };

        if (get(machineModelAtom) === 'S225') {
            status.xavier4 = get(xavier4Atom) || 0
            status.xavier8 = get(xavier8Atom) || 0
        }

        return status;
    },
})

export {
    xavier1Atom,
    xavier2Atom,
    xavier3Atom,
    xavier4Atom,
    xavier7Atom,
    xavier8Atom,
    xaviersStatusSelector,
}
