import * as React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { SingleSprayControl, DecIncButtons } from '../..'
import { DoseSlider } from './DoseSlider'
import { useRecoilValue } from 'recoil'
import {
    machineSocketAtom,
    treatmentDispenseDuration,
    fluidInstrumentationPressureSetpointSelector,
} from '../../../_state'
import WaterDropIcon from '@mui/icons-material/WaterDrop'
import { blue } from '@mui/material/colors'

export const DispenseDose = () => {
    const socket = useRecoilValue(machineSocketAtom)
    if (!socket) return

    const handleSetDispenseDuration = async (v) => {
        socket.emit('send_command', {
            service: 'treatment',
            command: 'set_dispense_duration',
            kwargs: { duration: v },
        })
    }
    const handleSetTankRegulatorPressure = async (v) => {
        socket.emit('send_command', {
            service: 'fluid_instrumentation',
            command: 'set_tank_regulator_pressure',
            kwargs: { value: v },
        })
    }
    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Stack direction={'row'} alignItems={'center'}>
                    <Typography variant='h6'>Dosering</Typography>
                    <WaterDropIcon sx={{ ml: 1, color: blue[300] }} />
                </Stack>
                <SingleSprayControl />
            </Stack>
            <Box sx={{ ml: 2, mr: 2, mb: 2 }}>
                <Stack sx={{ width: '100%', mb: 2 }}>
                    <DoseSlider
                        step={1}
                        min={30}
                        max={100}
                        socketAttr='dispense_duration'
                        infoText={[
                            '🇳🇱 Met deze schuif kunt u de doseertijd aanpassen. Het verlengen van de doseertijd geeft een groter doseervolume en vice versa. Het wijzigen van de doseerduur heeft geen invloed op het spetterrisico.',
                            '🇬🇧 With this slider you can adjust the dispense duration. Increasing the dispense duration results in more dispense volume and vice versa. Changing the dispense duration does not affect the splashing risk.',
                        ]}
                        callback={handleSetDispenseDuration}
                    />
                    <DecIncButtons
                        step={1}
                        min={30}
                        max={100}
                        socketAtom={treatmentDispenseDuration}
                        callback={handleSetDispenseDuration}
                    />
                </Stack>
                <Stack sx={{ width: '100%', mt: 1 }}>
                    <DoseSlider
                        step={0.1}
                        min={0}
                        max={2.5}
                        socketAttr='tank_pressure'
                        infoText={[
                            '🇳🇱 Met deze schuif kunt u de doseerdruk aanpassen. Het verhogen van de druk geeft een hoger debiet(uitstroomsnelheid) en een groter doseervolume, maar verhoogt ook het risico op spetteren. Het verlagen van de druk geeft een kleiner debiet en doseervolume en vermindert het risico op spetteren.',
                            '🇬🇧 With this slider you can adjust the dispense pressure. Increasing the pressure results in more dispense volume but also increases splashing risk. Decreasing the pressure results in less dispense volume and reduces splashing risk.',
                        ]}
                        callback={handleSetTankRegulatorPressure}
                    />
                    <DecIncButtons
                        step={0.1}
                        min={0}
                        max={2.5}
                        socketAtom={fluidInstrumentationPressureSetpointSelector}
                        callback={handleSetTankRegulatorPressure}
                    />
                </Stack>
            </Box>
        </>
    )
}
