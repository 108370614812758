import React, { useState, useEffect } from 'react'
import {
    Table,
    TableContainer,
    TableHead,
    Collapse,
    Box,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
    Button,
    TextField,
    Stack,
    Autocomplete,
    Typography,
} from '@mui/material'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { useCompanyActions, useMachineTransfersActions } from '../../_actions'
import { TranfersOverview } from '../types'
import { ModalBox } from '../../_components'
import { Company } from '../../types'

interface RowProps {
    row: any
}
const Row = ({ row }: RowProps) => {
    const [open, setOpen] = React.useState(false)

    return (
        <React.Fragment>
            <TableRow>
                <TableCell component='th' scope='row' sx={{ p: 1 }}>
                    {row.company_name}
                </TableCell>
                <TableCell align='left' sx={{ p: 1 }}>
                    {new Date(row.created_at * 1000).toLocaleString('nl', {
                        hour12: false,
                    })}
                </TableCell>
                <TableCell sx={{ p: 1 }}>
                    <IconButton size='small' onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell sx={{ pb: 0, pt: 0 }} colSpan={6}>
                    <Collapse in={open} timeout='auto' unmountOnExit>
                        <Box sx={{ margin: 0.5 }}>
                            <Typography variant='body2'>Description: {row.description}</Typography>
                            <Typography variant='body2'>Initiated by: {row.created_by}</Typography>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

interface CollapsibleTableProps {
    transfers: TranfersOverview
}
export const CollapsibleTable = ({ transfers }: CollapsibleTableProps) => {
    return (
        <>
            <Typography variant='h6' gutterBottom>
                History
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align='left'>Company</TableCell>
                            <TableCell align='left'>Date</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {transfers.transfers.map((row, index) => (
                            <Row key={`${row.company_name}-${row.description}-${row.created_by}`} row={row} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

interface TransferMachineModalProps {
    machineName: string
    machineTransfers: TranfersOverview
    handleClose: () => void
}

export const TransferMachineModal = ({ machineName, machineTransfers, handleClose }: TransferMachineModalProps) => {
    const [selectedCompany, setSelectedCompany] = useState<Company | null>(null)
    const [companies, setCompanies] = useState<Company[] | []>([])
    const [description, setDescription] = useState('')
    const [error, setError] = useState('')
    const [companyError, setCompanyError] = useState('')

    let lastTransferCompanyName: string | null = null
    if (machineTransfers.transfers.length > 0) {
        lastTransferCompanyName = machineTransfers.transfers[0].company_name
    }

    const handleChange = (event) => {
        const value = event.target.value
        if (value.length > 255) {
            setError('Description must be less than 255 characters')
        } else {
            setError('')
        }
        setDescription(value)
    }

    const handleTransfer = () => {
        if (!selectedCompany) {
            setCompanyError('Company is required')
            return
        }
        if (description.trim() === '') {
            setError('Description is required')
            return
        }
        if (description.length > 255) {
            setError('Description must be less than 255 characters')
            return
        }
        machineTransfersActions
            .transfer({
                machine_name: machineName,
                company_id: selectedCompany.id,
                description: description,
            })
            .then(handleClose)
    }

    const companyActions = useCompanyActions()
    const machineTransfersActions = useMachineTransfersActions()

    useEffect(() => {
        companyActions.getAll().then(setCompanies)
    }, [])

    if (companies.length === 0) return null

    return (
        <ModalBox title={`Transfer: ${machineName}`} open={true} handleDialogClose={handleClose}>
            <Stack spacing={1}>
                <Autocomplete
                    value={selectedCompany}
                    options={companies}
                    getOptionDisabled={(option: Company) => option.name === lastTransferCompanyName}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField {...params} label='Company' error={!!companyError} helperText={companyError} />
                    )}
                    onChange={(_, value, reason) => {
                        setSelectedCompany(value)
                        setCompanyError('')
                    }}
                />
                <TextField
                    required
                    fullWidth
                    name='description'
                    label='Description'
                    autoComplete='description'
                    value={description}
                    onChange={handleChange}
                    error={!!error}
                    helperText={error}
                />
                <Stack direction={'row'} justifyContent={'space-between'} spacing={1}>
                    <Button variant='contained' color={'success'} onClick={handleTransfer} fullWidth>
                        Transfer
                    </Button>
                    <Button variant='contained' color={'error'} onClick={() => handleClose()} fullWidth>
                        Cancel
                    </Button>
                </Stack>
            </Stack>
        </ModalBox>
    )
}
