import { NameAvatar } from '../_components'
import { Box, ListItemButton, ListItemText, Typography } from '@mui/material'
import { TaskListItem as TaskListItemType } from '../types'
import StartIcon from '@mui/icons-material/Start'
import { TaskItemMenu } from './TaskItemMenu'

interface TaskListItemProps {
    taskListItem: TaskListItemType
    selected: boolean
    disabled: boolean
    onClick: () => void
}

export const TaskListItem = ({ taskListItem, selected, disabled, onClick }: TaskListItemProps) => (
    <div key={taskListItem.id}>
        <ListItemButton
            dense
            sx={{
                '&.Mui-selected': {
                    color: '#81c784',
                    fontWeight: 'bold',
                },
                display: 'flex',
                alignItems: 'center',
            }}
            selected={selected}
            alignItems='flex-start'
            onClick={onClick}
            disabled={disabled}
        >
            <Box
                sx={{
                    '& > :not(style)': {
                        mb: 1.5,
                        mr: 1,
                    },
                }}
            >
                <NameAvatar name={`${taskListItem.user_name} ${taskListItem.surname}`} />
            </Box>
            <ListItemText
                primary={
                    <Typography
                        sx={{
                            fontWeight: selected ? 'bold' : 'normal',
                            color: selected ? '#81c784' : 'white',
                        }}
                        component='span'
                        variant='body2'
                        color='text.primary'
                    >
                        {`${taskListItem.name !== '' ? taskListItem.name : taskListItem.field_name} ${
                            taskListItem.result == 'check-warning' ? '⚠️' : ''
                        }`}
                    </Typography>
                }
                secondary={
                    <>
                        <Typography
                            sx={{ fontWeight: selected ? 'bold' : 'normal' }}
                            component='span'
                            variant='body2'
                            color='text.primary'
                        >
                            {`ID: ${taskListItem['id']}—`}
                            {new Date(taskListItem.created_at)
                                .toLocaleString(['nl'], {
                                    hour12: false,
                                    month: 'numeric',
                                    day: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                })
                                .split('-')
                                .join('/')}
                        </Typography>
                        <StartIcon fontSize='small' sx={{ mb: 0.5, ml: 1, mr: 0.5, verticalAlign: 'middle' }} />
                        <Typography
                            sx={{ fontWeight: selected ? 'bold' : 'normal' }}
                            component='span'
                            variant='body2'
                            color='text.primary'
                        >
                            {`${taskListItem.bed_extension.toFixed(2)}`}
                        </Typography>
                    </>
                }
            />
            <TaskItemMenu taskListItem={taskListItem} />
        </ListItemButton>
    </div>
)
