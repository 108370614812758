import React from 'react'
import { useImageActions, useAlertActions } from '../_actions'
import { Box, CircularProgress } from '@mui/material'
import { useWindowSize } from '../_helpers'

interface ImageComponentProps {
    filename: string
    altText: string
    style?: React.CSSProperties
}
const ImageComponent = ({ filename, altText, style }: ImageComponentProps) => {
    const [image, setImage] = React.useState('')
    const imageActions = useImageActions()
    const alertActions = useAlertActions()
    const windowSize = useWindowSize()

    if (windowSize.width >= 868 && !style) {
        style = { width: '40%', height: '40%' }
    } else if (!style) {
        style = { width: '100%', height: 'auto' }
    }

    const getImage = (filename: string) => {
        return imageActions
            .get(filename)
            .then((res) => {
                const imageObjectURL = URL.createObjectURL(res)
                setImage(imageObjectURL)
            })
            .catch(() => {
                alertActions.error('Could not load image')
            })
    }

    React.useEffect(() => {
        getImage(filename)
    }, [filename])

    if (!image)
        return (
            <Box sx={{ display: 'flex' }}>
                <CircularProgress color='secondary' />
            </Box>
        )
    return (
        <Box
            sx={{
                width: '100%',
                height: 'auto',
            }}
        >
            <img style={style} src={image} alt={altText} />
        </Box>
    )
}
export { ImageComponent }
