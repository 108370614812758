import React, { useState, useEffect } from 'react'
import { Slider, Typography, Stack, Box } from '@mui/material'
import { clusteringTreatmentDensityTargetAtom, machineSocketAtom, treatmentsPer100mSelector } from '../../_state'
import { useRecoilValue } from 'recoil'
import { useTheme } from '@mui/material/styles'
import { valuetext } from '../../_helpers/utils'
import { ThreeTulipsIcon } from '../icons/ThreeTulipsIcon'
import { useAlertActions } from '../../_actions'
import IncrementDecrementButtons from './IncrementDecrementButtons'
import { InfoBox } from './ThresholdSetting'

const STEP = 5
const MIN = 0
const MAX = 400

export const DensityThresholdSlider = () => {
    const [sliderValue, setSliderValue] = useState<number>(MIN)
    const [loading, setLoading] = useState<boolean>(false)

    const socket = useRecoilValue(machineSocketAtom)
    const treatmentDensityTarget = useRecoilValue(clusteringTreatmentDensityTargetAtom)
    // Green vertical bar value
    const treatmentDensity = useRecoilValue(treatmentsPer100mSelector)

    const theme = useTheme()
    const alertActions = useAlertActions()

    if (!socket) {
        console.error('Socket not available')
        return <></>
    }

    const handleChange = (value: number) => {
        setLoading(true)
        socket.emit(
            'send_command',
            {
                service: 'clustering',
                command: 'set_treatment_density_target',
                kwargs: { treatment_density_target: value },
            },
            () => setLoading(false),
        )
    }

    const handleChangeCommitted = (_: Event | React.SyntheticEvent, newValue: number | number[]) => {
        handleChange(Array.isArray(newValue) ? newValue[0] : newValue)
    }

    const onChange = (_: Event | React.SyntheticEvent, newValue: number | number[]) => {
        setSliderValue(Array.isArray(newValue) ? newValue[0] : newValue)
    }

    useEffect(() => {
        setSliderValue(Math.min(Math.max(treatmentDensityTarget, MIN), MAX))
    }, [treatmentDensityTarget])

    const handleIncrement = () => {
        if (treatmentDensityTarget !== null && treatmentDensityTarget + STEP <= MAX) {
            handleChange(treatmentDensityTarget + STEP)
        } else {
            alertActions.error(`Threshold cannot have a value greater than ${MAX}`)
        }
    }

    const handleDecrement = () => {
        if (treatmentDensityTarget !== null && treatmentDensityTarget - STEP >= MIN) {
            handleChange(treatmentDensityTarget - STEP)
        } else {
            alertActions.error(`Threshold cannot have a value less than ${MIN}`)
        }
    }

    return (
        <>
            <Stack sx={{ width: '100%' }}>
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-end'}>
                    <Typography sx={{ fontWeight: 500 }} gutterBottom>
                        {`Behandeldichtheid: ${treatmentDensityTarget} per 100m`}
                    </Typography>
                    <InfoBox />
                </Stack>
                <Typography variant='caption' sx={{ textAlign: 'center', mb: '-10px' }}>
                    {'Behandeldichtheid per 100m'}
                </Typography>
                <Stack direction={'row'} alignItems={'center'}>
                    {'🌷'}
                    <Typography variant='caption' sx={{ ml: 0.5 }}>
                        {MIN}
                    </Typography>
                    <Slider
                        sx={{
                            pointerEvents: 'none !important',
                            '& .MuiSlider-rail, & .MuiSlider-track': {
                                pointerEvents: 'none',
                            },
                            '& .MuiSlider-thumb': {
                                pointerEvents: 'auto',
                            },
                            '& .MuiSlider-mark': {
                                height: '35px',
                                width: '5px',
                                backgroundColor: theme.palette.success.main,
                                opacity: 1,
                            },
                            mr: 2,
                            ml: 2,
                        }}
                        valueLabelDisplay={'auto'}
                        color='secondary'
                        step={STEP}
                        min={MIN}
                        max={MAX}
                        getAriaValueText={valuetext}
                        // if treatmentDensity > max set to max do the same for min
                        marks={[{ value: Math.min(Math.max(treatmentDensity, MIN), MAX) }]}
                        value={sliderValue}
                        disabled={loading}
                        onChangeCommitted={handleChangeCommitted}
                        onChange={onChange}
                        disableSwap
                    />
                    <Typography variant='caption' sx={{ mr: 0.5 }}>
                        {MAX}
                    </Typography>
                    <ThreeTulipsIcon />
                </Stack>
            </Stack>
            <Box sx={{ ml: 2, mr: 2, mb: 2 }}>
                <IncrementDecrementButtons
                    onIncrement={handleIncrement}
                    onDecrement={handleDecrement}
                    disableIncrement={treatmentDensityTarget === null || treatmentDensityTarget + STEP > MAX}
                    disableDecrement={treatmentDensityTarget === null || treatmentDensityTarget - STEP < MIN}
                    loading={loading}
                />
            </Box>
        </>
    )
}
