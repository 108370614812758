import { atom } from 'recoil'

const toolFieldAtom = atom<any>({
    key: 'toolField',
    default: null,
})

const toolCommandQueueAtom = atom<any>({
    key: 'toolCommandQueue',
    default: [],
})

export { toolFieldAtom, toolCommandQueueAtom }
