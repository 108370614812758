import { useFetchWrapper } from '../_helpers'
import { TreatmentSafetySettings } from '../types'
export { useFluidSafetyActions }

function useFluidSafetyActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`
    const fetchWrapper = useFetchWrapper()

    return {
        get,
        update,
        reset,
        alarmsClear,
    }

    async function get(machineName: string): Promise<TreatmentSafetySettings> {
        return fetchWrapper.get(`${baseUrl}/fluid_instrumentation/${machineName}/settings`)
    }

    async function update(machineName: string, settings: TreatmentSafetySettings): Promise<TreatmentSafetySettings> {
        return fetchWrapper.post(`${baseUrl}/fluid_instrumentation/${machineName}/settings`, settings)
    }

    async function reset(machineName: string) {
        return fetchWrapper.post(`${baseUrl}/fluid_instrumentation/${machineName}/settings/reset`)
    }

    async function alarmsClear(machineName: string) {
        return fetchWrapper.post(`${baseUrl}/fluid_instrumentation/${machineName}/alarms/clear`)
    }
}
