import { Box } from '@mui/material'
import { useRecoilValue } from 'recoil'
import { mapAtom } from '../_state'
import { FieldEditorDrawer } from '../_components'
import { GMapFieldEditor } from '../gmap'
import { MenuEditorChoice } from '../gmap/MenuEditorChoice'
import { H2LFieldJson } from '../types'
import { useOutletContext } from 'react-router-dom'

export default function BoundaryDraw() {
    return (
        <>
            <h3>Boundary Draw</h3>
        </>
    )
}

interface OutletContext {
    h2lFieldJson: H2LFieldJson
}

export function CropEditorView() {
    const { h2lFieldJson } = useOutletContext<OutletContext>()
    const map = useRecoilValue(mapAtom)
    const field = JSON.parse(JSON.stringify(h2lFieldJson))
    const render = () => {
        return (
            <>
                <MenuEditorChoice />
                {map && <GMapFieldEditor field={field} map={map} />}
                <Box sx={{ position: 'relative' }}>
                    <FieldEditorDrawer fieldName={field.name} />
                </Box>
            </>
        )
    }
    return render()
}
