import React from 'react'
import { Typography } from '@mui/material'

/* eslint-disable quotes */
export const getModelInfo = (keyPart: string): string => {
    const modelInfo = {
        'Super Groep':
            "Het Super Groep model is het aanbevolen model, getraind op de foto's van de cultivars van alle groepen tezamen.",
        'Groep A':
            "Het Groep A model is getraind op foto's van de volgende cultivars: Strong Gold, Royal Virgin, Antarctica, Lion's Glory en Kobla.",
        'Groep B':
            "Het Groep B model is getraind op foto's van de volgende cultivars: Denmark, Yellow Flight, White Flag, Avantgarde en Malaysia.",
        'Groep C':
            "Het Groep C model is getraind op foto's van de volgende cultivars: Flash Point, Red Princess, Hermitage, Orca, Rococo Double, Monte Orange en Bright Sun.",
        'Groep D':
            "Het Groep D model is getraind op foto's van de volgende cultivars: Jumbo Pink, First Star, White Mountain.",
        'Groep E':
            "Het Groep E model is getraind op foto's van de volgende cultivars: Columbus, Super Parrot en Seadov Parrot.",
        'Groep F': "Het Groep F model is getraind op foto's van de Update cultivar.",
    }
    for (const key in modelInfo) {
        if (key === keyPart) {
            return modelInfo[key]
        }
    }
    return 'Beschrijving nog niet beschikbaar.'
}

interface ModelInfoProps {
    modelName: string
}

export const ModelInfo = ({ modelName }: ModelInfoProps) => (
    <>
        <Typography variant='body2' gutterBottom>
            {getModelInfo(modelName.split(' ').slice(0, -1).join(' '))}
        </Typography>
    </>
)
