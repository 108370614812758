import React, { useState, useEffect, MouseEvent } from 'react'
import UserService from '../services/UserService'
import { Field, Company } from '../types'
import { useQuery } from '@tanstack/react-query'
import { Link, useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import { useAlertActions, useCompanyActions, useFieldActions } from '../_actions'
import { FieldMenu, BoundaryIcon, FullscreenIFrame } from '../_components'
import AddIcon from '@mui/icons-material/Add'
import {
    Container,
    Tooltip,
    Box,
    Typography,
    Table,
    Button,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    LinearProgress,
    Stack,
    IconButton,
    Popover,
    Autocomplete,
    TextField,
    Badge,
} from '@mui/material'
import FilterAltIcon from '@mui/icons-material/FilterAlt'

export { List }

function List(): JSX.Element {
    const [filterAnchor, setFilterAnchor] = useState(null)
    const [selectedCompany, setSelectedCompany] = useState<Company | null>()
    const [iframeOpen, setIframeOpen] = useState(false)
    const fieldActions = useFieldActions()
    const companyActions = useCompanyActions()
    const alertActions = useAlertActions()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()

    const fieldsResult = useQuery<Field[]>({
        queryKey: ['fields', selectedCompany?.id],
        queryFn: () => fieldActions.getAll(selectedCompany?.id),
    })

    const companiesResult = useQuery<Company[]>({
        queryKey: ['companies'],
        queryFn: () => companyActions.getAll(),
        enabled: UserService.hasRole(['admin']),
    })

    const updateUrl = (companyName: string) => setSearchParams({ company: companyName })

    const handleFilterIconClick = (event) => {
        setFilterAnchor(event.currentTarget)
    }

    const handleCloseFilterPopover = () => {
        setFilterAnchor(null)
    }

    const handleRowClick = (event: MouseEvent, field: Field) => {
        if (field && field.id && field.crop_id && event.target instanceof Element) {
            if (event.target.tagName === 'TD' || event.target.tagName === 'TH') {
                navigate(`/fields/${field.id}/crops/${field.crop_id}/view/`)
            }
        } else {
            alertActions.error('Field is not parsed')
        }
    }

    const handleRowMiddleMouseClick = (event, field: Field) => {
        if (field?.id && field?.crop_id) {
            if (event.target.type !== 'button') {
                const url = `/fields/${field.id}/crops/${field.crop_id}/view/`
                const popunder = window.open(url, '_blank')
                popunder.blur()
                window.focus()
            }
        } else {
            alertActions.error('Field is not parsed')
        }
    }

    const getBoundaryBtnColor = (field: Field): string => {
        if (!field.crop_id) {
            return 'gray'
        }
        return field.boundary_parsed ? '#2e7d32' : '#ed6d28'
    }

    useEffect(() => {
        const company = searchParams.get('company')
        if (company && companiesResult.data) {
            const selectedCompanyFromUrl = companiesResult.data.find((c) => c.name == company)
            setSelectedCompany(selectedCompanyFromUrl)
        }
    }, [searchParams, companiesResult.data])

    if (iframeOpen) {
        return (
            <FullscreenIFrame
                title='📊 Field Statistics'
                url={`https://grafana.h2lselector.com/d/et_MruIVz/field-statistics?orgId=1&var-company=${
                    selectedCompany?.name ? selectedCompany?.name : 'All'
                }&kiosk&theme=light`}
                handleClose={() => setIframeOpen(false)}
            />
        )
    }
    return (
        <Container sx={{ height: '100%' }}>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Stack direction={'row'} alignItems={'flex-start'}>
                    <Typography variant='h3' gutterBottom>
                        Fields
                    </Typography>
                    {UserService.hasRole(['admin']) && (
                        <Button sx={{ fontSize: '30px' }} onClick={() => setIframeOpen(true)}>
                            📊
                        </Button>
                    )}
                </Stack>
                <Box alignSelf={'center'}>
                    <Link to={'/fields/add'}>
                        <Button variant='contained' color={'success'} startIcon={<AddIcon />}>
                            {'Add field'}
                        </Button>
                    </Link>
                </Box>
            </Stack>
            <Paper elevation={3}>
                <TableContainer component={Paper}>
                    <Table aria-label='simple table'>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Ha</TableCell>
                                <TableCell>
                                    <Stack direction={'row'} alignItems={'center'}>
                                        Company
                                        {UserService.hasRole(['admin']) && !companiesResult.isLoading && (
                                            <>
                                                <IconButton onClick={handleFilterIconClick}>
                                                    <Badge color='success' variant='dot' invisible={!selectedCompany}>
                                                        <FilterAltIcon />
                                                    </Badge>
                                                </IconButton>
                                                <Popover
                                                    open={Boolean(filterAnchor)}
                                                    anchorEl={filterAnchor}
                                                    onClose={handleCloseFilterPopover}
                                                    anchorOrigin={{
                                                        vertical: 'center',
                                                        horizontal: 'center',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'center',
                                                        horizontal: 'center',
                                                    }}
                                                >
                                                    <Box p={1}>
                                                        <Autocomplete
                                                            open={true}
                                                            options={companiesResult.data || []}
                                                            getOptionLabel={(option) => option.name}
                                                            value={selectedCompany}
                                                            sx={{ width: 300 }}
                                                            renderInput={(params) => <TextField {...params} />}
                                                            onChange={(_, value, reason) => {
                                                                if (reason === 'clear') {
                                                                    updateUrl(null)
                                                                    setSelectedCompany(null)
                                                                }
                                                                if (value) {
                                                                    updateUrl(value.name)
                                                                }
                                                                handleCloseFilterPopover()
                                                            }}
                                                        />
                                                    </Box>
                                                </Popover>
                                            </>
                                        )}
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {fieldsResult.data?.map((field) => (
                                <TableRow
                                    hover={true}
                                    onAuxClick={(event) => handleRowMiddleMouseClick(event, field)}
                                    onClick={(event) => handleRowClick(event, field)}
                                    key={field.id}
                                    sx={{
                                        cursor: 'pointer',
                                        bgcolor: '#ffffff',
                                        whiteSpace: 'normal',
                                        wordBreak: 'break-word',
                                    }}
                                >
                                    <TableCell
                                        component='th'
                                        scope='row'
                                        sx={{
                                            whiteSpace: 'normal',
                                            wordBreak: 'break-word',
                                        }}
                                    >
                                        {`${field.test_pass ? '✅ ' : '❌'} ${field.name}`}
                                    </TableCell>
                                    <TableCell
                                        component='th'
                                        scope='row'
                                        sx={{
                                            whiteSpace: 'normal',
                                            wordBreak: 'break-word',
                                        }}
                                    >
                                        {field.hectare}
                                    </TableCell>
                                    <TableCell
                                        component='th'
                                        scope='row'
                                        sx={{
                                            whiteSpace: 'normal',
                                            wordBreak: 'break-word',
                                        }}
                                    >
                                        <Stack
                                            direction={'row'}
                                            justifyContent={'space-between'}
                                            alignItems={'center'}
                                        >
                                            <Box sx={{ align: 'left' }}>{field.company}</Box>
                                            <Stack direction={'row'}>
                                                <Tooltip
                                                    title={
                                                        field.crop_id !== null
                                                            ? field.boundary_parsed
                                                                ? 'Field has boundary'
                                                                : 'Field does not have boundary'
                                                            : 'Field not parsed'
                                                    }
                                                    placement='top'
                                                >
                                                    <>
                                                        <BoundaryIcon
                                                            backgroundColor={getBoundaryBtnColor(field)}
                                                            pathColor='white'
                                                        />
                                                    </>
                                                </Tooltip>
                                                <FieldMenu
                                                    fieldID={field.id}
                                                    cropID={field.crop_id}
                                                    fieldName={field.name}
                                                />
                                            </Stack>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {fieldsResult.isLoading && <LinearProgress color='success' />}
                {fieldsResult.error && <>{'An error has occurred: '}</>}
            </Paper>
        </Container>
    )
}
