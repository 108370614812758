import { useNavigate } from 'react-router-dom'
import { useAlertActions, useParserActions } from '../_actions'
export { useFieldFunctions }
function useFieldFunctions() {
    const alertActions = useAlertActions()
    const parserActions = useParserActions()
    const navigate = useNavigate()

    return {
        //selectAllCreateTask,
        reparse,
    }

    function reparse(fieldID: number): void {
        if (window.confirm('Run reparse on field?')) {
            // console.log('Run re-parse')
            parserActions
                .parseById(fieldID)
                .then((reply) => {
                    navigate(`/fields/${fieldID}/crops/${reply.crop_id}/view`)
                    console.log(`Field with ID: ${fieldID} parsed`)
                })
                .catch(() => {
                    alertActions.error('Could not parse field. Try again later')
                })
        }
    }
}
