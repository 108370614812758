import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import { ReactComponent as Icon } from '../../assets/images/shield-drop.svg'
export { FluidSafetyIcon }
function FluidSafetyIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <Icon />
        </SvgIcon>
    )
}
