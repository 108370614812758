import React, { useState } from 'react'
import { CircularProgress, Stack, Button } from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { useRecoilValue } from 'recoil'
import { grey } from '@mui/material/colors'

interface DecIncButtonsProps {
    step: number
    min: number
    max: number
    socketAtom: any
    callback: (v: number) => void
}

const activeStyles = {
    height: '40px',
    width: '100%',
    border: 1,
    borderColor: grey[700],
    color: 'white',
    backgroundColor: grey[800],
    '&:hover': {
        backgroundColor: grey[800],
        color: 'white',
    },
}
const disabledStyles = {
    height: '40px',
    width: '100%',
}

export const DecIncButtons = ({ step, min, max, socketAtom, callback }: DecIncButtonsProps) => {
    const [decLoading, setDecLoading] = useState(false)
    const [incLoading, setIncLoading] = useState(false)
    const value: number = useRecoilValue(socketAtom)

    const handleDecrement = () => {
        setDecLoading(true)
        const newValue = value - step
        if (newValue >= min) {
            callback(newValue)
        }
        setTimeout(() => setDecLoading(false), 2000)
    }

    const handleIncrement = () => {
        setIncLoading(true)
        const newValue = value + step
        if (newValue <= max) {
            callback(newValue)
        }
        setTimeout(() => setIncLoading(false), 2000)
    }

    const checkDecDisabled = () => {
        return value === null || value - step < min || decLoading || incLoading ? true : false
    }

    const checkIncDisabled = () => {
        return value === null || value + step > max || incLoading || decLoading ? true : false
    }

    return (
        <Stack spacing={1} direction='row' alignItems='center' sx={{ mt: 1 }}>
            <Button
                disabled={checkDecDisabled()}
                variant='outlined'
                sx={checkDecDisabled() ? disabledStyles : activeStyles}
                startIcon={decLoading ? <CircularProgress size={20} color='secondary' /> : <RemoveCircleOutlineIcon />}
                onClick={handleDecrement}
            >
                DEC
            </Button>
            <Button
                disabled={checkIncDisabled()}
                variant='outlined'
                sx={checkIncDisabled() ? disabledStyles : activeStyles}
                startIcon={incLoading ? <CircularProgress size={20} color='secondary' /> : <AddCircleOutlineIcon />}
                onClick={handleIncrement}
            >
                INC
            </Button>
        </Stack>
    )
}
