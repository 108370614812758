import { useFetchWrapper } from '../_helpers'
import { Logbook as LogbookType, LogbookCreate, LogbookImage } from '../types'
export { useLogbookActions }

function useLogbookActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1/logbooks`
    const fetchWrapper = useFetchWrapper()

    return {
        getById,
        getByMachineName,
        getLogbookImagesByLogbookId,
        markAsRead,
        create,
        update,
    }

    function getById(id: number): Promise<LogbookType | null> {
        return fetchWrapper.get(`${baseUrl}/${id}`)
    }
    function getByMachineName(machineName: string): Promise<LogbookType[] | []> {
        return fetchWrapper.get(`${baseUrl}/machine/machine_name/${machineName}`)
    }

    function getLogbookImagesByLogbookId(id: number): Promise<LogbookImage[] | []> {
        return fetchWrapper.get(`${baseUrl}/images/logbook_id/${id}`)
    }

    function markAsRead(unread: Set<number>) {
        const markAsReadRequest = {
            ids: Array.from(unread),
        }
        return fetchWrapper.post(`${baseUrl}/mark-as-read`, markAsReadRequest)
    }

    function create(params: LogbookCreate) {
        return fetchWrapper.post(`${baseUrl}/`, params)
    }

    function update(id: number, params: LogbookCreate) {
        const formData = new FormData()

        // Backend validation does not allow to send empty data.
        // A mocked file is used to pass validation
        if (params.images && params.images.length > 0) {
            params.images.forEach((image: File) => formData.append('input_files', image))
        } else {
            formData.append('input_files', new Blob([], { type: 'image/jpeg' }), 'empty_image.jpg')
        }

        // Add rest of params to form data
        for (const [key, value] of Object.entries(params)) {
            if (key === 'images') continue
            formData.append(key, value)
        }
        return fetchWrapper.put(`${baseUrl}/${id}/updatelogbook`, formData)
    }
}
