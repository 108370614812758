import '../index.css'

interface CircleProps {
    value: number
}
function CircleWithNumber({ value }: CircleProps) {
    const containerStyle = {
        // width: '100vw',
        // height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }

    const circleStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: value >= 95 ? 'green' : 'red',
        borderRadius: '50%',
        borderWidth: 'thick',
        width: '50vmin',
        height: '50vmin',
        color: 'white',
        fontSize: '30vmin',
        border: '2px solid white',
        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
        boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.5)',
        animation: value >= 95 ? 'pulse 2s infinite' : 'spin 2s linear infinite',
    }

    return (
        <div style={containerStyle}>
            <div style={circleStyle}>{value}</div>
        </div>
    )
}

export default CircleWithNumber
