// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react'
import UserService from '../services/UserService'
import { NavLink } from 'react-router-dom'
import { AppBarUserMenu, NotificationIndicator } from '.'
import { AppBar, Box, Toolbar, IconButton, ListItemText, Typography, MenuItem, Menu } from '@mui/material'

import MenuIcon from '@mui/icons-material/Menu'
import { useTheme } from '@mui/system'
import Logo from '../assets/images/logo.png'
import { removeDashCapitalize } from '../_helpers'

export { UIAppBar }

function UIAppBar() {
    const theme = useTheme()
    const [anchorElNav, setAnchorElNav] = React.useState(null)
    const activeColor = '#fff'
    const inActiveColor = '#b7b7b7'

    interface MobileMenuLinkProps {
        loc: string
    }
    const DesktopMenuLink = ({ loc }: DesktopMenuLinkProps) => (
        <NavLink
            to={`/${loc}`}
            style={({ isActive }) => ({
                color: isActive ? activeColor : inActiveColor,
                textDecoration: 'none',
            })}
        >
            <MenuItem key={loc} sx={{ my: 2, borderRadius: '4px' }} disableGutters>
                <ListItemText
                    primary={loc}
                    primaryTypographyProps={{
                        typography: 'button',
                        paddingRight: 1,
                        paddingLeft: 1,
                        fontSize: '0.875rem',
                        variant: 'subtitle2',
                        borderRadius: '4px',
                    }}
                />
            </MenuItem>
        </NavLink>
    )
    const MobileMenuLink = ({ loc }: MobileMenuLinkProps) => (
        <NavLink
            to={`/${loc}`}
            onClick={handleCloseNavMenu}
            style={({ isActive }) => ({
                color: isActive ? activeColor : inActiveColor,
                textDecoration: 'none',
            })}
        >
            <MenuItem key={loc}>
                <ListItemText>{removeDashCapitalize(loc)}</ListItemText>
            </MenuItem>
        </NavLink>
    )
    const handleOpenNavMenu = (event): void => {
        setAnchorElNav(event.currentTarget)
    }

    const handleCloseNavMenu = (): void => {
        setAnchorElNav(null)
    }

    return (
        <React.Fragment>
            <Toolbar sx={{ mb: 2 }} />
            <AppBar
                sx={{
                    background: '#343a40',
                    zIndex: theme.zIndex.drawer + 1,
                }}
                position='fixed'
            >
                <Toolbar>
                    {UserService.isLoggedIn() && (
                        <Box
                            sx={{
                                display: { xs: 'flex', md: 'none' },
                            }}
                        >
                            <IconButton
                                size='large'
                                aria-label='account of current user'
                                aria-controls='menu-appbar'
                                aria-haspopup='true'
                                onClick={handleOpenNavMenu}
                                color='inherit'
                            >
                                <MenuIcon />
                            </IconButton>

                            <Menu
                                id='menu-appbar'
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    '& .MuiPaper-root': {
                                        backgroundColor: '#343a40',
                                    },
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {UserService.hasRole(['admin', 'manager']) && <MobileMenuLink loc={'users'} />}
                                <MobileMenuLink loc={'machines'} />
                                <MobileMenuLink loc={'fields'} />
                                <MobileMenuLink loc={'messages'} />
                                <MobileMenuLink loc={'manual'} />
                                {UserService.hasRole(['admin']) && <MobileMenuLink loc={'service'} />}
                                <MobileMenuLink loc={'plant-marking'} />
                                {UserService.hasRole(['admin', 'manufacturer']) && (
                                    <MobileMenuLink loc={'production'} />
                                )}
                            </Menu>
                        </Box>
                    )}
                    <Box
                        component='img'
                        sx={{
                            width: 128,
                            maxWidth: { xs: 128, md: 128 },
                        }}
                        src={Logo}
                        alt='H2L'
                    />
                    {UserService.isLoggedIn() && (
                        <Box sx={{ flexGrow: 1, pl: 1, display: { xs: 'none', md: 'flex' } }}>
                            {UserService.hasRole(['admin', 'manager']) && <DesktopMenuLink loc='users' />}
                            <DesktopMenuLink loc='machines' />
                            <DesktopMenuLink loc='fields' />
                            <DesktopMenuLink loc='messages' />
                            <DesktopMenuLink loc='manual' />
                            {UserService.hasRole(['admin']) && <DesktopMenuLink loc='service' />}
                            <DesktopMenuLink loc={'plant-marking'} />
                            {UserService.hasRole(['admin', 'manufacturer']) && <DesktopMenuLink loc={'production'} />}
                        </Box>
                    )}
                    <Typography component='div' sx={{ flexGrow: 1 }}></Typography>
                    <NotificationIndicator />
                    <AppBarUserMenu />
                </Toolbar>
            </AppBar>
        </React.Fragment>
    )
}
