import { atom } from 'recoil'
import { SubframePositionStatus } from '../types'


const subframePositionStartAtom = atom<SubframePositionStatus | null>({
    key: 'subframe_position_start',
    default: null,
})

const subframePositionAtom = atom<SubframePositionStatus | null>({
    key: 'subframe_position',
    default: null,
})

export { subframePositionStartAtom, subframePositionAtom }
