import { InSerialNumberForm, OutSerialNumber } from '../serial_number/types'
import { useFetchWrapper } from '../_helpers'
export { useSerialNumberActions }

function useSerialNumberActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`
    const fetchWrapper = useFetchWrapper()

    return {
        get,
        generate,
        downloadCSV,
    }

    async function get(): Promise<OutSerialNumber[]> {
        return fetchWrapper.get(`${baseUrl}/serial_numbers`)
    }

    async function generate(formData: InSerialNumberForm) {
        return fetchWrapper.post(`${baseUrl}/serial_numbers/generate`, formData)
    }

    async function downloadCSV(): Promise<void> {
        return fetchWrapper.get(`${baseUrl}/serial_numbers/to_csv`)
    }
}
