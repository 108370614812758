import { atom, selector } from 'recoil'
import { MachineControlStatus } from '../types'

const machineControlAtom = atom<MachineControlStatus | null>({
    key: 'machine_control',
    default: null,
})

const machineControlRunningSelector = selector<boolean | null>({
    key: 'machine_control_running',
    get: ({ get }) => {
        //return get(machineControlAtom)?.running
        return get(machineControlAtom)?.status === 3
    },
})

const machineControlStateSelector = selector<number | null>({
    key: 'machine_control_state',
    get: ({ get }) => {
        const machineControlState = get(machineControlAtom);
        // Return the state property from the atom
        return machineControlState?.state ?? null
    },
})

// Used for back compatibility, remove when all the machines are compatible
const machineControlStateAvailableSelector = selector({
    key: 'machine_control_state_available',
    get: ({ get }) => {
        const machineControl = get(machineControlAtom)
        if (machineControl && machineControl.state != null) {
            return true
        }
        return false
    },
})

export {
    machineControlAtom,
    machineControlRunningSelector,
    machineControlStateAvailableSelector,
    machineControlStateSelector,
}
