import { Box, Tabs, Tab } from '@mui/material'
import { HandleChangeTabIndex } from '../../types'
import { styles } from '../../styles'
import { IndicatorIcon } from '../IndicatorIcon'

interface IconTabsProps {
    value: number
    handleChangeTabIndex: HandleChangeTabIndex
}

export default function IconTabs({ value, handleChangeTabIndex }: IconTabsProps) {
    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        }
    }

    return (
        <Box sx={[styles.IconTabsBox]}>
            <Tabs indicatorColor={'secondary'} value={value} onChange={handleChangeTabIndex} variant='fullWidth'>
                {['navigation', 'treatment', 'cameras'].map((t, i) => {
                    return <Tab key={t} icon={<IndicatorIcon type={t} />} {...a11yProps(i)} />
                })}
            </Tabs>
        </Box>
    )
}
