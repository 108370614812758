import { atom } from 'recoil'
import { Company } from '../types'

const companiesAtom = atom<Company[] | []>({
    key: 'companies',
    default: [],
})

const companyFilterAtom = atom({
    key: 'company_filter',
    default: null,
})

export { companiesAtom, companyFilterAtom }
