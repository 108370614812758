import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import { ReactComponent as Icon } from '../../assets/images/video.svg'
export { VideoIcon }
function VideoIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <Icon />
        </SvgIcon>
    )
}
