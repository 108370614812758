import UserService from './services/UserService'
import { RecoilRoot } from 'recoil'
import { createRoot } from 'react-dom/client'
import './index.css'
import { App } from './App'
import { BrowserRouter } from 'react-router-dom';

const container = document.getElementById('app')
const root = createRoot(container!)
const renderApp = () =>
    root.render(
        // <React.StrictMode> // adds one render
        <RecoilRoot>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </RecoilRoot>,
        //</React.StrictMode>
    )
UserService.initKeycloak(renderApp)
