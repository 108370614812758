import React from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { useTaskActions, useCropActions } from '../_actions'
import { machineControlAtom, taskQuery, taskAtom, h2lFieldJsonAtom } from '../_state'

export { Controller }

function Controller(): null {
    // console.log('[Controller]: Render');
    const setTask = useSetRecoilState(taskQuery)
    const task = useRecoilValue(taskAtom)
    const machineControl = useRecoilValue(machineControlAtom)
    const setH2lFieldJson = useSetRecoilState(h2lFieldJsonAtom)
    const taskActions = useTaskActions()
    const cropActions = useCropActions()

    /**
     * Draws task when machine controls is updated from another user
     */
    React.useEffect(() => {
        // console.log('[Machine Controller] useEffect machineControl');
        //if (machineControl?.task_id && machineControl?.running === true) {
        if (machineControl?.task_id && machineControl?.status === 3) {
            if (task?.id !== machineControl?.task_id) {
                taskActions
                    .getById(machineControl.task_id)
                    .then((res) => {
                        setTask({ data: res, isLoading: false })
                        cropActions.getCropByIdWithTulibBeds(res.crop_id).then(setH2lFieldJson)
                    })
                    .catch((e) => console.error(e))
            }
        }
        //if (machineControl?.task_id && machineControl?.running === false) {
        if (machineControl?.task_id && machineControl?.status === 3) {
            taskActions
                .getById(machineControl.task_id)
                .then((res) => {
                    setTask({ data: res, isLoading: false })
                    cropActions.getCropByIdWithTulibBeds(res.crop_id).then(setH2lFieldJson)
                })
                .catch((e) => console.error(e))
        }
    }, [machineControl])

    return null
}
