import { atom, selector } from 'recoil'
import { MSU } from '../types'
import { Socket } from 'socket.io-client'

const machineModelAtom = atom<'S180' | 'S225' | 'IC300' | null>({
    key: 'machine_model',
    default: null,
})

const machineMSUAtom = atom<MSU | null>({
    key: 'machine_msu',
    default: null,
})

const machineNameAtom = atom<string | null>({
    key: 'machine_name',
    default: null,
})

const machineSocketAtom = atom<Socket | null>({
    key: 'machine_socket',
    default: null,
    dangerouslyAllowMutability: true,
})

const machineSelector = selector({
    key: 'machine',
    dangerouslyAllowMutability: true,
    get: ({ get }) => {
        const machineName = get(machineNameAtom)
        const machineSocket = get(machineSocketAtom)
        if (machineName && machineSocket) {
            return { name: machineName, socket: machineSocket }
        } else {
            return null
        }
    },
})

export { machineSelector, machineNameAtom, machineSocketAtom, machineModelAtom, machineMSUAtom }
