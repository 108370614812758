import React from 'react'
import { styled } from '@mui/system'

interface FormProps {
    children: React.ReactNode
}

const StyledForm = styled('form')(({ theme }) => ({
    width: '100%',
    marginTop: theme.spacing(1),
}))

const Form: React.FC<FormProps> = ({ children, ...props }) => {
    return <StyledForm {...props}>{children}</StyledForm>
}

export { Form }
