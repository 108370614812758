import { AppBar, Toolbar, Stack, Dialog, Typography, DialogContent, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

interface FullscreenIFrameProps {
    title: string
    url: string
    handleClose: () => void
}
export const FullscreenIFrame = ({ title, url, handleClose }: FullscreenIFrameProps) => (
    <Dialog fullScreen open={true} onClose={handleClose}>
        <Stack direction={'row'} justifyContent={'space-between'}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
                        {title}
                    </Typography>
                    <IconButton color='inherit' onClick={handleClose} aria-label='close' size='large'>
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
        </Stack>
        <DialogContent sx={{ p: 0, overflow: 'hidden' }}>
            <iframe width='100%' height='100%' src={url} title={title}></iframe>
        </DialogContent>
    </Dialog>
)
