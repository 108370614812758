import React, { useState, lazy, Suspense } from 'react'
import { AppBar, Toolbar, Stack, Button, Dialog, Typography, DialogContent, IconButton } from '@mui/material'
import { useRecoilValue } from 'recoil'
import { tulipMemoryStateAtom } from '../_state'
import CloseIcon from '@mui/icons-material/Close'

const LazyTulipMemoryViewer = lazy(() => import('./TulipMemoryViewer'))

function LoadingFallback() {
    return <div>Loading Tulip Memory Viewer...</div>
}

export function TulipMemoryViewerDialog() {
    const [open, setOpen] = useState(false)
    const tulipMemory = useRecoilValue(tulipMemoryStateAtom)

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    if (!tulipMemory?.tm_enabled) return null

    return (
        <>
            <Button sx={{ mb: 1 }} color='secondary' variant='outlined' onClick={handleOpen}>
                Open Dialog
            </Button>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth='xl' fullScreen>
                <Stack direction={'row'} justifyContent={'space-between'}>
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
                                {'Tulip Memory'}
                            </Typography>
                            <IconButton color='inherit' onClick={handleClose} aria-label='close' size='large'>
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                </Stack>
                <DialogContent>
                    <Suspense fallback={<LoadingFallback />}>
                        <LazyTulipMemoryViewer />
                    </Suspense>
                </DialogContent>
            </Dialog>
        </>
    )
}
