import * as React from 'react'
import { Alert, Stack, Button } from '@mui/material'
import { machineNameAtom, fluidInstrumentationAlarmSelector } from '../../../_state'
import { useRecoilValue } from 'recoil'

import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { useFluidSafetyActions } from '../../../_actions'

export const DoseLimitAlerts = () => {
    const machineName = useRecoilValue(machineNameAtom)
    if (!machineName) return
    const machineDoseAlarmMsg: string[] = useRecoilValue(fluidInstrumentationAlarmSelector)
    const fluidSafetyActions = useFluidSafetyActions()
    if (!Array.isArray(machineDoseAlarmMsg) || machineDoseAlarmMsg.length === 0) {
        return null
    }
    return (
        <Stack direction={'row'} spacing={1} sx={{ mb: 1 }}>
            <Stack spacing={1} sx={{ width: '100%' }}>
                {machineDoseAlarmMsg &&
                    machineDoseAlarmMsg.map((msg, index) => (
                        <Alert key={index} severity='error'>
                            {msg}
                        </Alert>
                    ))}
            </Stack>
            <Button
                endIcon={<HighlightOffIcon />}
                variant='contained'
                color='secondary'
                sx={{ alignSelf: 'center' }}
                onClick={() => {
                    fluidSafetyActions.alarmsClear(machineName)
                }}
            >
                Clear
            </Button>
        </Stack>
    )
}
