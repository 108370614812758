import React from 'react'
import { GoogleMap, useJsApiLoader, Libraries } from '@react-google-maps/api'
import { useSetRecoilState } from 'recoil'
import { mapAtom } from '../_state'
import { RobotIcon } from './'
import { CropTask } from '../gmap/CropTask'
import { MapMachines } from '../service/MapMachines'

interface MapProps {
    children?: React.ReactNode
}

const libraries: Libraries = ['drawing', 'marker']

export function Map({ children }: MapProps) {
    // console.log('[Map]: render', view)
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_API_KEY}`,
        libraries: libraries,
    })
    const setMap = useSetRecoilState(mapAtom)
    React.useEffect(() => {
        return setMap(null)
    }, [])

    const loadHandler = (map: google.maps.Map) => {
        setMap(map)
        map.setOptions({
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
            rotateControl: false,
            scaleControl: true,
            mapId: '3b57e677ce70cdef', //this is necessary for rotation, it makes the map use WebGL. I generated it on GoogleCloudConsole
            mapTypeId: 'satellite',
            zoomControl: false,
            zoom: 8,
            center: new window.google.maps.LatLng(51.987, 5.5825),
            minZoom: 6,
            gestureHandling: 'greedy',
            // initialCenter: {
            //   lat: 51.987,
            //   lng: 5.5825,
            // },
        })
    }
    const renderMap = () => {
        return (
            <GoogleMap id='map-canvas' onLoad={loadHandler}>
                {children}
            </GoogleMap>
        )
    }
    return isLoaded ? renderMap() : null
}
