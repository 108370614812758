import { atom, selector } from 'recoil'
import { H2LFieldJson, BedGeoJSON, CultivarGroup } from '../types'
import { selectedGroupIDsAtom } from './selectedGroupIDs'


const h2lFieldJsonAtom = atom<H2LFieldJson | null>({
    key: 'h2lFieldJson',
    default: null,
})

// TODO move to field type
const cultivarListSelector = selector({
    key: 'cultivar_list',
    get: ({ get }): Array<CultivarGroup> | null => {
        const cultivarList = get(h2lFieldJsonAtom)?.beds
        if (cultivarList) {
            const groups: Array<CultivarGroup> = []
            cultivarList.forEach((bed: BedGeoJSON) => {
                groups[bed.properties.group_id] = {
                    name: bed.properties.name,
                    cultivar: bed.properties.cultivar,
                    group_id: bed.properties.group_id,
                    size: bed.properties.size,
                    strokeColor: bed.properties.strokeColor,
                }
            })
            const cultivarGroupList: Array<CultivarGroup> = []
            Object.keys(groups).forEach(function (key) {
                cultivarGroupList.push(groups[key])
            })
            return cultivarGroupList
        } else {
            return null
        }
    },
})

const groupIDsToBedIDsSelector = selector({
    key: 'groupIDsToBedIDs',
    get: ({ get }): Array<number> | [] => {
        const h2lFieldJson = get(h2lFieldJsonAtom)
        const selectedGroupIDs = get(selectedGroupIDsAtom)
        const bedIDs: number[] = []
        if (selectedGroupIDs && h2lFieldJson) {
            h2lFieldJson.beds.forEach((bed) => {
                if (selectedGroupIDs.has(bed.properties.group_id) && !bed.properties.skip) {
                    bedIDs.push(bed.id)
                }
            })
        }
        return bedIDs
    },
})

export { h2lFieldJsonAtom, cultivarListSelector, groupIDsToBedIDsSelector }
