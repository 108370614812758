import { Typography, Skeleton } from '@mui/material'
interface ListSkeletonProps {
    skeletonsToRender: number
    height: number
}
const ListSkeleton = ({ skeletonsToRender, height }: ListSkeletonProps) => {
    return (
        <>
            {Array(skeletonsToRender)
                .fill(1)
                .map((card, index) => (
                    <Typography key={index} variant='h2'>
                        <Skeleton key={index} height={height} />
                    </Typography>
                ))}
        </>
    )
}
export { ListSkeleton }
