import React from 'react'
import { useRecoilValue } from 'recoil'
import { Backdrop, Stack } from '@mui/material'
import { machineOnlineStatusAtom } from '../_state'
import { useLocation } from 'react-router-dom'
import { CircularStatic } from '../_components'
import SignalCellularNodataIcon from '@mui/icons-material/SignalCellularNodata'

interface OfflineBackdropProps {
    children?: React.ReactNode
}

export default function OfflineBackdrop({ children }: OfflineBackdropProps) {
    const location = useLocation()

    const machineOnline = useRecoilValue(machineOnlineStatusAtom)
    return (
        <>
            {machineOnline ? (
                <>{children}</>
            ) : (
                <>
                    {!location.pathname.includes('support') && (
                        <Backdrop sx={{ color: '#fff' }} open={true}>
                            <Stack direction='column' alignItems='center' justifyContent='center'>
                                <CircularStatic size={60}>
                                    <SignalCellularNodataIcon fontSize='large' />
                                </CircularStatic>
                            </Stack>
                        </Backdrop>
                    )}
                </>
            )}
        </>
    )
}
