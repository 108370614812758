import React, { useState, useEffect } from 'react'
import { Slider, Typography, Stack, Box } from '@mui/material'
import { clusteringThresholdAtom, machineSocketAtom } from '../../_state'
import { useRecoilValue } from 'recoil'
import { ThreeTulipsIcon } from '../icons/ThreeTulipsIcon'
import { stripDecimals, valuetext } from '../../_helpers/utils'
import IncrementDecrementButtons from './IncrementDecrementButtons'
import { useAlertActions } from '../../_actions'
import { InfoBox } from './ThresholdSetting'

const STEP = 0.005
const MIN = 0.0
const MAX = 1

export const ScoreThresholdSlider: React.FC = () => {
    const [value, setValue] = useState<number>(MAX)
    const [loading, setLoading] = useState<boolean>(false)
    const [disabled, setDisabled] = useState<boolean>(false)

    const threshold = useRecoilValue(clusteringThresholdAtom)
    const socket = useRecoilValue(machineSocketAtom)

    const alertActions = useAlertActions()

    if (!socket) {
        console.error('Socket not available')
        return <></>
    }

    const handleChange = (newValue: number) => {
        setLoading(true)
        socket.emit(
            'send_command',
            {
                service: 'clustering',
                command: 'set_threshold',
                kwargs: { threshold: newValue },
            },
            () => setLoading(false),
        )
    }

    const handleChangeCommitted = (_: Event | React.SyntheticEvent, newValue: number | number[]) => {
        if (typeof newValue === 'number') {
            handleChange(newValue)
        }
    }

    useEffect(() => {
        if (threshold != null && threshold >= MIN && threshold <= MAX) {
            setValue(stripDecimals(threshold))
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }, [threshold])

    const handleIncrement = () => {
        const newValue = stripDecimals(value + STEP)
        if (newValue <= MAX) {
            handleChange(newValue)
        } else {
            alertActions.error(`Threshold cannot have a value greater than ${MAX}`)
        }
    }

    const handleDecrement = () => {
        const newValue = stripDecimals(value - STEP)
        if (newValue >= MIN) {
            handleChange(newValue)
        } else {
            alertActions.error(`Threshold cannot have a value less than ${MIN}`)
        }
    }

    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-end'} sx={{ mb: '10px' }}>
                <Typography sx={{ fontWeight: 500 }} gutterBottom>
                    {`Threshold: ${threshold}`}
                </Typography>
                <InfoBox />
            </Stack>
            <Stack direction={'row'} alignItems={'center'}>
                <ThreeTulipsIcon />
                <Typography variant='caption' sx={{ ml: 0.5 }}>
                    {MIN}
                </Typography>
                <Slider
                    sx={{
                        '&.MuiSlider-root.Mui-disabled': {
                            color: 'DimGray',
                        },
                        '& .MuiSlider-mark': {
                            display: 'none', // Hide the steps
                        },
                        mr: 2,
                        ml: 2,
                    }}
                    color='secondary'
                    key={`slider-${stripDecimals(value)}`}
                    defaultValue={stripDecimals(value)}
                    getAriaValueText={valuetext}
                    valueLabelDisplay='auto'
                    step={STEP}
                    marks
                    min={MIN}
                    max={MAX}
                    disabled={disabled || loading}
                    onChangeCommitted={handleChangeCommitted}
                />
                <Typography variant='caption' sx={{ mr: 0.5 }}>
                    {MAX}
                </Typography>
                {'🌷'}
            </Stack>
            <Box sx={{ ml: 2, mr: 2, mb: 2 }}>
                <IncrementDecrementButtons
                    onIncrement={handleIncrement}
                    onDecrement={handleDecrement}
                    disableIncrement={value + STEP > MAX || disabled}
                    disableDecrement={value - STEP < MIN || disabled}
                    loading={loading}
                />
            </Box>
        </>
    )
}
