import { useFetchWrapper } from '../_helpers'
import { Company } from '../types'

export { useCompanyActions }

function useCompanyActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`
    const fetchWrapper = useFetchWrapper()

    return {
        getAll,
    }

    function getAll(): Promise<Company[]|[]> {
        return fetchWrapper.get(`${baseUrl}/companies`)
    }
}
