import React from 'react'
import { useAlertActions } from '../_actions'
import { useNavigate } from 'react-router-dom'

interface BoundaryDrawProps {
    boundary: google.maps.Data
    map: google.maps.Map
}

export function BoundaryDraw(props: BoundaryDrawProps) {
    //stackoverflow.com/questions/37240211/google-maps-api-togeojson-returns-object-with-geometry-null
    const navigate = useNavigate()
    const alert = useAlertActions()
    const dm = React.useRef(
        new google.maps.drawing.DrawingManager({
            drawingMode: google.maps.drawing.OverlayType.POLYGON,
            drawingControl: false,
            polygonOptions: {
                strokeColor: '#FFFFFF',
                strokeOpacity: 1.0,
                strokeWeight: 3,
            },
        }),
    )
    dm.current.setMap(props.map)

    React.useEffect(() => {
        alert.info('Click on the map to start drawing a new boundary')

        props.boundary.setMap(null)
        // Drawing returns an OverlayType which is not the same a shape
        google.maps.event.addListener(dm.current, 'polygoncomplete', function (polygon: google.maps.Polygon) {
            polygon.setMap(null)
            //TODO probably there is a better way to do this
            const coords = polygon
                .getPath()
                .getArray()
                .map((a) => [a.lng(), a.lat()])
            // Construct the polygon.
            const closed_coords = [[...coords, coords[0]]]

            // removes previous boundary
            props.boundary.forEach((f) => {
                props.boundary.remove(f)
            })

            //TODO move out of here
            const properties = {
                strokeColor: '#FFFFFF',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#FFFFFF',
                fillOpacity: 0.1,
            }
            props.boundary.addGeoJson({
                id: -1,
                type: 'Feature',
                properties: properties,
                geometry: {
                    type: 'Polygon',
                    coordinates: closed_coords,
                },
            })
            navigate(location.pathname.split('/').slice(0, -1).join('/') + '/boundary-edit')
        })
        return () => {
            props.boundary.setMap(props.map)
            dm.current.setMap(null)
        }
    }, [])

    return null
}
