
import { atom } from 'recoil'
import { OnlyStatus } from '../types'

const robotControlAtom = atom<OnlyStatus | null>({
    key: 'robot_control',
    default: null,
})

export { robotControlAtom }
