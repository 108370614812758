import { IconButton, Typography, Box, Grid } from '@mui/material'
import ControlCameraIcon from '@mui/icons-material/ControlCamera'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { useTheme } from '@mui/material/styles'
import { useRecoilValue } from 'recoil'
import { machineSocketAtom } from '../_state'

interface ArrowJoystickProps {
    dispenser: 1 | 2
}

export const ArrowJoystick = ({ dispenser }: ArrowJoystickProps) => {
    const socket = useRecoilValue(machineSocketAtom)
    const theme = useTheme()
    function move(x: number, y: number, zero: boolean) {
        // console.log(`x=${x}:y=${y}:zero=${zero}:dispenser=${dispenser}`)
        if (!socket) {
            console.error('Socket is not available')
            return
        }
        socket.emit('send_command', {
            service: 'treatment',
            command: 'adjust_offset',
            kwargs: { x: x, y: y, zero: zero, dispenser: dispenser },
        })
    }

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Grid container direction='column' justifyContent='space-evenly' alignItems='center'>
                    <Grid item>
                        <IconButton
                            sx={{ backgroundColor: theme.palette.success.main, mb: 2 }}
                            size='large'
                            onClick={() => move(0, +10, false)}
                        >
                            <ArrowUpwardIcon />
                            <Typography variant='caption' display='block'>
                                +10
                            </Typography>
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <IconButton
                            sx={{ backgroundColor: theme.palette.success.main, mb: 1 }}
                            onClick={() => move(0, +5, false)}
                            size='large'
                        >
                            <ArrowUpwardIcon />
                            <Typography variant='caption' display='block'>
                                +5
                            </Typography>
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction='row'
                    justifyContent='space-evenly'
                    alignItems='center'
                    sx={{ mt: 1, mb: 1 }}
                >
                    <Grid item>
                        <IconButton
                            sx={{ backgroundColor: theme.palette.primary.main }}
                            onClick={() => move(-10, 0, false)}
                            size='large'
                        >
                            <ArrowBackIcon />
                            <Typography variant='caption' display='block'>
                                -10
                            </Typography>
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <IconButton
                            sx={{ backgroundColor: theme.palette.primary.main }}
                            onClick={() => move(-5, 0, false)}
                            size='large'
                        >
                            <ArrowBackIcon />
                            <Typography variant='caption' display='block'>
                                -5
                            </Typography>
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={() => move(0, 0, true)} size='large'>
                            <ControlCameraIcon />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <IconButton
                            sx={{ backgroundColor: theme.palette.success.main }}
                            onClick={() => move(+5, 0, false)}
                            size='large'
                        >
                            <Typography variant='caption' display='block'>
                                +5
                            </Typography>
                            <ArrowForwardIcon />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <IconButton
                            sx={{ backgroundColor: theme.palette.success.main }}
                            size='large'
                            onClick={() => move(+10, 0, false)}
                        >
                            <Typography variant='caption' display='block'>
                                +10
                            </Typography>
                            <ArrowForwardIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid container direction='column' justifyContent='space-evenly' alignItems='center'>
                    <Grid item>
                        <IconButton
                            sx={{ backgroundColor: theme.palette.primary.main, mt: 1 }}
                            onClick={() => move(0, -5, false)}
                            size='large'
                        >
                            <ArrowDownwardIcon />
                            <Typography variant='caption' display='block'>
                                -5
                            </Typography>
                        </IconButton>
                    </Grid>
                    <Grid>
                        <IconButton
                            sx={{ backgroundColor: theme.palette.primary.main, mt: 2 }}
                            size='large'
                            onClick={() => move(0, -10, false)}
                        >
                            <ArrowDownwardIcon />
                            <Typography variant='caption' display='block'>
                                -10
                            </Typography>
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}
