import React, { useEffect, useState } from 'react'
import { Stack, Slider, Typography } from '@mui/material'
import { valuetext } from '../../../_helpers/utils'
import {
    fluidInstrumentationPressureSetpointSelector,
    fluidInstrumentationTankPressureSelector,
    treatmentDispenseDuration,
} from '../../../_state'
import { useRecoilValue } from 'recoil'
import { useTheme } from '@mui/material/styles'
import { InfoPopover } from '../..'
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined'
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined'

interface DoseSliderProps {
    step: number
    min: number
    max: number
    socketAttr: string
    infoText: string[]
    callback: (v: number | number[]) => void
}

export const DoseSlider = ({ step, min, max, socketAttr, infoText, callback }: DoseSliderProps) => {
    const theme = useTheme()
    const [loading, setLoading] = useState(false)
    const [isBlinking, setIsBlinking] = useState(false)
    let value: number | null = 0
    let title = ''
    let unit = ''
    let markValue: number | null = null
    if (socketAttr === 'tank_pressure') {
        title = 'Doseerdruk'
        unit = 'bar'
        markValue = useRecoilValue(fluidInstrumentationTankPressureSelector)
        value = useRecoilValue(fluidInstrumentationPressureSetpointSelector)
    } else if (socketAttr === 'dispense_duration') {
        title = 'Doseertijd'
        unit = 'ms'
        value = useRecoilValue(treatmentDispenseDuration)
    }
    const [sliderValue, setSliderValue] = useState(value)

    const handleChangeCommited = (e: Event | React.SyntheticEvent<Element, Event>, value: number | number[]) => {
        setLoading(true)
        callback(value)
        setLoading(false)
    }
    const handleChange = (e: Event | React.SyntheticEvent<Element, Event>, newValue: number | number[]) => {
        setSliderValue(newValue as number)
    }

    useEffect(() => {
        setIsBlinking(true)
        if (value !== null) {
            setSliderValue(value)
        }
        setTimeout(() => {
            setIsBlinking(false)
        }, 1000)
    }, [value])

    // Blink when others change the setting
    useEffect(() => {
        setIsBlinking(true)
        setTimeout(() => {
            setIsBlinking(false)
        }, 1000)
    }, [markValue])

    return (
        <>
            <Stack direction='row' justifyContent={'space-between'} alignItems={'center'}>
                <Stack direction='row' alignItems={'center'} spacing={1}>
                    {socketAttr === 'tank_pressure' ? (
                        <>
                            <Typography sx={{ fontWeight: 500 }}>
                                {title}:{' '}
                                <span style={{ animation: isBlinking ? 'blink 1s linear' : 'none' }}>
                                    {markValue} {unit}
                                </span>
                            </Typography>
                            <SpeedOutlinedIcon />
                        </>
                    ) : (
                        <>
                            <Typography sx={{ fontWeight: 500 }}>
                                {title}:{' '}
                                <span style={{ animation: isBlinking ? 'blink 1s linear' : 'none' }}>
                                    {value} {unit}
                                </span>
                            </Typography>
                            <TimerOutlinedIcon />
                        </>
                    )}
                </Stack>
                <InfoPopover content={infoText} />
            </Stack>
            <Stack direction={'row'} alignItems={'center'}>
                <Typography variant='caption'>{min}</Typography>
                <Slider
                    sx={{
                        pointerEvents: 'none !important',
                        '& .MuiSlider-rail, & .MuiSlider-track': {
                            pointerEvents: 'none',
                        },
                        '& .MuiSlider-thumb': {
                            pointerEvents: 'auto',
                            display: loading || value === null ? 'none' : 'block', // Hides the thumb when disabled
                        },
                        '& .MuiSlider-mark': {
                            height: '35px',
                            width: '5px',
                            backgroundColor: theme.palette.success.main,
                            opacity: 1,
                        },
                        mr: 2,
                        ml: 2,
                    }}
                    valueLabelDisplay={'auto'}
                    color='secondary'
                    step={step}
                    min={min}
                    max={max}
                    disabled={loading || value === null}
                    getAriaValueText={valuetext}
                    marks={markValue !== null ? [{ value: markValue }] : false}
                    value={sliderValue !== null ? sliderValue : 0}
                    onChangeCommitted={handleChangeCommited}
                    onChange={handleChange}
                />
                <Typography variant='caption'>{max}</Typography>
            </Stack>
        </>
    )
}
