import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { Dialog, AppBar, Toolbar, IconButton, Typography, Slide } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { TransitionProps } from '@mui/material/transitions'
import { PdfView } from '../_components'
export { PdfDialog }

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction='up' ref={ref} {...props} />
})

interface PdfDialogProps {
    title: string
    document: string
}
function PdfDialog({ title, document }: PdfDialogProps) {
    const navigate = useNavigate()
    const handleClose = () => navigate(-1)
    return (
        <React.Fragment>
            <Dialog fullScreen open={true} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
                            {title}
                        </Typography>
                        <IconButton edge='start' color='inherit' onClick={handleClose} aria-label='close'>
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <PdfView pdf={`${process.env.REACT_APP_API_URL}/api/v1/utils/${document}`} />
            </Dialog>
        </React.Fragment>
    )
}
