import { valuetext } from '../_helpers/utils'
import Slider from '@mui/material/Slider'
import Typography from '@mui/material/Typography'

interface RangeSliderProps {
    title?: string
    value: number
    unit: string
    step: number
    min: number
    max: number
    handleChange: (value: number) => void
}

export const RangeSlider: React.FC<RangeSliderProps> = ({
    value,
    unit,
    step,
    min,
    max,
    handleChange,
    title,
}: RangeSliderProps) => {
    return (
        <>
            <Typography variant='body1' gutterBottom>
                {title && `${title}:`} {value} {unit}
            </Typography>
            <Slider
                key={`slider-${value}`}
                aria-label='bed-extension'
                defaultValue={value}
                getAriaValueText={valuetext}
                color={'secondary'}
                valueLabelDisplay='auto'
                step={step}
                marks
                min={min}
                max={max}
                onChangeCommitted={(e, val) => {
                    handleChange(val as number)
                }}
            />
        </>
    )
}
