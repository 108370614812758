import React, { useEffect, useState } from 'react'
import { useMachineActions } from '../_actions'
import { Container, Box, Typography, Grid } from '@mui/material'
import { Machine } from '../types'

export { List }
function List() {
    const machineActions = useMachineActions()
    const [machines, setMachines] = useState<Machine[]>([])

    useEffect(() => {
        machineActions.getAll().then((machines) => {
            setMachines(machines)
        })
    }, [])

    const cameraList = machines.map(({ machine_name }) => (
        <React.Fragment key={`key-${machine_name}`}>
            <Grid item xs={12} md={2}>
                {/* <CameraView machineName={machineName} camera_num={7} /> */}
            </Grid>
        </React.Fragment>
    ))

    return (
        <Container sx={{ height: '100%' }}>
            <Typography variant='h3' sx={{ mb: 2 }}>
                Cameras
            </Typography>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={1}>
                    {cameraList}
                </Grid>
            </Box>
        </Container>
    )
}
