import { InServiceForm, ServiceFormOut } from '../service/types'
import { useFetchWrapper } from '../_helpers'
export { useServiceFormActions }

function useServiceFormActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`
    const fetchWrapper = useFetchWrapper()

    return {
        getByMachineName,
        getAll,
        submit,
        downloadPDF,
        downloadCSV,
    }

    async function getByMachineName(machine: string): Promise<ServiceFormOut[] | []> {
        return fetchWrapper.get(`${baseUrl}/service_forms/machine/${machine}`)
    }

    async function getAll(): Promise<ServiceFormOut[] | []> {
        return fetchWrapper.get(`${baseUrl}/service_forms`)
    }

    async function submit(formData: InServiceForm) {
        return fetchWrapper.post(`${baseUrl}/service_forms/submit`, formData)
    }

    async function downloadPDF(id: number): Promise<void> {
        return fetchWrapper.get(`${baseUrl}/service_forms/id/${id}/download_pdf`)
    }

    async function downloadCSV(): Promise<void> {
        return fetchWrapper.get(`${baseUrl}/service_forms/to_csv`)
    }
}

