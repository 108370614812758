import React, { useState } from 'react'
import { TransferMachineIcon } from '../../_components'
import {
    Button,
    Stack,
    IconButton,
    Typography,
    Dialog,
    DialogContent,
    DialogActions,
    DialogContentText,
    DialogTitle,
} from '@mui/material'

interface TransferStatusDialogProps {
    machineName: string
    companyName: string
    ownerCompany: string
}

export const TransferStatusDialog = ({ machineName, companyName, ownerCompany }: TransferStatusDialogProps) => {
    const [infoOpen, setInfoOpen] = useState(false)

    const handleLendIconClick = () => setInfoOpen(true)
    const handleLendIconClose = () => setInfoOpen(false)

    if (companyName === ownerCompany) return null
    return (
        <>
            <IconButton
                size='small'
                onClick={handleLendIconClick}
                sx={{
                    pl: 0.25,
                    pr: 0.25,
                    pt: 0.25,
                    pb: 0.25,
                    bgcolor: 'white',
                    borderRadius: '4px',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                    border: '1px solid rgba(0, 0, 0, 0.1)',
                    '&:hover': {
                        bgcolor: 'lightgray',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                    },
                }}
            >
                <TransferMachineIcon sx={{ color: 'black' }} />
            </IconButton>
            <Dialog open={infoOpen} onClose={handleLendIconClose} onClick={(e) => e.stopPropagation()}>
                <DialogTitle>
                    <Stack direction='row' alignItems='center' spacing={1}>
                        <TransferMachineIcon sx={{ color: 'black' }} />
                        <Typography variant='h6'>{machineName}</Typography>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Machine {`${machineName} owned by `}
                        <Typography component='span' style={{ fontWeight: 'bold' }}>
                            {ownerCompany}
                        </Typography>
                        {' is currently let to '}
                        <Typography component='span' style={{ fontWeight: 'bold' }}>
                            {companyName}
                        </Typography>
                        .
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleLendIconClose()} sx={{ color: 'black' }} autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
