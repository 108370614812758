import React, { useEffect, useState } from 'react'
import { CircularStatic } from '../_components'
import Backdrop from '@mui/material/Backdrop'
import CloudOffIcon from '@mui/icons-material/CloudOff'

export { NetworkStatus }
function NetworkStatus() {
    const [status, setStatus] = useState(true)

    useEffect(() => {
        function changeStatus() {
            setStatus(navigator.onLine)
        }
        window.addEventListener('online', changeStatus)
        window.addEventListener('offline', changeStatus)
        return () => {
            window.removeEventListener('online', changeStatus)
            window.removeEventListener('offline', changeStatus)
        }
    }, [])
    let offlineBackdrop = <></>
    if (!status) {
        offlineBackdrop = (
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={!status}>
                <CircularStatic size={60}>
                    <CloudOffIcon fontSize='large' />
                </CircularStatic>
            </Backdrop>
        )
    }
    return offlineBackdrop
}
