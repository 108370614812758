import React, { useRef, useState, useEffect } from 'react'
import { Socket } from 'socket.io-client'
import { treatmentAtom } from '../../_state'
import { useRecoilValue } from 'recoil'
import { LightIcon } from '../icons/LightIcon'
import { Stack, Switch, Typography, LinearProgress } from '@mui/material'
import { green, grey } from '@mui/material/colors'

export { LightsControl }

type LightsControlProps = {
    socket: Socket
}

function LightsControl({ socket }: LightsControlProps) {
    const toggleUserInputVal = useRef<boolean | null>(null)
    const [checked, setChecked] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [loading, setLoading] = useState(false)
    const treatment = useRecoilValue(treatmentAtom)

    useEffect(() => {
        if (treatment == null) {
            setDisabled(true)
            return
        }

        if (treatment?.lights == null) {
            setDisabled(true)
        } else {
            setDisabled(false)
            if (treatment.lights === toggleUserInputVal.current && loading) {
                setLoading(false)
            }
            if (treatment.lights !== checked) {
                setChecked(treatment.lights)
            }
        }
    }, [treatment, loading, checked])

    if (!treatment) return null

    return (
        <>
            <Typography id='lights-control-desc' sx={{ fontWeight: 500 }}>
                Switch on/off lights
            </Typography>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <LightIcon />
                <Switch
                    sx={{
                        '& .MuiSwitch-switchBase': {
                            color: disabled ? grey[300] : grey[500],
                            '&.Mui-checked': {
                                color: disabled ? grey[300] : green[300],
                                '& + .MuiSwitch-track': {
                                    backgroundColor: disabled ? grey[300] : green[500],
                                },
                            },
                        },

                        '& .MuiSwitch-thumb': {
                            color: disabled ? grey[300] : undefined,
                        },
                        '& .MuiSwitch-track': {
                            backgroundColor: '#fff',
                            opacity: 1,
                        },
                    }}
                    checked={checked}
                    disabled={disabled}
                    onChange={() => {
                        setDisabled(true)
                        setLoading(true)
                        if (checked) {
                            toggleUserInputVal.current = false
                            socket.emit('send_command', {
                                service: 'treatment',
                                command: 'set_lights',
                                kwargs: { state: 'off' },
                            })
                        } else {
                            toggleUserInputVal.current = true
                            socket.emit('send_command', {
                                service: 'treatment',
                                command: 'set_lights',
                                kwargs: { state: 'on' },
                            })
                        }
                    }}
                />
            </Stack>
            {loading && <LinearProgress />}
        </>
    )
}
