import React from 'react'
import { DispenseDose } from './DispenseDose'
import { Card, CardActionArea, CardContent, Stack, Typography, Box, Divider } from '@mui/material'
import { Link, useResolvedPath } from 'react-router-dom'
import { FluidSafetyIcon } from '../../../_components/icons/FluidSafetyIcon'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

const TreatmentSettingsButton = () => {
    const url = useResolvedPath('').pathname
    return (
        <Link to={`${url}/fluid-safety`} style={{ textDecoration: 'none', color: 'inherit' }}>
            <Card sx={{ display: 'flex', backgroundImage: 'none' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <CardActionArea>
                        <CardContent sx={{ flex: '1 0 auto', p: 0 }}>
                            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                <Stack>
                                    <Stack direction={'row'} justifyContent={'left'} alignItems={'center'} spacing={1}>
                                        <Typography component='div' variant='h6'>
                                            Spuitbeveiliging
                                        </Typography>
                                        <FluidSafetyIcon />
                                    </Stack>
                                    <Typography variant='body2' color='text.secondary' component='div' sx={{ pl: 1 }}>
                                        Instellingen voor spuitbeveiliginssysteem aanpassen
                                    </Typography>
                                </Stack>
                                <ArrowForwardIosIcon sx={{ mr: 2 }} />
                            </Stack>
                        </CardContent>
                    </CardActionArea>
                </Box>
            </Card>
        </Link>
    )
}

export const FluidSafetySettings = () => {
    return (
        <>
            <Divider sx={{ mt: 1, mb: 1 }} />
            <DispenseDose />
            <Divider sx={{ mt: 1, mb: 1 }} />
            <TreatmentSettingsButton />
        </>
    )
}
