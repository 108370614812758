import React, { useEffect, useState } from 'react'
import { Box, Slider, Typography, Stack } from '@mui/material'
import { InfoPopover } from '..'
import { useRecoilValue } from 'recoil'
import { machineSocketAtom, treatmentSpeedControlSelector } from '../../_state'

export const SpeedControl = () => {
    const [disabled, setDisabled] = React.useState(false)
    const [loading, setLoading] = useState(false)
    const [sliderValue, setSliderValue] = useState(2)
    const speed = useRecoilValue(treatmentSpeedControlSelector)
    const socket = useRecoilValue(machineSocketAtom)
    if (!socket) return

    const handleChangeCommited = (e: Event | React.SyntheticEvent<Element, Event>, value) => {
        setLoading(true)
        socket.emit('send_command', {
            service: 'treatment',
            command: 'set_speed_control',
            kwargs: { speed: value },
        })
        setTimeout(() => {
            setLoading(false)
        }, 2000)
    }

    useEffect(() => {
        if (speed !== null) {
            if ((speed >= 0 && speed <= 3) || speed != sliderValue) {
                setSliderValue(speed)
                setDisabled(false)
            }
        } else {
            setDisabled(true)
        }
        if (!speed) setDisabled(true)
    }, [speed])

    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography variant='h6'>Rijsnelheid</Typography>
                <Box sx={{ pr: 2 }}>
                    <InfoPopover
                        content={[
                            `🇳🇱<br />
                            laag: de machine rijdt altijd op de langzame snelheid.<br />
                            auto: de machine bepaalt zelf de snelheid aan de hand van zieke tulpen.<br />
                            hoog: de machine rijdt altijd op hoge snelheid en vertraagd niet bij veel zieke tulpen.`,
                            `🇬🇧<br />
                            low: the machine always runs at the slow speed.<br />
                            auto: the machine determines the speed itself based on sick tulips.<br />
                            high: the machine always drives at high speed and does not slow down when there are many sick tulips.`,
                        ]}
                    />
                </Box>
            </Stack>
            <Box sx={{ ml: 2, mr: 2, mb: 2 }}>
                {speed !== 5 ? (
                    <Stack>
                        <Typography variant='caption' sx={{ textAlign: 'center', mb: '-4px' }}>
                            {'Auto'}
                        </Typography>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography variant='caption'>Laag</Typography>
                            <Slider
                                key={`slider-${sliderValue}`}
                                sx={{
                                    mr: 2,
                                    ml: 2,
                                }}
                                color='secondary'
                                step={1}
                                min={1}
                                max={3}
                                disabled={loading || disabled}
                                defaultValue={sliderValue}
                                onChangeCommitted={handleChangeCommited}
                            />
                            <Typography variant='caption'>Hoog</Typography>
                        </Stack>
                    </Stack>
                ) : (
                    <Typography variant='body2' color='text.secondary'>
                        Update alstublieft uw machine naar de laatse software versie
                    </Typography>
                )}
            </Box>
        </>
    )
}
