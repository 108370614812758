import * as React from 'react'
import { useRecoilValue } from 'recoil'
import { treatmentAtom, machineSocketAtom, machineNameAtom, tulipMemoryStateAtom } from '../_state'
import { Typography, Stack } from '@mui/material/'
import { TulipMemoryViewerDialog } from './TulipMemoryViewerDialog'
import { CustomSwitch } from './CustomSwitch'
import UserService from '../services/UserService'
import { InfoPopover } from './InfoPopover'
export { TulipMemory }

function TulipMemory() {
    const tulipMemory = useRecoilValue(tulipMemoryStateAtom)
    const treatment = useRecoilValue(treatmentAtom)
    const socket = useRecoilValue(machineSocketAtom)
    const machineName = useRecoilValue(machineNameAtom)

    if (!socket) return
    // const Status = () => {
    //   if (treatment.running && tulipMemory.tm_enabled) {
    //     return (
    //       <Stack>
    //         <Typography variant='body1'>Tulips in memory: 1000</Typography>
    //         <Typography variant='body1'>Total in FieldName 1000</Typography>
    //         <Typography variant='body1'>Sprayed Tulips in this Row 150</Typography>
    //       </Stack>
    //     )
    //   } else if (treatment.running && !tulipMemory.tm_enabled) {
    //     return <Typography variant='body1'>Tulip Memory is disabled</Typography>
    //   } else if (!treatment.running && tulipMemory.tm_enabled) {
    //     return (
    //       <Typography variant='body1'>
    //         Tulip Memory Controls are available only during treating
    //       </Typography>
    //     )
    //   } else if (!treatment.running && !tulipMemory.tm_enabled) {
    //     return <Typography variant='body1'>Tulip Memory is disabled</Typography>
    //   } else {
    //     console.error('Invalid state')
    //     return <Typography variant='body1'>Tulip Memory is disabled</Typography>
    //   }
    // }

    if (!tulipMemory)
        return (
            <>
                <Typography variant='h6'>Tulip Memory</Typography>
                <Typography variant='body2' color='text.secondary' component='div' sx={{ pl: 1 }}>
                    Er is een probleem met het Tulip Memory, we werken eraan!
                </Typography>
            </>
        )
    //<Typography variant='body1' style={{ marginRight: '8px' }}>
    //State:
    //</Typography>
    //<Typography variant='body1' style={{ color: 'blue' }}>
    //{tulipMemory.state}
    //</Typography>

    return (
        <>
            <Stack sx={{ pr: 2 }} direction={'row'} justifyContent='space-between' alignItems='center'>
                <Stack direction={'row'} alignItems={'center'}>
                    <Typography variant='h6' gutterBottom>
                        Tulip Memory
                    </Typography>
                </Stack>
                <Stack direction={'row'} alignItems={'center'}>
                    {treatment ? (
                        <CustomSwitch
                            sx={{ m: 1 }}
                            checked={tulipMemory.tm_enabled}
                            onChange={() => {
                                socket.emit('send_command', {
                                    service: 'tulip_memory_server',
                                    command: 'tm_enabled',
                                    kwargs: {
                                        machine: machineName,
                                        val: tulipMemory.tm_enabled ? false : true,
                                    },
                                })
                            }}
                        />
                    ) : null}
                    <InfoPopover
                        content={[
                            `🇳🇱 Behandelde tulpen worden altijd opgeslagen, ongeacht of Tulip Memory is ingeschakeld of niet.<br>
                            FAQ:<br>
                            • Worden behandelde tulpen van het hele seizoen bewaard: Ja<br>
                            • Worden behandelde tulpen van de ene machine in het tulpengeheugen van een andere machine opgeslagen? Ja<br>
                            • Als twee machines elkaar opvolgen: Ze moeten één bedafstand van elkaar hebben. De behandelde tulpen worden gedownload aan het begin van een bed.`,
                            `🇬🇧 Treated tulips are always saved regardless of if Tulip Memory is enabled or not.<br>
                            FAQ:<br>
                            • Are treated tulips saved from the full season: Yes<br>
                            • Are treated tulips from one machine in the tulip memory of another machine: Yes<br>
                            • If two machines are following each other: They have to have one bed distance between them. The treated tulips are downloaded at the start of a turn.<br>`,
                        ]}
                    />
                </Stack>
            </Stack>
            {UserService.hasRole(['dev']) ? <TulipMemoryViewerDialog /> : null}
        </>
    )

    // return (
    //     <>
    //         <Stack direction={'row'} alignItems='center' justifyContent='space-between'>
    //             <Typography variant='h6' gutterBottom>
    //                 Tulip Memory
    //             </Typography>
    //             {treatment ? (
    //                 <CustomSwitch
    //                     sx={{ m: 1 }}
    //                     checked={tulipMemory.tm_enabled}
    //                     onChange={() => {
    //                         socket.emit('send_command', {
    //                             service: 'tulip_memory_server',
    //                             command: 'tm_enabled',
    //                             kwargs: {
    //                                 machine: machineName,
    //                                 val: tulipMemory.tm_enabled ? false : true,
    //                             },
    //                         })
    //                     }}
    //                 />
    //             ) : null}
    //         </Stack>
    //         <TulipMemoryViewerDialog />
    //     </>
    // )
}
