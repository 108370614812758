import React, { useEffect } from 'react'
import UserService from '../services/UserService'
import { useImpersonateUser } from './useImpersonateUser'
export const useKeyBindings = () => {
    const impersonate = useImpersonateUser()

    const impersonateUser = (event: KeyboardEvent) => {
        if (event.altKey && event.key === 'i') impersonate.toggle()
    }

    useEffect(() => {
        if (UserService.hasRole(['admin'])) window.addEventListener('keydown', impersonateUser)

        return () => {
            if (UserService.hasRole(['admin'])) window.removeEventListener('keydown', impersonateUser)
        }
    }, [])
}