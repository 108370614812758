/* eslint-disable camelcase */
import React, { useEffect, Suspense, lazy, ComponentType } from 'react'
import { io } from 'socket.io-client'
import UserService from '../services/UserService'
import { useParams, Outlet, Routes, Route } from 'react-router-dom'
import { Controller } from './Controller'
import Box from '@mui/material/Box'
import { ThemeProvider } from '@mui/material/styles'
import { mobileDarkTheme } from '../themes'
import CssBaseline from '@mui/material/CssBaseline'
import { styles } from '../styles'
import MachineViewAppBar from '../_components/mobile/MachineViewAppBar'
import { TabManager } from '../_components/mobile/TabManager'
import MachineStatusSub from '../_components/MachineStatusSub'
import { machineNameAtom, machineSelector, machineSocketAtom, h2lFieldJsonAtom, taskQuery } from '../_state'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { Transfer } from './transfer/Transfer'
import { TreatmentSafetyDialog } from '../_components/treatment/FluidSafety/TreatmentSafetyDialog'
import OfflineBackdrop from '../_components/OfflineBackdrop'

const Job = lazy(() => import('../jobs'))
const Logbook = lazy(() => import('../logbook'))
const SystemCommands = lazy(() =>
    import('../_components/SystemCommandsManager').then(
        (module) => ({ default: module.SystemCommands }) as { default: ComponentType<any> },
    ),
)
const FocusCameras = lazy(() => import('../focus_cameras'))
const CabinetsAssembly = lazy(() => import('../cabinets_assembly'))
const RobotCurrentTest = lazy(() => import('../robot_current_test/'))
const AcceptanceTest = lazy(() => import('../acceptance_test'))

export function RouteListener() {
    const { machine_name } = useParams<Record<string, string>>()
    if (!machine_name) return
    const setMachineName = useSetRecoilState(machineNameAtom)
    useEffect(() => {
        setMachineName(machine_name)
        return () => {
            setMachineName(null)
        }
    }, [machine_name])
    return null
}

export function View() {
    const setMachineStatusSocket = useSetRecoilState(machineSocketAtom)
    const machineName = useRecoilValue(machineNameAtom)
    const setTask = useSetRecoilState(taskQuery)
    const setCrop = useSetRecoilState(h2lFieldJsonAtom)
    const machine = useRecoilValue(machineSelector)

    useEffect(() => {
        if (machineName) {
            document.title = `${machineName} - H2L Selector`

            setMachineStatusSocket(
                io(`${process.env.REACT_APP_FSS_URL}/${machineName}`, {
                    auth: {
                        token: UserService.getToken(),
                        client_type: 'ui-client',
                    },
                }),
            )
        }

        return () => {
            document.title = 'H2L Selector'
            setMachineStatusSocket(null)
            setTask({ data: null, isLoading: false })
            setCrop(null)
        }
    }, [machineName])

    if (!machine) return null
    return (
        <>
            <MachineStatusSub />
            <Controller />
            <ThemeProvider theme={mobileDarkTheme}>
                <CssBaseline />
                <Box sx={[styles.MobileView.BoxOuter]}>
                    <Box sx={[styles.MobileView.BoxInner]}>
                        <MachineViewAppBar />
                    </Box>
                    <OfflineBackdrop>
                        <TabManager />
                    </OfflineBackdrop>
                    <Outlet />
                    <Routes>
                        <Route path='fluid-safety/*' element={<TreatmentSafetyDialog />} />
                        <Route
                            path='jobs/*'
                            element={
                                <Suspense fallback={<div>Loading...</div>}>
                                    <Job />
                                </Suspense>
                            }
                        />
                        <Route
                            path='logbook/*'
                            element={
                                <Suspense fallback={<div>Loading...</div>}>
                                    <Logbook />
                                </Suspense>
                            }
                        />
                        <Route
                            path='system-commands/*'
                            element={
                                <Suspense fallback={<div>Loading...</div>}>
                                    <SystemCommands />
                                </Suspense>
                            }
                        />
                        <Route
                            path='acceptance-tests/*'
                            element={
                                <Suspense fallback={<div>Loading...</div>}>
                                    <AcceptanceTest />
                                </Suspense>
                            }
                        />
                        <Route
                            path='focus-cameras/*'
                            element={
                                <Suspense fallback={<div>Loading...</div>}>
                                    <FocusCameras />
                                </Suspense>
                            }
                        />
                        <Route
                            path='cabinets-test-bench/*'
                            element={
                                <Suspense fallback={<div>Loading...</div>}>
                                    <CabinetsAssembly />
                                </Suspense>
                            }
                        />
                        <Route
                            path='robot-current-test/*'
                            element={
                                <Suspense fallback={<div>Loading...</div>}>
                                    <RobotCurrentTest />
                                </Suspense>
                            }
                        />
                        <Route
                            path='transfer/*'
                            element={
                                <Suspense fallback={<div>Loading...</div>}>
                                    <Transfer />
                                </Suspense>
                            }
                        />
                    </Routes>
                </Box>
            </ThemeProvider>
        </>
    )
}
