import React from 'react'
import { useRecoilValue } from 'recoil'
import { machineTaskStatus, machineControlAtom } from '../_state'
import { InferenceModelSelector, ModalBox, StartStopTaskButton } from './'
import { IconButton } from '@mui/material'
import { useGetBtnColor } from '../_helpers/utils'
import { GetBtnIcon } from './GetBtnIcon'

export const StartJobWrapper = () => {
    const [modalOpen, setModalOpen] = React.useState(false)
    const machineTaskState = useRecoilValue(machineTaskStatus)
    const machineControl = useRecoilValue(machineControlAtom)

    const RenderBtn = () => {
        if (machineTaskState === 'start' || machineTaskState === 'resume') {
            return (
                <IconButton
                    key='start-stop-task'
                    sx={{
                        '& .MuiButton-startIcon': { marginRight: '0px', marginLeft: '0px' },
                        bgcolor: useGetBtnColor(machineTaskState),
                        ':hover': {
                            backgroundColor: useGetBtnColor(machineTaskState),
                        },
                    }}
                    disabled={!machineControl}
                    onClick={() => setModalOpen(true)}
                    size='large'
                >
                    {GetBtnIcon(machineTaskState)}
                </IconButton>
            )
        } else {
            return <StartStopTaskButton />
        }
    }

    return (
        <>
            {modalOpen && (
                <ModalBox
                    title={'Herkennings Model Selectie'}
                    open={modalOpen}
                    handleDialogClose={() => setModalOpen(false)}
                >
                    <InferenceModelSelector handleModalClose={() => setModalOpen(false)} />
                </ModalBox>
            )}
            {<RenderBtn />}
        </>
    )
}
