import React, { useState, MouseEvent } from 'react'
import { getTimeSinceNow } from '../_helpers'
import {
    Stack,
    Box,
    Chip,
    List,
    ListItem,
    Menu,
    IconButton,
    Tooltip,
    ListItemIcon,
    MenuItem,
    Typography,
} from '@mui/material'
import { Ticket } from '../types'
import { grey } from '@mui/material/colors'
import PersonIcon from '@mui/icons-material/Person'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import ContentPasteIcon from '@mui/icons-material/ContentPaste'
import { Link } from 'react-router-dom'
import UserService from '../services/UserService'
import LaunchIcon from '@mui/icons-material/Launch'
import MoreVertIcon from '@mui/icons-material/MoreVert'

interface TicketMenuProps {
    machineName: string
    ticket: Ticket
}
const TicketMenu = ({ machineName, ticket }: TicketMenuProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)
    const handleClose = () => setAnchorEl(null)
    const handleViewOnGitlab = () => window.open(ticket.web_url)

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title='Ticket options'>
                    <IconButton
                        onClick={handleClick}
                        size='small'
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'ticket-menu' : undefined}
                        aria-haspopup='true'
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id='ticket-menu'
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={handleViewOnGitlab}>
                    <ListItemIcon>
                        <LaunchIcon fontSize='small' color='info' />
                    </ListItemIcon>
                    View on Gitlab
                </MenuItem>
                {UserService.hasRole(['dev']) && (
                    <Link
                        to={`/service/${machineName}/service_forms/create?ticketID=${ticket.iid}`}
                        style={{ color: 'inherit', textDecoration: 'inherit' }}
                    >
                        <MenuItem>
                            <ListItemIcon>
                                <ContentPasteIcon fontSize='small' />
                            </ListItemIcon>
                            Make service form
                        </MenuItem>
                    </Link>
                )}
                {UserService.hasRole(['dev']) && (
                    <Link
                        to={`/service/close-ticket/${ticket.iid}`}
                        style={{ color: 'inherit', textDecoration: 'inherit' }}
                    >
                        <MenuItem>
                            <ListItemIcon>
                                <HighlightOffIcon fontSize='small' color='error' />
                            </ListItemIcon>
                            Close ticket
                        </MenuItem>
                    </Link>
                )}
            </Menu>
        </>
    )
}

interface TicketsListProps {
    machineName: string
    tickets: Ticket[]
}

export const TicketsList = ({ machineName, tickets }: TicketsListProps) => (
    <List>
        {tickets.map((ticket: Ticket) => (
            <ListItem
                key={ticket.created_at}
                sx={{
                    '&:hover': {
                        backgroundColor: grey[300],
                        color: 'black',
                    },
                    ...(ticket.state === 'closed' && {
                        boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.5)',
                    }),
                    borderRadius: '5px',
                    p: 1,
                    mt: 1,
                    mb: 1,
                    backgroundColor: ticket.state === 'opened' ? 'whitesmoke' : grey[300],
                }}
            >
                <Stack sx={{ width: 'inherit' }} spacing={1}>
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                        <Stack direction={'row'} spacing={1} alignItems={'center'}>
                            <PersonIcon />
                            <Typography variant='body2'>{ticket?.assignee?.name}</Typography>
                        </Stack>
                        <Typography variant='body2'>{`🕒 ${getTimeSinceNow(ticket.created_at)}`}</Typography>
                        <TicketMenu machineName={machineName} ticket={ticket} />
                    </Stack>
                    <Typography variant='body2'>{`#${ticket.iid} - ${ticket.title}`}</Typography>
                    <Box sx={{ overflowX: 'auto', width: '100%' }}>
                        <Stack direction={'row'} flexWrap='wrap'>
                            🏷️
                            {ticket.labels.map((label) => {
                                if (label === machineName) return null
                                return (
                                    <Chip
                                        key={label}
                                        label={label}
                                        sx={{ m: 0.2 }}
                                        size='small'
                                        color={
                                            label === 'field service'
                                                ? 'warning'
                                                : label === 'Blocking'
                                                  ? 'error'
                                                  : 'primary'
                                        }
                                    />
                                )
                            })}
                        </Stack>
                    </Box>
                </Stack>
            </ListItem>
        ))}
    </List>
)
