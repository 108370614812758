import * as React from 'react'
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material'

export { ControlledRadioButtonsGroup }
interface ControlledRadioButtonsGroupProps {
    label: string
    options: Record<string, string>
    handleRadioChange: (value: string) => void
}

function ControlledRadioButtonsGroup({ label, options, handleRadioChange }: ControlledRadioButtonsGroupProps) {
    const [value, setValue] = React.useState('')
    const handleChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value)
        handleRadioChange((event.target as HTMLInputElement).value)
    }
    const radioOptions = Object.keys(options).map(function (opt) {
        return <FormControlLabel key={opt} value={opt} control={<Radio />} label={options[opt]} />
    })

    return (
        <FormControl>
            <FormLabel>{label}</FormLabel>
            <RadioGroup
                aria-labelledby='controlled-radio-buttons-group'
                name='controlled-radio-buttons-group'
                value={value}
                onChange={handleChangeEvent}
            >
                {radioOptions}
            </RadioGroup>
        </FormControl>
    )
}
