import * as React from 'react'
import { useTheme } from '@mui/material/styles'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    IconButton,
} from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { TransitionProps } from '@mui/material/transitions'

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction='up' ref={ref} {...props} />
})

interface AlertDialogSlideProps {
    title: string
    description: string
}
export default function AlertDialogSlide({ title, description }: AlertDialogSlideProps) {
    const theme = useTheme()
    const [open, setOpen] = React.useState(false)
    const handleClickOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }

    return (
        <>
            <IconButton onClick={handleClickOpen}>
                <ErrorOutlineIcon id='blob' sx={{ color: theme.palette.error.main }} />
            </IconButton>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby='alert-dialog-slide-description'
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-slide-description'>{description}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color={'secondary'} onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
