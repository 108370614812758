import React from 'react'
import { io, Socket } from 'socket.io-client'
import UserService from '../services/UserService'
import { useRecoilValue } from 'recoil'
import { MSUUserView } from './MSUUserView'
import { ModalBox } from '../_components'
import { IconButton, Stack } from '@mui/material'
import SystemUpdateAltRoundedIcon from '@mui/icons-material/SystemUpdateAltRounded'
import { machineMSUAtom, machineNameAtom, machineTaskStatus } from '../_state'

interface MSUSocketWrapperProps {
    handleClose: () => void
}
const MSUSocketWrapper = ({ handleClose }: MSUSocketWrapperProps) => {
    const machineName = useRecoilValue(machineNameAtom)
    const [machineMSUSocket, setMachineMSUSocket] = React.useState<Socket | null>(
        io(`${process.env.REACT_APP_MSU_URL}/${machineName}`, {
            auth: {
                token: UserService.getToken(),
            },
        }),
    )

    React.useEffect(() => {
        return () => {
            if (machineMSUSocket) {
                machineMSUSocket.off()
                machineMSUSocket.close()
                setMachineMSUSocket(null)
            }
        }
    })

    return (
        <ModalBox title={'Update Machine'} open={true} handleDialogClose={() => handleClose()}>
            <Stack spacing={1}>
                {machineMSUSocket && <MSUUserView socket={machineMSUSocket} handleClose={handleClose} />}
            </Stack>
        </ModalBox>
    )
}

export const MSUIcon = () => {
    const machineMSU = useRecoilValue(machineMSUAtom)
    const [updateModalOpen, setUpdateModalOpen] = React.useState<boolean>(false)
    const machineTaskState = useRecoilValue(machineTaskStatus)
    const handleClose = () => {
        setUpdateModalOpen(false)
    }

    React.useEffect(() => {
        if (machineMSU?.progress != null) setUpdateModalOpen(true)
    }, [machineMSU])

    return machineMSU != null ? (
        <>
            <IconButton
                sx={{
                    ...(machineMSU != null &&
                        machineTaskState !== 'stop' && {
                            borderRadius: '50%',
                            transform: 'scale(0.6)',
                            animation: 'pulse-green 1s infinite',
                        }),
                }}
                color='secondary'
                onClick={() => setUpdateModalOpen(true)}
            >
                <SystemUpdateAltRoundedIcon sx={{ fontSize: 28 }} />
            </IconButton>
            {updateModalOpen && <MSUSocketWrapper handleClose={handleClose} />}
        </>
    ) : null
}
