import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

export { SelectBox }

interface SelectBoxProps {
    inputLabel: string
    value: string
    options: string[]
    width?: string
    disabled: boolean
    // eslint-disable-next-line
    handleChange: (v) => void
    handleOnOpen?: any
}

function SelectBox({
    inputLabel,
    value,
    options,
    disabled,
    handleChange,
    handleOnOpen = null,
    width = '',
}: SelectBoxProps) {
    return (
        <FormControl variant='filled' sx={{ minWidth: 120, width: width }} disabled={disabled}>
            <InputLabel color={'secondary'} id='select-box'>
                {inputLabel}
            </InputLabel>
            <Select labelId='select-box' defaultValue='' value={value} onChange={handleChange} onOpen={handleOnOpen}>
                {options?.map((v, index) => (
                    <MenuItem key={index} value={v}>
                        {v}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
