import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

interface ScrollDialogProps {
    title: string
    children: JSX.Element
    handleClose: () => void
}
export default function ScrollDialog({ title, children, handleClose }: ScrollDialogProps) {
    const descriptionElementRef = React.useRef<HTMLElement>(null)
    React.useEffect(() => {
        const { current: descriptionElement } = descriptionElementRef
        if (descriptionElement !== null) {
            descriptionElement.focus()
        }
    }, [])

    return (
        <div>
            <Dialog
                open={true}
                onClose={handleClose}
                scroll={'paper'}
                aria-labelledby='scroll-dialog-title'
                aria-describedby='scroll-dialog-description'
            >
                <DialogTitle id='scroll-dialog-title'>{title}</DialogTitle>
                <DialogContent dividers={true}>
                    <DialogContentText id='scroll-dialog-description' ref={descriptionElementRef} tabIndex={-1}>
                        {children}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>OK</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
