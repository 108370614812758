import React from 'react'
import { useSetRecoilState, useRecoilState } from 'recoil'
import { h2lFieldJsonAtom, taskQuery, taskSettingsState } from '../_state'
import { CultivarsDrawer } from './'
import { useOutletContext } from 'react-router-dom'
import { Box, Fab, Stack } from '@mui/material'
import SwapVertIcon from '@mui/icons-material/SwapVert'
//import UTurnRightIcon from '@mui/icons-material/UTurnRight'
//import TurnRightIcon from '@mui/icons-material/TurnRight'
import UserService from '../services/UserService'
import RepeatOneIcon from '@mui/icons-material/RepeatOne'
import RepeatOneOnIcon from '@mui/icons-material/RepeatOneOn'
import ListIcon from '@mui/icons-material/List'
import { H2LFieldJson } from '../types'
import { useGroupIDsSelector } from '../_helpers'

export { TulipBedsView }

//{UserService.hasRole(['admin']) && (
//<Fab
//id='turn'
//onClick={() => {
//setTaskSettings((prev) => ({
//...prev,
//turn: prev.turn === 'omega' ? 'three-point' : 'omega',
//}))
//}}
//>
//{taskSettings.turn === 'omega' ? (
//<UTurnRightIcon fontSize='large' />
//) : (
//<TurnRightIcon fontSize='large' />
//)}
//</Fab>
//)}
interface OutletContext {
    h2lFieldJson: H2LFieldJson
    cropID: number
}
function TulipBedsView() {
    // console.log('[TulipBedsView] Render')
    const { h2lFieldJson } = useOutletContext<OutletContext>()
    const setH2lFieldJson = useSetRecoilState(h2lFieldJsonAtom)
    const setTask = useSetRecoilState(taskQuery)
    const [cultivarDrawerOpen, setCultivarDrawerOpen] = React.useState(true)
    const handleCultivarDrawerOpen = (val: boolean) => {
        setCultivarDrawerOpen(val)
    }
    const groupIDsSelector = useGroupIDsSelector()
    const [taskSettings, setTaskSettings] = useRecoilState(taskSettingsState)

    // Set name of tab
    React.useEffect(() => {
        document.title = `${h2lFieldJson.name} - H2L Selector`
        setH2lFieldJson(h2lFieldJson)
        return () => {
            document.title = 'H2L Selector'
            setH2lFieldJson(null)
            setTask({ data: null, isLoading: false })
            groupIDsSelector.resetSelectedGroupIDs()
        }
    }, [h2lFieldJson])

    return (
        <>
            <Box sx={{ position: 'relative' }}>{cultivarDrawerOpen && <CultivarsDrawer />}</Box>
            <Stack
                sx={{
                    '& > :not(style)': { m: 1 },
                    position: 'absolute',
                    bottom: '20px',
                    right: '1%',
                }}
            >
                <Fab
                    id='repeat'
                    onClick={() => {
                        setTaskSettings((prev) => ({
                            ...prev,
                            repeat: prev.repeat === 0 ? 1 : 0,
                        }))
                    }}
                >
                    {taskSettings.repeat === 0 ? (
                        <RepeatOneIcon fontSize='large' />
                    ) : (
                        <RepeatOneOnIcon fontSize='large' />
                    )}
                </Fab>
                <Fab
                    id='change-direction'
                    onClick={() => {
                        setTaskSettings((prev) => ({
                            ...prev,
                            direction: prev.direction === 'down' ? 'up' : 'down',
                        }))
                    }}
                >
                    <SwapVertIcon fontSize='large' />
                </Fab>
                <Fab
                    id='tulip-drawer-btn'
                    onClick={() => {
                        cultivarDrawerOpen === true ? handleCultivarDrawerOpen(false) : handleCultivarDrawerOpen(true)
                    }}
                >
                    <ListIcon fontSize='large' />
                </Fab>
            </Stack>
        </>
    )
}
