import { atom, selector } from 'recoil'

const fleetStatusAtom = atom({
    key: 'fleet_status',
    default: null,
})
const fleetStatusAtomSelector = selector({
    key: 'fleet_status_selector',
    get: ({ get }) => {
        const fleetStatus = get(fleetStatusAtom)
        return fleetStatus
    },
})

export { fleetStatusAtom, fleetStatusAtomSelector }
