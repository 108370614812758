import { useSetRecoilState, useResetRecoilState } from 'recoil'
import { H2LFieldJson } from '../types'
import { useFetchWrapper } from '../_helpers'
import { bedCollectionsAtom, h2lFieldJsonAtom } from '../_state'

export { useCropActions }

function useCropActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`
    const fetchWrapper = useFetchWrapper()
    const setBedCollections = useSetRecoilState(bedCollectionsAtom)

    return {
        //getById,
        getCropByIdWithBedCollections,
        getCropByIdWithTulibBeds,
        detectTreatables,
        updateCropTulipBeds,
        resetBedCollections: useResetRecoilState(bedCollectionsAtom),
        resetTulipBeds: useResetRecoilState(h2lFieldJsonAtom),
    }

    //function getById(fieldID: number, cropID: number) {
    //return fetchWrapper
    //.get(`${baseUrl}/fields/${fieldID}/crops/${cropID}`)
    //.then(setCrop)
    //}

    function getCropByIdWithBedCollections(cropID: number) {
        return fetchWrapper.get(`${baseUrl}/crops/${cropID}/raw_crop`).then(setBedCollections)
    }

    function getCropByIdWithTulibBeds(cropID: number) {
        return fetchWrapper.get(`${baseUrl}/crops/${cropID}/beds`)
    }

    function detectTreatables(cropID: number) {
        return fetchWrapper.get(`${baseUrl}/crops/${cropID}/detect_treatables`)
    }

    function updateCropTulipBeds(cropID: number, field: H2LFieldJson) {
        return fetchWrapper.post(`${baseUrl}/crops/${cropID}/tulip_beds`, field)
    }
}
