import React from 'react'
import { useAlertActions } from '../_actions'

//export default function BoundaryDraw({ type }) {
interface BoundaryEditProps {
    boundary: google.maps.Data
}

export function BoundaryEdit(props: BoundaryEditProps): null {
    const alert = useAlertActions()

    React.useEffect(() => {
        alert.info('You can change the boundary by dragging the white circles')
        props.boundary.forEach((f) => {
            props.boundary.overrideStyle(f, {
                editable: true,
            })
        })
        return () => {
            props.boundary.forEach((f) => {
                props.boundary.overrideStyle(f, {
                    editable: false,
                })
            })
        }
    }, [])

    return null
}
