import React, { useState, useEffect } from 'react'
import {
    Box,
    Grid,
    Typography,
    IconButton,
    Divider,
    Stack,
    Dialog,
    DialogContent,
    AppBar,
    Toolbar,
    TextField,
    CircularProgress,
} from '@mui/material'
import { useMachineActions, useImageActions } from '../_actions'
import NoPhotographyIcon from '@mui/icons-material/NoPhotography'
import CloseIcon from '@mui/icons-material/Close'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Machine } from '../types'

interface CameraImageWrapperProps {
    name: number
    image: string | null
    loading: boolean
}

const CameraImageWrapper: React.FC<CameraImageWrapperProps> = ({ name, image, loading }) => {
    return (
        <>
            <Typography gutterBottom>Camera {name}</Typography>
            <Box
                sx={{
                    borderStyle: 'solid',
                    borderWidth: 'thin',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {loading ? (
                    <CircularProgress sx={{ p: 10 }} color='success' />
                ) : (
                    <>
                        {image ? (
                            <img style={{ width: '100%', height: 'auto' }} src={image} alt={`Camera ${name}`} />
                        ) : (
                            <NoPhotographyIcon fontSize='large' sx={{ p: 10 }} />
                        )}
                    </>
                )}
            </Box>
        </>
    )
}

interface MachineCamerasProps {
    machineName: string
    model: string
}

interface ImageData {
    [index: number]: {
        image: string | null
        name: number
    }
}

const MachineCameras: React.FC<MachineCamerasProps> = ({ machineName, model }) => {
    const imageActions = useImageActions()
    const [images, setImages] = useState<ImageData>({})
    const [loading, setLoading] = useState<{ [key: number]: boolean }>({})

    const fetchImagesForModel = async (machineName: string, model: string) => {
        const cameraIndices = model === 'S180' ? [1, 2, 3, 7] : [1, 2, 3, 4, 7, 8]

        const promises = cameraIndices.map(async (index) => {
            setLoading((prevLoading) => ({ ...prevLoading, [index]: true }))
            try {
                const res = await imageActions.getLatestMachineCameraImage(machineName, index)
                setLoading((prevLoading) => ({ ...prevLoading, [index]: false }))
                const image = res.type === 'image/jpeg' ? URL.createObjectURL(res) : null
                setImages((prevImages) => ({ ...prevImages, [index]: { image, name: index } }))
            } catch (error) {
                console.error('Error fetching image:', error)
                setImages((prevImages) => ({ ...prevImages, [index]: { image: null, name: index } }))
            }
        })

        await Promise.all(promises)
    }

    const checkImagesNull = (obj: ImageData) => Object.values(obj).every((item) => item.image === null)

    useEffect(() => {
        fetchImagesForModel(machineName, model)
    }, [])

    return (
        <>
            {checkImagesNull(images) ? (
                <Typography variant='h5'>{machineName} - No images found</Typography>
            ) : (
                <>
                    <Stack direction={'row'} sx={{ p: 0.5 }} justifyContent={'space-between'}>
                        <Typography variant='h3'>{machineName}</Typography>
                        <IconButton onClick={() => fetchImagesForModel(machineName, model)}>
                            <RefreshIcon color={'success'} fontSize='large' />
                        </IconButton>
                    </Stack>

                    <Grid container columns={{ xs: 1, md: 8 }} rowSpacing={1} columnSpacing={{ xs: 1, md: 1 }}>
                        {Object.keys(images).map((index) => (
                            <Grid item xs={1} md={2} key={index}>
                                <CameraImageWrapper
                                    key={index}
                                    name={images[parseInt(index)].name}
                                    image={images[parseInt(index)].image}
                                    loading={loading[index]}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </>
            )}
            <Divider sx={{ mt: 1, mb: 1 }} />
        </>
    )
}

interface AllMachineCamerasProps {
    handleClose: () => void
}

const AllMachineCameras: React.FC<AllMachineCamerasProps> = ({ handleClose }) => {
    const machineActions = useMachineActions()
    const [machines, setMachines] = useState<Machine[]>([])
    const [searchQuery, setSearchQuery] = useState<string>('')

    const fetchMachines = async () => {
        try {
            await machineActions.getAll().then(setMachines)
            // TESTING
            // const machinesData = await machineActions.getAll()
            // setMachines(
            //     machinesData.filter((machine: any) => ['TestStand', 'Ella', '6529'].includes(machine.machine_name)),
            // )
        } catch (error) {
            console.error('Error fetching machines:', error)
        }
    }

    const handleRefreshAll = async () => {
        try {
            setMachines([])
            await machineActions.getAll().then(setMachines)
            // TESTING
            // const machinesData = await machineActions.getAll()
            // setMachines(
            //     machinesData.filter((machine: any) => ['TestStand', 'Ella', '6529'].includes(machine.machine_name)),
            // )
        } catch (error) {
            console.error('Error refreshing machines:', error)
        }
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value)
    }

    const filteredMachines = machines.filter((machine: Machine) =>
        machine.machine_name.toLowerCase().includes(searchQuery.toLowerCase()),
    )

    useEffect(() => {
        fetchMachines()
    }, [])

    return (
        <Dialog fullScreen open={true} onClose={handleClose}>
            <Stack direction='row' justifyContent='space-between'>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Typography variant='h6' component='div' sx={{ flex: 1 }} noWrap>
                            All Machine Cameras
                        </Typography>
                        <IconButton color='inherit' onClick={handleRefreshAll} aria-label='refresh' size='large'>
                            <RefreshIcon />
                        </IconButton>
                        <IconButton color='inherit' onClick={handleClose} aria-label='close' size='large'>
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </Stack>
            <DialogContent sx={{ p: 2 }}>
                <TextField
                    color='success'
                    label='Search Machine'
                    variant='outlined'
                    fullWidth
                    value={searchQuery}
                    onChange={handleInputChange}
                    sx={{ mb: 2 }}
                />
                {filteredMachines.length === 0 && <Typography>No machines found</Typography>}
                {filteredMachines.map((machine: any) => (
                    <MachineCameras key={machine.id} machineName={machine.machine_name} model={machine.model} />
                ))}
            </DialogContent>
        </Dialog>
    )
}

export { AllMachineCameras }
