import Keycloak from 'keycloak-js'

const _kc = new Keycloak({
    url: `${process.env.REACT_APP_KEYCLOAK_SCHEME}://${process.env.REACT_APP_KEYCLOAK_HOST}:${process.env.REACT_APP_KEYCLOAK_PORT}/`,
    realm: 'h2lselector',
    clientId: 'frontend',
})

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback: () => void): void => {
    _kc.init({
        onLoad: 'login-required',
    })
        .then((authenticated) => {
            if (!authenticated) {
                console.log('user is not authenticated..!')
            }
            onAuthenticatedCallback()
        })
        .catch(console.error)
}

const doLogin = _kc.login

const doLogout = _kc.logout

const getToken = (): string => {
    if (_kc.token === undefined) {
        throw new Error('Token is undefined');
    }
    return _kc.token;
};

const isLoggedIn = (): boolean => !!_kc.token

const updateToken = (): Promise<boolean | void> => _kc.updateToken(5).catch(doLogin)

const getUsername = (): string => _kc.tokenParsed?.preferred_username

const getFullname = (): string => _kc.tokenParsed?.name

const isTokenExpired = (): boolean => _kc.isTokenExpired()

let impersonateUser = false
const hasRole = (roles: string[]): boolean => {
    if (impersonateUser) {
        return false
    } else {
        return roles.some((role) => _kc.hasRealmRole(role))
    }
}

const toggleImpersonateUser = () => impersonateUser = !impersonateUser

const role = (): string => {
    if (_kc.hasRealmRole('admin')) {
        return 'admin'
    } else if (_kc.hasRealmRole('manufacturer')) {
        return 'manufacturer'
    } else if (_kc.hasRealmRole('manager')) {
        return 'manager'
    } else {
        return 'user'
    }
}



const UserService = {
    initKeycloak,
    doLogin,
    doLogout,
    isLoggedIn,
    getToken,
    updateToken,
    getUsername,
    isTokenExpired,
    hasRole,
    role,
    getFullname,
    toggleImpersonateUser,
}

export default UserService
