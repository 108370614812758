// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react'
import { useRecoilValue } from 'recoil'
import { Map } from '../_components'
import { bedCollectionsAtom, mapAtom } from '../_state'
import { useCropActions } from '../_actions'
import ReportIcon from '@mui/icons-material/Report'
import { removeFeaturesFromMap, addFeaturesToMap, zoomMap } from '../_helpers'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { H2LFieldJson } from '../types'
import { useOutletContext } from 'react-router-dom'

export { BedCollectionsView }

interface OutletContext {
    h2lFieldJson: H2LFieldJson
    cropID: number
}

function BedCollectionsView() {
    // console.log('[BedCollectionsView] render')
    const { h2lFieldJson } = useOutletContext<OutletContext>()
    const map = useRecoilValue(mapAtom)
    const cropActions = useCropActions()
    const bedCollections = useRecoilValue(bedCollectionsAtom)
    const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
        ({ theme }) => ({
            [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: theme.palette.common.white,
                color: 'rgba(0, 0, 0, 0.87)',
                boxShadow: theme.shadows[1],
                fontSize: 22,
            },
        }),
    )

    // Set name of tab
    React.useEffect(() => {
        document.title = `${h2lFieldJson.name} - H2L Selector`
        cropActions.getCropByIdWithBedCollections(cropID)
        return () => {
            document.title = 'H2L Selector'
            cropActions.resetBedCollections()
        }
    }, [])

    React.useEffect(() => {
        if (bedCollections) {
            h2lFieldJson.beds = bedCollections.feature_collection.features
            addFeaturesToMap(map, h2lFieldJson, false)
            zoomMap(map)
        }
        return () => {
            removeFeaturesFromMap(map)
        }
    }, [bedCollections, map])

    const render = () => {
        return (
            <>
                <Map />
                <Box sx={{ m: 4 }}>
                    <LightTooltip title='Data with no preprocessing' placement='right-start'>
                        <ReportIcon sx={{ color: 'red', position: 'absolute' }} fontSize='large' />
                    </LightTooltip>
                </Box>
            </>
        )
    }
    return render()
}
