// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react'
import UserService from '../services/UserService'
import { useSetRecoilState, useResetRecoilState, useRecoilValue } from 'recoil'
import {
    machineModelAtom,
    subframePositionAtom,
    subframePositionStartAtom,
    followPathAtom,
    machineControlAtom,
    fluidInstrumentationAtom,
    treatmentAtom,
    xavier1Atom,
    xavier2Atom,
    xavier3Atom,
    xavier4Atom,
    xavier7Atom,
    xavier8Atom,
    clusteringAtom,
    motorControlAtom,
    notificationsAtom,
    machineOnlineStatusAtom,
    machineSocketAtom,
    machineMSUAtom,
    ussAtom,
    logbookAtom,
    tulipMemoryBatchIDAtom,
    tulipMemoryStateAtom,
    estimatedTimeLeftAtom,
    clusteringThresholdAtom,
    miscellaneousAtom,
    autoSafetyAtom,
    robotControlAtom,
    robotZheightAtom,
    clusteringTreatmentDensityTargetAtom,
    clusteringTreatmentModeAtom,
} from '../_state'
import { useAlertActions } from '../_actions'
import { arraysHaveSameContent } from '../_helpers'
import { MachineStatus, Notification } from '../types'
import { useNavigate } from 'react-router-dom'

let lastMsgTs = 0
// If auto-reload and go from machine to machine list and back to machine, this code break
// and goes in an infinite loop
function useLastMsgCountdown() {
    const setMachineOnline = useSetRecoilState(machineOnlineStatusAtom)
    setInterval(() => {
        if (Date.now() - lastMsgTs > 8000 && navigator.onLine) setMachineOnline(false)
    }, 1000)
}

function compareObjs(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2)
}

export default function MachineStatusSub() {
    // console.log('[Render useMachineStatusSub]', socket)
    const navigate = useNavigate()
    const socket = useRecoilValue(machineSocketAtom)
    useLastMsgCountdown()
    let prevMachineModel: string = null
    let prevAutoSafety = { auto_safety: null }
    let prevRobotControl = { robot_control: null }
    let prevRobotZheight = { robot_z_height: null }
    let prevSubframePosition = { subframe_position: null }
    let prevFollowPath = { follow_path: null }
    let prevMachineControl = { machine_control: null }
    let prevFluidInstrumentation = { fluid_instrumentation: null }
    let prevTreatment = { treatment: null }
    let prevMotorControl = { motor_control: null }
    let notifications: Notification[] | [] = []
    let logbook: Logbook[] | [] = []
    let prevMSU = null
    let prevUSS = null
    let prevTulipMemoryState = null

    // Socket data
    const setMachineModel = useSetRecoilState(machineModelAtom)
    const setAutoSafety = useSetRecoilState(autoSafetyAtom)
    const setRobotControl = useSetRecoilState(robotControlAtom)
    const setRobotZheight = useSetRecoilState(robotZheightAtom)
    const setMachineMSU = useSetRecoilState(machineMSUAtom)
    const setSubframePositionStart = useSetRecoilState(subframePositionStartAtom)
    const setSubframePosition = useSetRecoilState(subframePositionAtom)
    const setFollowPath = useSetRecoilState(followPathAtom)
    const setMachineControl = useSetRecoilState(machineControlAtom)
    const setFluidInstrumentation = useSetRecoilState(fluidInstrumentationAtom)
    const setTreatment = useSetRecoilState(treatmentAtom)
    const setXavier1 = useSetRecoilState(xavier1Atom)
    const setXavier2 = useSetRecoilState(xavier2Atom)
    const setXavier3 = useSetRecoilState(xavier3Atom)
    const setXavier4 = useSetRecoilState(xavier4Atom)
    const setXavier7 = useSetRecoilState(xavier7Atom)
    const setXavier8 = useSetRecoilState(xavier8Atom)
    const setClusteringStatus = useSetRecoilState(clusteringAtom)
    const setMotorControl = useSetRecoilState(motorControlAtom)
    const setTulipMemoryBatchID = useSetRecoilState(tulipMemoryBatchIDAtom)
    const setClusteringThreshold = useSetRecoilState(clusteringThresholdAtom)
    const setClusteringTreatmentDensityTarget = useSetRecoilState(clusteringTreatmentDensityTargetAtom)
    const setClusteringTreatmentMode = useSetRecoilState(clusteringTreatmentModeAtom)
    const setUSS = useSetRecoilState(ussAtom)
    const setTulipMemoryStatus = useSetRecoilState(tulipMemoryStateAtom)
    const setEstimatedTimeLeft = useSetRecoilState(estimatedTimeLeftAtom)
    const setMiscellaneous = useSetRecoilState(miscellaneousAtom)
    const resetMachineModel = useResetRecoilState(machineModelAtom)
    const resetMachineMsu = useResetRecoilState(machineMSUAtom)
    const resetAutoSafety = useResetRecoilState(autoSafetyAtom)
    const resetRobotControl = useResetRecoilState(robotControlAtom)
    const resetRobotZheight = useResetRecoilState(robotZheightAtom)
    const resetSubframePosition = useResetRecoilState(subframePositionAtom)
    const resetFollowPath = useResetRecoilState(followPathAtom)
    const resetMachineControl = useResetRecoilState(machineControlAtom)
    const resetFluidInstrumentation = useResetRecoilState(fluidInstrumentationAtom)
    const resetTreatment = useResetRecoilState(treatmentAtom)
    const resetXavier1 = useResetRecoilState(xavier1Atom)
    const resetXavier2 = useResetRecoilState(xavier2Atom)
    const resetXavier3 = useResetRecoilState(xavier3Atom)
    const resetXavier4 = useResetRecoilState(xavier4Atom)
    const resetXavier7 = useResetRecoilState(xavier7Atom)
    const resetXavier8 = useResetRecoilState(xavier8Atom)
    const resetMotorControl = useResetRecoilState(motorControlAtom)
    const resetClustering = useResetRecoilState(clusteringAtom)
    const resetTulipMemoryBatchID = useResetRecoilState(tulipMemoryBatchIDAtom)
    const resetClusteringThreshold = useResetRecoilState(clusteringThresholdAtom)
    const resetUSS = useResetRecoilState(ussAtom)
    const resetTulipMemoryState = useResetRecoilState(tulipMemoryStateAtom)
    const resetEstimatedTimeLeft = useResetRecoilState(estimatedTimeLeftAtom)

    const machineMSU = useRecoilValue(machineMSUAtom)
    const tulipMemory = useRecoilValue(tulipMemoryStateAtom)

    // Notifications
    const setNotifications = useSetRecoilState(notificationsAtom)

    // Logbook
    const setLogbook = useSetRecoilState(logbookAtom)

    const alertActions = useAlertActions()

    const setMachineOnline = useSetRecoilState(machineOnlineStatusAtom)

    React.useEffect(() => {
        socket.on('NOTIFICATION', (notifications: Notifications[]) => {
            notifications.forEach((n) => {
                handleLiveNotification(n)
            })
        })

        socket.on('NOTIFICATIONS_SAVED', (notifications: Notifications[]) => {
            notifications.forEach((n) => {
                handleDbNotification(n)
            })
        })

        socket.on('LOGBOOK_SAVED', (logbook: Logbook[]) => {
            logbook.forEach((l) => {
                handleDbLogbook(l)
            })
        })
        socket.on('UNAUTHORIZED', () => {
            UserService.doLogout()
        })

        socket.once('MS', (msg) => {
            setSubframePositionStart(msg.status.subframe_position)
        })

        socket.on('TRANSFERED', () => {
            navigate('/machines')
        })

        socket.on('MS', (msg: MachineStatus) => {
            lastMsgTs = Date.now()
            setMachineOnline(true)
            handleMachineModel(msg)
            handleMSU(msg)
            handleSubframePositionMessage(msg.status)
            handleFollowPathMessage(msg.status)
            handleMachineControlMessage(msg.status)
            handleRobotControlMessage(msg.status)
            handleRobotZheightMessage(msg.status)
            handleFluidInstrumentationMessage(msg.status)
            handleTreatmentMessage(msg.status)
            handleXavierMessages(msg.status)
            handleMotorControlMessage(msg.status)
            handleAutoSafetyMessage(msg.status)
            handleUSSMessage(msg.status)
            handleClusteringMessage(msg.status)
            handleTulipMemoryStateMessage(msg)
            handleEstimatedTimeLeft(msg.status)
            handleMiscellaneous(msg.status)
        })

        return () => {
            // console.log('[MachineStatusSub] return')
            setNotifications([])
            notifications = []
            setLogbook([])
            logbook = []
            resetMachineModel()
            resetMachineMsu()
            resetSubframePosition()
            resetFollowPath()
            resetMachineControl()
            resetFluidInstrumentation()
            resetAutoSafety()
            resetRobotControl()
            resetRobotZheight()
            resetTreatment()
            resetXavier1()
            resetXavier2()
            resetXavier3()
            resetXavier4()
            resetXavier7()
            resetXavier8()
            resetMotorControl()
            resetUSS()
            resetTulipMemoryState()
            resetEstimatedTimeLeft()
            resetTulipMemoryBatchID()
            resetClusteringThreshold()
            socket.close()
        }
    }, [socket])

    const handleMiscellaneous = (msg: MachineStatus.status) => {
        enum States {
            'error',
            'loading',
            'idle',
            'running',
        }
        let min = NaN
        if (
            msg.config_server?.status &&
            msg.ethernet_relay?.status &&
            msg.uss?.status &&
            msg.image_server?.status &&
            msg.influx_mirror_publisher?.status &&
            msg.machine_monitor?.status
        ) {
            min = Math.min(
                ...[
                    msg.config_server.status,
                    msg.ethernet_relay.status,
                    msg.uss.status,
                    msg.image_server.status,
                    msg.influx_mirror_publisher.status,
                    msg.machine_monitor.status,
                ],
            )
        }

        setMiscellaneous(isNaN(min) ? States[0] : States[min])
    }

    const handleMachineModel = (msg: MachineStatus) => {
        if (msg.model) {
            if (prevMachineModel !== msg.machine_model) {
                setMachineModel(msg.model)
            }
            prevMachineModel = msg.machine_model
        }
    }

    const handleTulipMemoryStateMessage = (msg) => {
        if (msg.tm) {
            if (!compareObjs(prevTulipMemoryState, msg.tm)) {
                setTulipMemoryStatus(msg.tm)
            }
            prevTulipMemoryState = msg.tm
        } else if (!compareObjs(prevTulipMemoryState, tulipMemory)) {
            resetTulipMemoryState()
            prevTulipMemoryState = null
        }
    }

    const handleMSU = (msg: MachineStatus) => {
        if (msg.msu) {
            if (!compareObjs(prevMSU, msg.msu)) {
                setMachineMSU(msg.msu)
            }
            prevMSU = msg.msu
        } else {
            // Got null value to socket, if prev has value and new does not set to null
            if (!compareObjs(prevMSU, machineMSU)) {
                resetMachineMsu()
                prevMSU = null
            }
        }
    }

    /**
     * msg.uss
     * undef: Sensor status ok
     * null: invalid value
     * [ 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1 ]: all sensors firing
     */
    const handleUSSMessage = (msg: MachineStatus) => {
        if (msg.uss) {
            if (JSON.stringify(prevUSS) !== JSON.stringify(msg.uss)) {
                setUSS(msg.uss)
            }
            prevUSS = msg.uss
        } else {
            resetUSS()
            prevUSS = null
        }
    }

    const handleSubframePositionMessage = (msg: MachineStatus.status) => {
        if (msg.subframe_position) {
            if (prevSubframePosition.ts !== msg.subframe_position.ts) {
                setSubframePosition(msg.subframe_position)
            }
            prevSubframePosition = msg.subframe_position
        }
    }

    const handleFollowPathMessage = (msg: MachineStatus.status) => {
        if (msg.follow_path) {
            if (
                prevFollowPath.task_id !== msg.follow_path.task_id ||
                prevFollowPath.cursor !== msg.follow_path.cursor ||
                prevFollowPath.status !== msg.follow_path.status
            ) {
                setFollowPath(msg.follow_path)
            }
            prevFollowPath = msg.follow_path
        } else if (prevFollowPath !== null && msg.follow_path === null) {
            resetFollowPath()
            prevFollowPath = { follow_path: null }
        }
    }

    const handleMachineControlMessage = (msg: MachineStatus.status) => {
        if (msg.machine_control) {
            if (
                prevMachineControl.task_id !== msg.machine_control.task_id ||
                prevMachineControl.status !== msg.machine_control.status ||
                prevMachineControl.state !== msg.machine_control.state
            ) {
                setMachineControl(msg.machine_control)
            }
            prevMachineControl = msg.machine_control
        } else if (prevMachineControl.status !== null && msg.machine_control === null) {
            resetMachineControl()
            prevMachineControl = { machine_control: null }
        }
    }

    const handleFluidInstrumentationMessage = (msg: MachineStatus.status) => {
        if (msg.fluid_instrumentation) {
            if (
                prevFluidInstrumentation.state !== msg.fluid_instrumentation.state ||
                prevFluidInstrumentation.status !== msg.fluid_instrumentation.status ||
                prevFluidInstrumentation.valid !== msg.fluid_instrumentation.valid ||
                prevFluidInstrumentation.ts !== msg.fluid_instrumentation.ts ||
                prevFluidInstrumentation.tank_pressure !== msg.fluid_instrumentation.tank_pressure ||
                prevFluidInstrumentation.pressure_setpoint !== msg.fluid_instrumentation.pressure_setpoint ||
                prevFluidInstrumentation.dispense_volume !== msg.fluid_instrumentation.dispense_volume ||
                prevFluidInstrumentation.dispense_flow !== msg.fluid_instrumentation.dispense_flow ||
                prevFluidInstrumentation.detection_density !== msg.fluid_instrumentation.detection_density ||
                prevFluidInstrumentation.tank_level !== msg.fluid_instrumentation.tank_level ||
                arraysHaveSameContent(prevFluidInstrumentation.alarm, msg.fluid_instrumentation.alarm) ||
                prevFluidInstrumentation.settings_id !== msg.fluid_instrumentation.settings_id
            ) {
                setFluidInstrumentation(msg.fluid_instrumentation)
            }
            prevFluidInstrumentation = msg.fluid_instrumentation
        } else if (prevFluidInstrumentation !== null && msg.fluid_instrumentation === null) {
            resetFluidInstrumentation()
            prevFluidInstrumentation = { fluid_instrumentation: null }
        }
    }

    const handleRobotControlMessage = (msg: MachineStatus.status) => {
        if (msg.robot_control) {
            if (prevRobotControl.status !== msg.robot_control.status) {
                setRobotControl(msg.robot_control)
            }
            prevRobotControl = msg.robot_control
        } else if (prevRobotControl !== null && msg.robot_control === null) {
            resetRobotControl()
            prevRobotControl = { robot_control: null }
        }
    }
    const handleRobotZheightMessage = (msg: MachineStatus.status) => {
        if (msg.robot_z_height) {
            if (
                prevRobotZheight.status !== msg.robot_z_height.status ||
                prevRobotZheight.state !== msg.robot_z_height.state
            ) {
                setRobotZheight(msg.robot_z_height)
            }
            prevRobotZheight = msg.robot_z_height
        } else if (prevRobotZheight !== null && msg.robot_z_height === null) {
            resetRobotZheight()
            prevRobotZheight = { robot_z_height: null }
        }
    }
    const handleAutoSafetyMessage = (msg: MachineStatus.status) => {
        if (msg.auto_safety) {
            if (prevAutoSafety.state !== msg.auto_safety.state || prevAutoSafety.status !== msg.auto_safety.status) {
                setAutoSafety(msg.auto_safety)
            }
            prevAutoSafety = msg.auto_safety
        } else if (prevAutoSafety !== null && msg.auto_safety === null) {
            resetAutoSafety()
            prevAutoSafety = { auto_safety: null }
        }
    }

    const handleTreatmentMessage = (msg: MachineStatus.status) => {
        if (msg.treatment) {
            if (
                prevTreatment.status !== msg.treatment.status ||
                prevTreatment.lights !== msg.treatment.lights ||
                prevTreatment.compressor !== msg.treatment.compressor ||
                prevTreatment.dispense_valve !== msg.treatment.dispense_valve ||
                prevTreatment.treatment_counter !== msg.treatment.treatment_counter ||
                prevTreatment.treatments_per_100m !== msg.treatment.treatments_per_100m ||
                prevTreatment.dispense_duration !== msg.treatment.dispense_duration ||
                prevTreatment.speed_control !== msg.treatment.speed_control
            ) {
                setTreatment(msg.treatment)
            }
            prevTreatment = msg.treatment
        } else if (prevTreatment.treatment !== null && msg.treatment === null) {
            resetTreatment()
            prevTreatment = { treatment: null }
        }
    }

    const handleXavierMessages = (msg: MachineStatus.status) => {
        setXavier1(msg?.Xavier1?.status)
        setXavier2(msg?.Xavier2?.status)
        setXavier3(msg?.Xavier3?.status)
        setXavier4(msg?.Xavier4?.status)
        setXavier7(msg?.Xavier7?.status)
        setXavier8(msg?.Xavier8?.status)
    }

    const handleMotorControlMessage = (msg: MachineStatus.status) => {
        if (msg.motor_control) {
            if (prevMotorControl.motor_control !== msg.motor_control) {
                setMotorControl(msg.motor_control)
            }
            prevMotorControl = msg.motor_control
        } else if (prevMotorControl !== null && msg.motor_control === null) {
            resetMotorControl()
            prevMotorControl = { motor_control: null }
        }
    }

    const handleDbNotification = (n: Notification) => {
        let tmp: Notification | [] = []

        // If notification is already on the list dont add it (needed because backend reload sent the same notifications)
        if (!notifications.find((e) => e.id === n.id)) {
            tmp = [...notifications, n]
        }

        if (tmp.length > 0) {
            setNotifications(tmp)
            notifications.push(n)
        }
    }

    const handleLiveNotification = (n: Notification) => {
        setNotifications((prev) => [n, ...prev].sort((item1, item2) => item2.id - item1.id))
        alertActions.info(`${n.message}`)
    }

    const handleDbLogbook = (l: Logbook) => {
        let tmp: Logbook | [] = []

        if (!logbook.find((e) => e.id === l.id)) {
            tmp = [...logbook, l]
        }

        if (tmp.length > 0) {
            setLogbook(tmp)
            logbook.push(l)
        }
    }

    const handleClusteringMessage = (msg: MachineStatus.status) => {
        setTulipMemoryBatchID(msg?.clustering?.tm_batch_id)
        setClusteringThreshold(msg?.clustering?.threshold)
        setClusteringTreatmentDensityTarget(msg?.clustering?.treatment_density_target)
        setClusteringTreatmentMode(msg?.clustering.treatment_mode)
        setClusteringStatus(msg?.clustering?.status)
    }

    const handleEstimatedTimeLeft = (msg: MachineStatus.status) => {
        setEstimatedTimeLeft(msg?.follow_path?.estimated_time_left)
    }

    return null
}
