import { IconButton, Badge } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledBadge = styled(Badge)(({ theme }) => ({
    // top: '30px',
    // background: 'black',
}))

interface CustomIconButtonProps {
    baseIcon: JSX.Element
    stateIcon: any
    onClick: () => void
}
const IconButtonWithStateIcon = ({ baseIcon, stateIcon, onClick }: CustomIconButtonProps) => {
    return (
        <IconButton onClick={onClick}>
            <StyledBadge badgeContent={stateIcon}>{baseIcon}</StyledBadge>
        </IconButton>
    )
}

export { IconButtonWithStateIcon }
