interface GitLabCreateTicketLinkProps {
    machineName?: string
}

const GitLabCreateTicketLink = ({ machineName }: GitLabCreateTicketLinkProps) => {
    const now = Date.now()
    const unixTimestampNow = Math.floor(now)
    const fifteenMinutesAgo = Math.floor(now - 15 * 60 * 1000)

    // https://docs.gitlab.com/ee/user/markdown.html
    // https://docs.gitlab.com/ee/user/project/issues/create_issues.html#using-a-url-with-prefilled-values
    let grafanaCustomerSupportUrl = `https://grafana.h2lselector.com/d/wn0td5xVk/customer-support?orgId=1&from=${fifteenMinutesAgo}&to=${unixTimestampNow}`
    let glCreateIssue

    if (machineName) {
        grafanaCustomerSupportUrl += `&var-machine=${machineName}_mirror`
        glCreateIssue = new URL(
            `https://gitlab.com/hll-robotics/tickets/-/issues/new?issue[title]=${machineName}+&issue[description]=/label+~%22${machineName}%22+%0D%0D[customer-support](${encodeURIComponent(
                grafanaCustomerSupportUrl,
            )})`,
        )
    } else {
        glCreateIssue = new URL('https://gitlab.com/hll-robotics/tickets/-/issues/new')
    }

    const linkStyle = {
        textDecoration: 'none',
        color: 'inherit',
    }
    return (
        <a href={glCreateIssue.toString()} target='_blank' rel='noopener noreferrer' style={linkStyle}>
            New Ticket
        </a>
    )
}

export { GitLabCreateTicketLink }
