import React, { CSSProperties, useState } from 'react'
import { FileInput, Form } from '../_components'
import { useForm } from 'react-hook-form'
import { useFieldActions, useAlertActions, useBoundaryActions } from '../_actions'
import { Box, Stack, Link, Button, Typography, LinearProgress } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useWindowSize } from '../_helpers'

interface UploadProps {
    type: 'field' | 'boundary'
    overwrite: boolean
    fieldID?: number
    onClose: () => void
}
interface IFormInput {
    files: FileList
}

export { Upload }

const docUrls = {
    johndeere: {
        url: 'https://docs.h2lselector.com/nl/planting/john-deere/myjohndeere-export-a-field/',
        filename: '/john-deere-logo.jpg',
    },
    raven: {
        url: 'https://docs.h2lselector.com/nl/planting/raven/office-manager-export/',
        filename: '/raven-logo.jpg',
    },
    trimble: {
        url: 'https://docs.h2lselector.com/nl/planting/trimble/exporting-field-from-Farmer-Pro/',
        filename: '/trimble-logo.jpg',
    },
}

function Upload({ type, overwrite, fieldID, onClose }: UploadProps) {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [fileName, setFileName] = useState<string | null>(null)
    const [dropzoneErrMsg, setDropzoneErrMsg] = useState(null)
    const fieldActions = useFieldActions()
    const boundaryActions = useBoundaryActions()
    const alertActions = useAlertActions()
    const windowSize = useWindowSize()

    const onDropAccepted = (value: FileList) => {
        if (dropzoneErrMsg) setDropzoneErrMsg(null)
        setFileName(value[0].name)
    }

    // eslint-disable-next-line
    const onDropRejected = (err: any) => {
        setDropzoneErrMsg(err[0])
    }

    const { handleSubmit, control } = useForm<IFormInput>()

    const onSubmit = (data: IFormInput) => {
        setLoading(true)
        if (overwrite && fieldID && type === 'field') {
            return fieldActions
                .update(fieldID, data.files[0].name)
                .then((field) => {
                    fieldActions
                        .uploadFileOnCreate(field.id, data.files[0])
                        .then((crop) => {
                            onClose()
                            navigate(`/fields/${field.id}/crops/${crop.id}/edit/`)
                        })
                        .catch((err) => {
                            console.error(err)
                            setLoading(false)
                            navigate('/fields')
                            alertActions.error('Could not parse field')
                        })
                        .finally(() => {
                            setLoading(false)
                        })
                })
                .catch((err) => {
                    console.error(err)
                    setLoading(false)
                })
        } else if (type === 'field') {
            return fieldActions
                .create(data.files[0].name)
                .then((field) => {
                    fieldActions
                        .uploadFileOnCreate(field.id, data.files[0])
                        .then((crop) => {
                            if (crop.boundary) {
                                navigate({
                                    pathname: `/fields/${field.id}/crops/${crop.id}/view/`,
                                })
                            } else {
                                navigate({
                                    pathname: `/fields/${field.id}/crops/${crop.id}/edit/`,
                                    hash: '#addboundary',
                                })
                            }
                        })
                        .catch((err) => {
                            console.error(err)
                            setLoading(false)
                            navigate('/fields')
                            alertActions.error('Could not parse field')
                        })
                        .finally(() => {
                            setLoading(false)
                        })
                })
                .catch((err) => {
                    console.error(err)
                    setLoading(false)
                })
        } else if (type === 'boundary') {
            if (fieldID) {
                return boundaryActions
                    .uploadBoundary(fieldID, data.files[0])
                    .then((res) => {
                        alertActions.success('Boundary uploaded')
                        navigate({
                            pathname: `/fields/${res.field_id}/crops/${res.crop_id}/view/`,
                        })
                        //onClose(boundary) // Hack to substitue previous hack. Correct way would be to return a new crop
                    })
                    .catch((res) => {
                        setDropzoneErrMsg(res.detail)
                        setLoading(false)
                        alertActions.error('Could not upload boundary')
                        navigate('/fields')
                    })
                    .finally(() => setLoading(false))
            } else {
                alertActions.success('Field not found')
                setLoading(false)
            }
        }
    }

    let style: CSSProperties = {}
    if (windowSize.width >= 868 && !style) {
        style = { width: '40%', height: '40%' }
    } else if (!style) {
        style = { width: '100%', height: 'auto' }
    }

    return (
        <Box className='container' sx={{ mt: 2, mb: 2 }}>
            {type === 'field' && <Typography variant='h4'>Upload field</Typography>}
            {type === 'boundary' && <Typography variant='h4'>Upload boundary</Typography>}
            <Box sx={{ mt: 2 }}>
                <Typography variant='body2'>File export instructions for crop registration systems:</Typography>
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={1}
                    justifyContent={'space-around'}
                    sx={{ mt: 2, mb: 2, textAlign: 'center' }}
                >
                    {Object.keys(docUrls).map((key) => (
                        <Box key={key} sx={{ alignSelf: 'center' }}>
                            <Button
                                variant='contained'
                                color='secondary'
                                sx={{
                                    '&:hover': {
                                        background: 'white',
                                    },
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                                component={Link}
                                rel='noopener'
                                target='_blank'
                                href={docUrls[key].url}
                            >
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: 'auto',
                                    }}
                                >
                                    <img style={style} src={docUrls[key].filename} alt={docUrls[key].filename} />
                                </Box>
                            </Button>
                        </Box>
                    ))}
                </Stack>
                <Typography variant='body2'>
                    After exporting the files click on the file input below to import them.
                </Typography>
            </Box>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment  */}
            {/* @ts-ignore */}
            <Form onSubmit={handleSubmit(onSubmit)}>
                <FileInput
                    name='files'
                    control={control}
                    onDropAccepted={onDropAccepted}
                    onDropRejected={onDropRejected}
                    acceptedFiles={['.zip', '.kml', '.csv', '.vit']}
                    textDescription={'Drag `n` drop files here, or click to select a file'}
                />
                {loading && (
                    <Box sx={{ mb: 2, width: '100%' }}>
                        <LinearProgress color='success' />
                    </Box>
                )}
                {dropzoneErrMsg && (
                    <>
                        <Typography variant='body1' color='error'>
                            The file `{dropzoneErrMsg['file']['name']}` that you have selected is of type `
                            {dropzoneErrMsg['file']['type']}`.
                        </Typography>
                        <Typography variant='body1'>We currently support kml and zip files only.</Typography>
                        <Typography variant='body1'>
                            If you have any questions please contact Kostas Konstantinidis at:
                        </Typography>
                        <Typography variant='body1'>
                            <a href='mailto: kostas@h2lrobotics.com'>kostas@h2lrobotics.com</a>
                        </Typography>
                        <Typography variant='body1'>
                            <a href='tel:+31645591569'>+31645591569</a>
                        </Typography>
                    </>
                )}
                <Stack direction={'row'} spacing={1} justifyContent={'right'}>
                    <Box sx={{ pr: 2 }}>
                        <Button
                            variant='outlined'
                            sx={{
                                borderColor: 'black',
                                color: 'black',
                                '&:hover': {
                                    borderColor: 'black',
                                },
                            }}
                            onClick={onClose}
                        >
                            Back
                        </Button>
                    </Box>
                    <Button type='submit' variant='contained' disabled={!fileName} color='success'>
                        Next
                    </Button>
                </Stack>
            </Form>
        </Box>
    )
}
