import { useEffect, useState } from 'react'
import { IconButton, Box, CircularProgress } from '@mui/material'
import { TaskList } from '../../tasks'
import { taskManagerAtom, h2lFieldJsonAtom, subframePositionStartAtom, taskQuery } from '../../_state'
import CloseIcon from '@mui/icons-material/Close'
import { CultivarsListMobile } from './CultivarsListMobile'
import { useFieldActions, useAlertActions } from '../../_actions'
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil'

export const TaskManager = () => {
    //console.log('[TaskManager] Render')
    const [loading, setLoading] = useState(true)
    const [h2lField, setH2lField] = useRecoilState(h2lFieldJsonAtom)
    const setTask = useSetRecoilState(taskQuery)
    const alert = useAlertActions()
    const [taskManager, setTaskManager] = useRecoilState(taskManagerAtom)
    const fieldActions = useFieldActions()
    const gps = useRecoilValue(subframePositionStartAtom)

    useEffect(() => {
        setLoading(true)
        setH2lField(null)
        setTask({ data: null, isLoading: false })
        if (gps && gps.lat && gps.lon && h2lField) {
            fieldActions
                .getNearestField(gps.lon, gps.lat)
                .then((h2lField) => {
                    setH2lField(h2lField)
                })
                .catch(() => {
                    alert.error('Error getting nearest field')
                    setH2lField(null)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
        setLoading(false)
        return () => {
            //setH2lField(null) //This is required, but good for  now
            //setTaskManager('')
        }
    }, [taskManager])

    const closeIcon = (
        <IconButton onClick={() => setTaskManager('')} sx={{ cursor: 'pointer', ml: 1 }} size='large'>
            <CloseIcon />
        </IconButton>
    )

    return (
        <Box sx={{ p: 1 }}>
            {loading ? (
                <CircularProgress size={'100px'} color='secondary' sx={{ mt: 10, mb: 10 }} />
            ) : taskManager === 'create' && h2lField ? (
                <CultivarsListMobile closeIcon={closeIcon} />
            ) : (
                <TaskList closeIcon={closeIcon} />
            )}
        </Box>
    )
}
