import { MSUGitlabTag } from '../types'
import { useFetchWrapper } from '../_helpers'

export { useMsuActions }

function useMsuActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1/msu`
    const fetchWrapper = useFetchWrapper()

    return {
        check_all_target_version,
        get,
        setTargetVersion,
    }

    function check_all_target_version() {
        return fetchWrapper.get(`${baseUrl}/check_all_target_version`)
    }

    function get(): Promise<MSUGitlabTag[] | []> {
        return fetchWrapper.get(`${baseUrl}/tags?order_by=tag&orientation=desc`)
    }

    function setTargetVersion(t: string): Promise<string> {
        return fetchWrapper.post(`${baseUrl}/set_target_version`, { version: t })
    }
}
