import { atom } from 'recoil'

const usersAtom = atom({
    key: 'users',
    default: null,
})

const impersonateUsersAtom = atom<boolean>({
    key: 'impersonate_users',
    default: false,
})

export { usersAtom, impersonateUsersAtom }
