import * as React from 'react'
import { useRecoilValue } from 'recoil'
import { machineSocketAtom } from '../_state'
import { useAlertActions } from '../_actions'
import { valuetext } from '../_helpers/utils'
import { Button, Box, Slider, Typography, Stack } from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { FluidInstrumentationStatus } from '../types'

interface Mark {
    val: number
    label: string
}

const marks: Mark[] = []
for (let i = 0; i < 9; i++) {
    marks[i] = { val: i, label: i.toString() }
}

export { PressureSlider }

interface PressureSliderProps {
    fluidInstrumentation: FluidInstrumentationStatus
}

function PressureSlider({ fluidInstrumentation }: PressureSliderProps) {
    const [loading, setLoading] = React.useState(false)
    const value = fluidInstrumentation.pressure_setpoint
    const alertActions = useAlertActions()
    const socket = useRecoilValue(machineSocketAtom)
    if (!socket || !value) return

    return (
        <Box>
            <Stack spacing={2} direction='row' alignItems='center'>
                <Typography>0</Typography>
                <Slider
                    sx={{
                        '&.MuiSlider-root.Mui-disabled': {
                            color: 'DimGray',
                        },
                    }}
                    color='secondary'
                    key={`slider-${value}`}
                    aria-label='Change Tank Pressure'
                    defaultValue={value}
                    getAriaValueText={valuetext}
                    valueLabelDisplay='on'
                    step={0.1}
                    marks
                    min={0}
                    max={2.5}
                    disabled={loading}
                    onChangeCommitted={(e, val) => {
                        setLoading(true)
                        socket.emit('send_command', {
                            service: 'fluid_instrumentation',
                            command: 'set_tank_regulator_pressure',
                            kwargs: { value: val },
                        })
                        setTimeout(() => {
                            setLoading(false)
                        }, 2000)
                    }}
                />
                <Typography>2.5</Typography>
            </Stack>
            <Stack spacing={2} direction='row' alignItems='center'>
                <Button
                    disabled={value - 0.1 < 0.0 || loading}
                    variant='contained'
                    color='secondary'
                    sx={{
                        height: '40px',
                        width: '100%',
                    }}
                    endIcon={<RemoveCircleOutlineIcon fontSize='large' />}
                    onClick={() => {
                        setLoading(true)
                        if (value - 0.1 >= 0.0) {
                            socket.emit('send_command', {
                                service: 'fluid_instrumentation',
                                command: 'set_tank_regulator_pressure',
                                kwargs: { value: value - 0.1 },
                            })
                        } else {
                            alertActions.error('Tank set_tank_regulator_pressure cannot have a value less than 0')
                        }
                        setTimeout(() => {
                            setLoading(false)
                        }, 2000)
                    }}
                >
                    DEC
                </Button>
                <Button
                    disabled={value + 0.1 > 2.5 || loading}
                    variant='contained'
                    color='secondary'
                    sx={{
                        height: '40px',
                        width: '100%',
                    }}
                    startIcon={<AddCircleOutlineIcon fontSize='large' />}
                    onClick={() => {
                        setLoading(true)
                        if (value + 0.1 > 0 && value + 0.1 <= 2.5) {
                            socket.emit('send_command', {
                                service: 'fluid_instrumentation',
                                command: 'set_tank_regulator_pressure',
                                kwargs: { value: value + 0.1 },
                            })
                        } else {
                            alertActions.error('Tank set_tank_regulator_pressure cannot have a value greater than 2.5')
                        }
                        setTimeout(() => {
                            setLoading(false)
                        }, 2000)
                    }}
                >
                    INC
                </Button>
            </Stack>
        </Box>
    )
}
