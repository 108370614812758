import { useFetchWrapper } from '../_helpers'

export { useSmsActions }

function useSmsActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`
    const fetchWrapper = useFetchWrapper()

    return {
        getCurrentSMSCredits,
        send,
    }

    async function getCurrentSMSCredits(): Promise<number> {
        return fetchWrapper.get(`${baseUrl}/current-sms-credits`)
    }

    async function send(message: string, recipientIDs: number[]) {
        // eslint-disable-next-line camelcase
        return fetchWrapper.post(`${baseUrl}/send`, { message: message, recipient_ids: recipientIDs })
    }
}
