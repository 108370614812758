import React, { useState, useRef, useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { mapAtom, subframePositionAtom } from '../_state'

export { RobotIcon }

function RobotIcon(): null {
    // console.log('[RobotIcon]: render')
    const map = useRecoilValue(mapAtom)
    const subframePosition = useRecoilValue(subframePositionAtom)
    const initialRender = useRef(true)
    const [robotIcon, setRobotIcon] = useState<google.maps.Marker | null>(null)

    useEffect(() => {
        if (map) {
            setRobotIcon(
                new window.google.maps.Marker({
                    map,
                    icon: {
                        path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                        fillColor: '#00FF00',
                        strokeColor: '#00FF00',
                        fillOpacity: 1,
                        scale: 3,
                    },
                }),
            )
        }
    }, [map])

    React.useEffect(() => {
        if (map && subframePosition && robotIcon) {
            const { lat, lon, heading, qual } = subframePosition

            if (lat === undefined || lon === undefined) return // handle missing latitude/longitude

            if (initialRender.current) {
                const bounds = new window.google.maps.LatLngBounds()
                bounds.extend(new window.google.maps.LatLng(lat, lon))
                map.fitBounds(bounds)
                initialRender.current = false
            }

            robotIcon.setPosition(new window.google.maps.LatLng(lat, lon))

            let indicator: string | google.maps.SymbolPath | null = null
            let rotation: number | undefined = heading

            if (heading === undefined) {
                indicator = window.google.maps.SymbolPath.CIRCLE
                rotation = undefined
            } else {
                indicator = window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW
                const mapHeading = map.getHeading() ?? 0 // Default to 0 if map.getHeading() is undefined
                rotation = heading - mapHeading
            }

            robotIcon.setIcon({
                path: indicator,
                strokeColor: qual !== 4 ? '#FF0000' : '#00FF00',
                fillColor: qual !== 4 ? '#FF0000' : '#00FF00',
                fillOpacity: 1,
                scale: 3,
                rotation,
            })
        }
    }, [subframePosition, map, robotIcon])

    return null
}
