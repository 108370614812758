import React, { useState } from 'react'
import { Box, Typography, Stack, LinearProgress, Button } from '@mui/material'
import { Ticket } from '../types'
import { useAlertActions, useGitlabActions } from '../_actions'
import { ReactComponent as CreateTicketIcon } from '../assets/images/create_ticket.svg'
import { GitLabCreateTicketLink } from '../_components'
import { SvgIconWrapper } from '../_components/icons/SvgIconWrapper'
import { TicketsList } from './TicketsList'

export { TicketsCollapse }

interface TicketsCollapseProps {
    machineName: string
}

const TicketsCollapse = ({ machineName }: TicketsCollapseProps) => {
    const [tickets, setTickets] = useState<Ticket[]>([])
    const [loading, setLoading] = useState(true)
    const gitlabActions = useGitlabActions()
    const alertActions = useAlertActions()

    React.useEffect(() => {
        gitlabActions
            .getByLabel(machineName)
            .then(setTickets)
            .catch(() => alertActions.error(`Could not get tickets for ${machineName}`))
            .finally(() => setLoading(false))
    }, [machineName])

    const RenderList = () => {
        if (!loading && tickets.length > 0) {
            return <TicketsList machineName={machineName} tickets={tickets} />
        }
        return <></>
    }

    return (
        <Box sx={{ mt: 1 }}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography variant='h6'>Tickets</Typography>
                <Button
                    variant='contained'
                    color='success'
                    size='small'
                    startIcon={
                        <SvgIconWrapper>
                            <CreateTicketIcon fontSize='small' />
                        </SvgIconWrapper>
                    }
                >
                    <GitLabCreateTicketLink machineName={machineName} />
                </Button>
            </Stack>
            {loading && <LinearProgress color='success' sx={{ mt: 1 }} />}
            {!loading && tickets?.length === 0 && <Typography variant='body1'>No tickets found</Typography>}
            <RenderList />
        </Box>
    )
}
