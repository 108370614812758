import { useSetRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { selectedGroupIDsAtom } from '../_state'

export { useGroupIDsSelector }
function useGroupIDsSelector() {
    const selectedGroupIDs = useRecoilValue(selectedGroupIDsAtom)
    const setSelectedGroupIDs = useSetRecoilState(selectedGroupIDsAtom)
    //const map = useRecoilValue(mapAtom)
    return {
        add,
        resetSelectedGroupIDs: useResetRecoilState(selectedGroupIDsAtom),
    }

    // TODO improve function to listen only to selectedGroupIDS
    // and map and inside a useEffect to mutate the map
    function add(groupID: number) {
        if (selectedGroupIDs.has(groupID)) {
            selectedGroupIDs.delete(groupID)
            setSelectedGroupIDs(new Set([...selectedGroupIDs]))
            //if (map) removeBedStyleSingle(map, groupID)
        } else {
            setSelectedGroupIDs(new Set([...selectedGroupIDs, groupID]))
            //if (map) highlightBed(map, groupID)
        }
    }
}
