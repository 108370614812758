import { useFetchWrapper } from '../_helpers'
import { InferenceModelsResponse } from '../types'
export { useInferenceModelActions }

function useInferenceModelActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`
    const fetchWrapper = useFetchWrapper()

    return {
        getModels,
    }

    function getModels(): Promise<InferenceModelsResponse> {
        return fetchWrapper.get(`${baseUrl}/inference_models`)
    }
}
