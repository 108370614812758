import Avatar from '@mui/material/Avatar'
import { useTheme } from '@mui/material/styles'
function stringToColor(string: string) {
    let hash = 0
    let i

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash)
    }

    let color = '#'

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff
        color += `00${value.toString(16)}`.substr(-2)
    }
    /* eslint-enable no-bitwise */

    return color
}

function stringAvatar(name: string) {
    const theme = useTheme()
    return {
        sx: {
            bgcolor: stringToColor(name),
            width: 34,
            height: 34,
            fontSize: 14,
            color: theme.palette.secondary.main,
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    }
}

export { NameAvatar }

interface NameAvatarProps {
    name: string
}
function NameAvatar({ name }: NameAvatarProps) {
    return <Avatar {...stringAvatar(name)} />
}
