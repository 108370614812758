import React, { ChangeEvent, useState, useEffect } from 'react'
import UserService from '../services/UserService'
import { ModalBox, SelectBox, SimpleAccordion } from '../_components'
import { Button, Stack, Typography } from '@mui/material'
import { MSUMsg, MsuCommandResponse } from '../types'
import { MSUInstallHistoryTable } from './MSUInstallHistoryTable'
import { formatToDutchTimezone, ws, socket } from '../_helpers'
import { MSUUserView } from './MSUUserView'
import { useAlertActions } from '../_actions'

interface MSUAdminPanelProps {
    machine: string
    msuClick: (param: null) => void
}

interface TagSelectModalProps {
    currentVersion: string
}
export default function MSUAdminPanel({ machine, msuClick }: MSUAdminPanelProps) {
    const [MSUMsg, setMSUMsg] = useState<MSUMsg | null>(null)
    const [selectedTag, setSelectedTag] = useState<string | null>(null)
    const [tagOptions, setTagOptions] = useState<string[]>([])
    const [selectVersionModalOpen, setSelectedVersionModalOpen] = useState<boolean>(false)
    const [open, setOpen] = useState(true)
    const [loading, setLoading] = useState(false)
    const alertActions = useAlertActions()

    const handleChangeSelectedVersion = (event: ChangeEvent<HTMLSelectElement>) => {
        // setSelectedTag(tagOptions.find((item) => item === event.target.value))
        const value = event.target.value
        const foundTag = tagOptions.find((item) => item === value)
        setSelectedTag(foundTag || null)
    }

    const handleClose = () => {
        setOpen(false)
        msuClick(null)
    }

    const handleUpdateTargetVersion = () => {
        socket.emit('send_command', {
            service: 'msu',
            command: 'update_target_version',
            kwargs: {
                machine: machine,
                // eslint-disable-next-line camelcase
                target_version: selectedTag,
            },
        })
        setSelectedVersionModalOpen(false)
    }

    const TagSelectModal = ({ currentVersion }: TagSelectModalProps) => {
        const [onOpenFetched, setOnOpenFetched] = useState(false)
        const handleOnOpen = () => {
            if (onOpenFetched === true) return
            socket.emit('pull_tags', {
                service: 'msu',
                command: 'pull_tags',
                kwargs: {},
            })
            setOnOpenFetched(true)
        }
        return (
            <ModalBox
                title={'Select version'}
                open={selectVersionModalOpen}
                handleDialogClose={() => setSelectedVersionModalOpen(false)}
            >
                <Stack>
                    <SelectBox
                        key={'version-select'}
                        inputLabel='Select version'
                        value={selectedTag || currentVersion}
                        options={tagOptions}
                        disabled={false}
                        handleChange={handleChangeSelectedVersion}
                        handleOnOpen={handleOnOpen}
                    />
                    <Stack direction={'row'} spacing={1} sx={{ mt: 1 }}>
                        <Button variant='contained' color='success' onClick={() => handleUpdateTargetVersion()}>
                            {'Save'}
                        </Button>
                        <Button variant='contained' color='error' onClick={() => setSelectedVersionModalOpen(false)}>
                            {'Cancel'}
                        </Button>
                    </Stack>
                </Stack>
            </ModalBox>
        )
    }

    useEffect(() => {
        ws('msu', machine)
        if (socket) {
            socket.on('msu_msg', (msg: MSUMsg) => {
                setLoading((prev) => {
                    if (prev) {
                        return false
                    }
                    return prev
                })
                setMSUMsg(msg)
                setTagOptions(
                    msg.tags.map((el) => {
                        return el['tag']
                    }),
                )
            })
            socket.on('UNAUTHORIZED', () => UserService.doLogout())
            socket.on('notification', (msg: MsuCommandResponse) => {
                alertActions[msg.status](msg.detail)
                setLoading(false)
            })
        }
        return () => {
            if (socket) {
                socket.off(machine)
                socket.close()
            }
        }
    }, [])

    if (!socket) return null
    return (
        <ModalBox title={`${machine} Software Status`} open={open} handleDialogClose={() => msuClick(null)}>
            {MSUMsg?.installinfo && (
                <>
                    <Typography variant='subtitle1'>Latest installation</Typography>
                    <Typography variant='body2'>
                        {`Version: 🏷️ ${MSUMsg.installinfo.tag} -> commit:  ${MSUMsg.installinfo.commit_id}`}
                    </Typography>
                    <Typography variant='body2' gutterBottom>
                        {`Synced at: ${formatToDutchTimezone(MSUMsg.installinfo.created_at)}`}
                    </Typography>
                </>
            )}
            {MSUMsg?.target_version && <TagSelectModal currentVersion={MSUMsg.target_version} />}
            <Stack>
                <Stack direction={'row'} spacing={1} sx={{ mb: 1 }}>
                    <Button
                        variant='contained'
                        onClick={() => {
                            setLoading(true)
                            socket.emit('send_command', {
                                service: 'msu',
                                command: 'installinfo',
                                kwargs: { machine: machine },
                            })
                        }}
                        fullWidth
                    >
                        Installinfo
                    </Button>
                </Stack>
                <Button variant='contained' sx={{ mb: 1 }} onClick={() => setSelectedVersionModalOpen(true)}>
                    {`Target version: ${MSUMsg?.target_version}`}
                </Button>
                {MSUMsg?.install_history && (
                    <SimpleAccordion title={'Install history'}>
                        <MSUInstallHistoryTable data={MSUMsg.install_history} />
                    </SimpleAccordion>
                )}
                <Typography sx={{ mt: 2 }} variant='h5' gutterBottom>
                    What the user sees
                </Typography>
                {MSUMsg?.target_version !== MSUMsg?.installinfo?.tag && (
                    <MSUUserView socket={socket} handleClose={handleClose} />
                )}
            </Stack>
        </ModalBox>
    )
}
