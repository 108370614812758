import React, { useState, MouseEvent, ReactNode } from 'react'
import InfoIcon from '@mui/icons-material/Info'
import { Popover, IconButton, Typography, Fade, Box } from '@mui/material'

interface InfoPopoverProps {
    content: ReactNode | string[]
}

export const InfoPopover = ({ content }: InfoPopoverProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

    const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)

    const renderContent = () => {
        if (Array.isArray(content)) {
            return (
                <Box sx={{ p: 2 }}>
                    {content.map((item, index) => (
                        <React.Fragment key={index}>
                            <Typography
                                component='div'
                                dangerouslySetInnerHTML={{ __html: item }}
                                sx={{ mb: index === 0 ? 2 : 0 }}
                            />
                        </React.Fragment>
                    ))}
                </Box>
            )
        }
        if (React.isValidElement(content)) {
            return <Box sx={{ p: 2 }}>{content}</Box>
        }
        return (
            <Typography component='div' sx={{ p: 2 }}>
                {content}
            </Typography>
        )
    }

    return (
        <>
            <IconButton onClick={open ? handlePopoverClose : handlePopoverOpen}>
                <InfoIcon />
            </IconButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                onClose={handlePopoverClose}
                onClick={handlePopoverClose}
                TransitionComponent={Fade}
                transitionDuration={300}
            >
                <Fade in={open}>
                    <Box>{renderContent()}</Box>
                </Fade>
            </Popover>
        </>
    )
}
