import { useNavigate } from 'react-router-dom'
import { Upload } from '../_components/Upload'
import { Container } from '@mui/material'

export { Add }

function Add() {
    const navigate = useNavigate()
    const onClose = () => navigate(-1)
    return (
        <Container sx={{ height: '100%' }}>
            <Upload type='field' overwrite={false} onClose={onClose} />
        </Container>
    )
}
