import { useRecoilValue } from 'recoil'
import { Box, Typography, CircularProgress, Fab, Stack, Toolbar } from '@mui/material'
import { ReverseTaskButton, CountDownIndicator, TreatmentCounterIndicator } from '..'
import { taskBarOpenAtom, machineControlRunningSelector, taskAtom, taskQuery } from '../../_state'
import { StartJobWrapper } from '../StartJobWrapper'

export default function TaskBar() {
    // console.log('[TaskBar] Render')
    const task = useRecoilValue(taskAtom)
    const taskQ = useRecoilValue(taskQuery)
    const machineControlRunning = useRecoilValue(machineControlRunningSelector)
    const taskBarOpen = useRecoilValue(taskBarOpenAtom)

    const TaskInfo = () => {
        if (taskQ.isLoading) {
            return (
                <Fab color={'info'}>
                    <CircularProgress color={'secondary'} />
                </Fab>
            )
        } else if (task && taskQ.isLoading === false) {
            return (
                <>
                    <Box sx={{ mr: 0.5 }}>
                        <Typography align='center' noWrap>
                            TaskID
                        </Typography>
                        <Typography align='center' noWrap>
                            {task.task_request_id}
                        </Typography>
                    </Box>
                    <ReverseTaskButton />
                </>
            )
        } else {
            return <></>
        }
    }

    const render = () => {
        return (
            <Box sx={{ flexGrow: 1 }} visibility={taskBarOpen ? 'visible' : 'hidden'}>
                <Toolbar sx={{ borderRadius: 2 }} variant='dense'>
                    <TaskInfo />
                    {machineControlRunning && (
                        <Box>
                            <CountDownIndicator />
                            <TreatmentCounterIndicator />
                        </Box>
                    )}
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'flex' } }}>
                        <Stack direction='row' spacing={1}>
                            {taskQ.isLoading === false && <StartJobWrapper />}
                        </Stack>
                    </Box>
                </Toolbar>
            </Box>
        )
    }
    return render()
}
