import { useFetchWrapper } from '../_helpers'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { taskAtom, startStopTaskButtonLoadingState, machineNameAtom } from '../_state'
import { useAlertActions } from './alert.actions'
import { CameraSettings, StartJobReqBody, StartJobResponse, StopJobResponse } from '../types'
import { taskManagerAtom } from '../_state/taskManager'
export { useJobActions }

function useJobActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`
    const fetchWrapper = useFetchWrapper()
    const alertActions = useAlertActions()
    const setTaskManager = useSetRecoilState(taskManagerAtom)
    const setStartStopTaskButtonLoading = useSetRecoilState(startStopTaskButtonLoadingState)
    const task = useRecoilValue(taskAtom)
    const machineName = useRecoilValue(machineNameAtom)
    let res

    return {
        getJobsByMachineName,
        startJob,
        stopJob,
    }
    function getJobsByMachineName(machineName: string) {
        return fetchWrapper.get(`${baseUrl}/jobs/machines/${machineName}`)
    }

    function startJob(settings?: CameraSettings) {
        if (!task) {
            console.log('Could not start job. Task not found')
            return
        }
        setStartStopTaskButtonLoading(true)
        console.info(`Setting task ${task.id} on machine ${machineName}`)
        const reqBody: StartJobReqBody = {
            task_id: task.id,
            camera_settings: settings ? settings : null,
        }

        fetchWrapper
            .post(`${baseUrl}/jobs/machines/${machineName}/start`, reqBody)
            .then((res: StartJobResponse) => {
                if (res.status === 'success') {
                    alertActions.info('Task started')
                } else {
                    alertActions.error('Could not start task')
                }
            })
            .catch(() => alertActions.error('Could not start task'))
            .finally(() => {
                setStartStopTaskButtonLoading(false)
                setTaskManager('')
            })
    }

    function stopJob() {
        console.info(`Stoping task on machine machine_name:${machineName}`)
        setStartStopTaskButtonLoading(true)
        res = fetchWrapper.post(`${baseUrl}/jobs/machines/${machineName}/stop`).then((res: StopJobResponse) => {
            setStartStopTaskButtonLoading(false)
            if (res.status === 'success') {
                alertActions.success('Task stopped')
            } else {
                alertActions.error('Could not stop the task')
            }
        })
        return res
    }
}
