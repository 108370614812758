export function handleOpenDirections(lat: number, lon: number) {
    if (lat && lon) {
        // https://developers.google.com/maps/documentation/urls/get-started
        if (window.innerWidth > 750) {
            window.open(`https://www.google.com/maps/search/?api=1&query=${lat}%2C${lon}`)
        } else {
            window.location.href = `https://www.google.com/maps/search/?api=1&query=${lat}%2C${lon}`
        }
    }
}

export function sleep(time: number) {
    return new Promise((resolve) => setTimeout(resolve, time))
}

export function thresholdRemap(x: number, inMin: number, inMax: number, outMin: number, outMax: number) {
    return ((x - inMax) * (outMin - outMax)) / (inMin - inMax) + outMax
}

/** Because in JavaScript 0.1 + 0.2 ≠ 0.3 */
export function stripDecimals(val: number): number {
    if (val && val >= 0 && val <= 1.0) {
        return Number(val.toFixed(3))
    } else {
        throw new Error('Input value must be between 0 and 1.0 inclusive')
    }
}

export function valuetext(value: number): string {
    return `${value}`
}

export function b64(e: ArrayBuffer) {
    let t = ''
    const n = new Uint8Array(e)
    const r = n.byteLength
    for (let i = 0; i < r; i++) {
        t += String.fromCharCode(n[i])
    }
    return window.btoa(t)
}

export function openImageInNewTab(data: string) {
    const win = window.open()
    if (!win) return
    win.document.write(
        '<iframe src="' +
        data +
        '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>',
    )
    win.document.close()
}

export const formatDate = (timestamp: number) => {
    if (!timestamp) {
        return null
    }
    const m = new Date(timestamp * 1000)
    return (
        ('0' + m.getHours()).slice(-2) +
        ':' +
        ('0' + m.getMinutes()).slice(-2) +
        ':' +
        ('0' + m.getSeconds()).slice(-2)
    )
}

export const arraysHaveSameContent = (array1, array2) => {
    if (array1.length !== array2.length) {
        return false
    }

    return array1.every((element) => array2.includes(element))
}

export const formatToDutchTimezone = (date: string, timeOnly?: boolean) => {
    if (!date) {
        return null
    }
    date = new Date(date)
        .toLocaleString(['nl'], {
            hour12: false,
        })
        .split('-')
        .join('/')
    if (timeOnly) {
        date = date.split(' ')[1]
    }
    return date
}

export function convertUtcToLocale(utcDateString: string) {
    return new Intl.DateTimeFormat('en-US', {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
    })
        .format(new Date(utcDateString + 'Z'))
        .replace(',', '')
}

export function formatBytes(bytes: number, decimals = 2): string {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

import { useTheme } from '@mui/material/styles'
export const useGetBtnColor = (machineTaskState: string) => {
    const theme = useTheme()
    if (machineTaskState === 'start') {
        return theme.palette.success.light
    } else if (machineTaskState === 'stop') {
        return theme.palette.error.light
    } else if (machineTaskState === 'resume') {
        return theme.palette.warning.light
    }
}

// auto-motor-control -> Auto Motor Control
export const removeDashCapitalize = (str: string) => {
    return str
        .split('-')
        .map(function capitalize(part: string) {
            return part.charAt(0).toUpperCase() + part.slice(1)
        })
        .join(' ')
}

export const getTimeSinceNow = (dateString: string): string => {
    const date = new Date(dateString)
    const now = new Date()
    const diff = now.getTime() - date.getTime()

    const minute = 60 * 1000
    const hour = 60 * minute
    const day = 24 * hour

    if (diff < minute) {
        return 'just now'
    } else if (diff < hour) {
        const minutes = Math.floor(diff / minute)
        return `${minutes} minute${minutes === 1 ? '' : 's'} ago`
    } else if (diff < day) {
        const hours = Math.floor(diff / hour)
        return `${hours} hour${hours === 1 ? '' : 's'} ago`
    } else {
        const days = Math.floor(diff / day)
        return `${days} day${days === 1 ? '' : 's'} ago`
    }
}

export const generateArray = (major: number, minor: number, patch: number) => {
    const array: string[] = []
    for (let i = 1; i <= patch; i++) {
        array.push(`${major}.${minor}.${i}`)
    }
    return array
}
