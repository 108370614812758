import React from 'react'
import { addStyleFromGeoJSON } from '../../src/_helpers'
import { useAlertActions } from '../_actions'

interface SkipRowsProps {
    beds: google.maps.Data
}

export function SkipRows(props: SkipRowsProps): null {
    useAlertActions().info('You can skip a row of beds by clicking on it and making it black')
    addStyleFromGeoJSON(props.beds)

    React.useEffect(() => {
        props.beds.addListener('click', (event) => {
            const row_number = event.feature.getProperty('row_number')
            props.beds.forEach((f: google.maps.Data.Feature) => {
                if (f.getProperty('row_number') == row_number) {
                    f.setProperty('skip', !f.getProperty('skip'))
                }
            })
            addStyleFromGeoJSON(props.beds)
        })
        return () => {
            window.google.maps.event.clearListeners(props.beds, 'click')
        }
    }, [])
    return null
}
