import AccountCircle from '@mui/icons-material/AccountCircle'
import SecurityIcon from '@mui/icons-material/Security'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import EngineeringIcon from '@mui/icons-material/Engineering'
import AccessibilityIcon from '@mui/icons-material/Accessibility'
import { useRecoilValue } from 'recoil'
import { impersonateUsersAtom } from '../_state'

interface RoleIconProps {
    role: string
}
export const RoleIcon = ({ role }: RoleIconProps) => {
    const impersonateUser = useRecoilValue(impersonateUsersAtom)
    if (impersonateUser) return <AccessibilityIcon />

    switch (role) {
        case 'admin':
            return <SecurityIcon />
        case 'manager':
            return <SupervisorAccountIcon />
        case 'manufacturer':
            return <EngineeringIcon />
        case 'user':
            return <AccountCircle />
        default:
            return <AccountCircle />
    }
}
