import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { DialogDataType } from '../types'
export { ConfirmDialog }

type ConfirmDialogProps = {
    title: string
    children: React.ReactNode
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    onConfirm: (type: DialogDataType) => void
    onCancel?: () => void
    type: any
}

function ConfirmDialog({ title, children, open, setOpen, onConfirm, onCancel, type }: ConfirmDialogProps) {
    return (
        <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby='confirm-dialog'>
            <DialogTitle id='confirm-dialog'>{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button
                    variant='contained'
                    color='error'
                    onClick={() => {
                        setOpen(false)
                        onConfirm(type)
                    }}
                >
                    Yes
                </Button>
                <Button
                    variant='contained'
                    color='secondary'
                    onClick={() => {
                        if (onCancel) {
                            onCancel()
                            setOpen(false)
                        } else {
                            setOpen(false)
                        }
                    }}
                >
                    No
                </Button>
            </DialogActions>
        </Dialog>
    )
}
