import { CreateBoundaryResponse } from '../types'

import { useFetchWrapper } from '../_helpers'

export { useBoundaryActions }

function useBoundaryActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`
    const fetchWrapper = useFetchWrapper()

    return {
        uploadBoundary,
    }

    function uploadBoundary(fieldID: number, file: File): Promise<CreateBoundaryResponse> {
        const formData = new FormData()
        formData.append('input_file', file)
        return fetchWrapper.post(`${baseUrl}/fields/${fieldID}/boundaries`, formData)
    }
}
