import React, { useState } from 'react'
import { useRecoilValue, useResetRecoilState, useRecoilState, useSetRecoilState } from 'recoil'
import {
    machineControlAtom,
    taskListSelectedIndexState,
    taskRequestsAtom,
    subframePositionStartAtom,
    h2lFieldJsonAtom,
    taskManagerAtom,
    taskQuery,
} from '../_state'
import { Box, Stack, List, Typography, Chip, CircularProgress } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { TaskListItem } from './TaskListItem'
import { useCropActions, useTaskActions, useTaskRequestActions, useAlertActions, useFieldActions } from '../_actions'

interface TaskListProps {
    closeIcon?: React.ReactNode
}

export const TaskList = ({ closeIcon }: TaskListProps) => {
    // console.log('[TaskList] Render')
    const [nearest, setNearest] = useState(true)
    const [loading, setLoading] = useState(true)
    const h2lField = useRecoilValue(h2lFieldJsonAtom)
    const [taskListItems, setTaskListItems] = useRecoilState(taskRequestsAtom)
    const [selectedIndex, setSelectedIndex] = useRecoilState(taskListSelectedIndexState)
    const setTaskManager = useSetRecoilState(taskManagerAtom)
    const resetSelectedIndex = useResetRecoilState(taskListSelectedIndexState)
    const machineControl = useRecoilValue(machineControlAtom)
    const gps = useRecoilValue(subframePositionStartAtom)
    const fieldActions = useFieldActions()
    const alert = useAlertActions()
    const taskRequestActions = useTaskRequestActions()

    const setTask = useSetRecoilState(taskQuery)
    const setH2lFieldJson = useSetRecoilState(h2lFieldJsonAtom)
    const taskActions = useTaskActions()
    const cropActions = useCropActions()
    const taskQ = useRecoilValue(taskQuery)

    React.useEffect(() => {
        if (nearest && gps && gps.lon && gps.lat) {
            setLoading(true)
            fieldActions
                .getNearestField(gps.lon, gps.lat)
                .then((h2lField) => {
                    setH2lFieldJson(h2lField)
                    return taskRequestActions.getByCompany(h2lField.field_id)
                })
                .then(setTaskListItems)
                .catch(() => {
                    alert.error('Error gettings tasks')
                })
                .finally(() => {
                    setLoading(false)
                })
        } else if (!nearest) {
            setLoading(true)
            taskRequestActions
                .getByCompany()
                .then((res) => {
                    setTaskListItems(res)
                })
                .catch((res) => {
                    console.error(res)
                    alert.error('Error getting tasks')
                })
                .finally(() => {
                    setLoading(false)
                })
        } else {
            // convers the case where there is no gps
            setLoading(false)
        }
        return () => {
            setTaskListItems([])
            resetSelectedIndex()
            //setH2lField(null) //This is required, but good for  now
        }
    }, [nearest, gps])

    let taskId: number | null = null
    if (selectedIndex > -1 && taskListItems && taskListItems[selectedIndex]) {
        taskId = taskListItems[selectedIndex]['task_forward_id']
    } else if (machineControl?.task_id) {
        taskId = machineControl.task_id
    }

    React.useEffect(() => {
        if (taskId) {
            setTask({ data: null, isLoading: true })
            taskActions.getById(taskId).then((res) => {
                cropActions
                    .getCropByIdWithTulibBeds(res.crop_id)
                    .then(setH2lFieldJson)
                    .then(() => {
                        setTask({ data: res, isLoading: false })
                    })
            })
        }
    }, [taskId])

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant='h5' sx={{ mt: 0.5 }}>
                    {nearest && h2lField ? `Tasks - ${h2lField.name}` : 'Tasks'}
                </Typography>
                {closeIcon}
            </Box>
            <Stack direction={'row'} justifyContent='space-between'>
                <Stack direction={'row'} alignItems='center' spacing={1}>
                    <Chip
                        label='Nearest'
                        color={nearest ? 'secondary' : 'primary'}
                        onClick={() => {
                            setLoading(true)
                            setNearest(true)
                        }}
                    />
                    <Chip
                        label='All'
                        color={!nearest ? 'secondary' : 'primary'}
                        onClick={() => {
                            setLoading(true)
                            setNearest(false)
                        }}
                    />
                </Stack>
                <Chip
                    onClick={() => setTaskManager('create')}
                    icon={<AddIcon />}
                    sx={{
                        zIndex: 110,
                    }}
                    color='secondary'
                    label={h2lField ? 'Maak nieuwe taak' : 'Geen veld dichtbij'}
                    disabled={!h2lField}
                />
            </Stack>
            {loading ? (
                <CircularProgress size={'100px'} color='secondary' sx={{ mt: 10, mb: 10 }} />
            ) : (
                <List>
                    {taskListItems.map((ts_item, index) => (
                        <TaskListItem
                            key={ts_item.id}
                            taskListItem={ts_item}
                            selected={index === selectedIndex}
                            onClick={() => setSelectedIndex(index)}
                            disabled={taskQ.isLoading}
                        />
                    ))}
                </List>
            )}
            {!loading && taskQ.isLoading === false && taskListItems.length == 0 && (
                <Typography sx={{ m: 2 }}>No tasks found, click on the Create button to make a task.</Typography>
            )}
            {!loading && gps === null && (
                <Typography sx={{ m: 2 }}>Unable to load nearest tasks because there is no GPS signal</Typography>
            )}
        </>
    )
}
