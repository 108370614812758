import { atom, selector } from 'recoil'
import { TreatmentMode } from '../types'

const clusteringThresholdAtom = atom<number | null>({
    key: 'clusteringThreshold',
    default: null,
})

const clusteringAtom = atom<number | null>({
    key: 'clusteringAtom',
    default: null,
})
const clusteringTreatmentDensityTargetAtom = atom<number>({
    key: 'treatmentDensityTarget',
    default: 0,
})

const clusteringTreatmentModeAtom = atom<number>({
    key: 'treatmentMode',
    default: 0,
})

const clusteringTreatmentModeSelector = selector<TreatmentMode>({
    key: 'clusteringTreatmentModeSelector',
    get: ({ get }) => {
        const mode = get(clusteringTreatmentModeAtom)
        if (mode === null) return null
        return mode === 0 ? 'threshold' : 'density'
    },
})
export {
    clusteringAtom,
    clusteringThresholdAtom,
    clusteringTreatmentDensityTargetAtom,
    clusteringTreatmentModeAtom,
    clusteringTreatmentModeSelector,
}
