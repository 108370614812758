import { CircularStatic } from './CircularStatic'

interface IconProps {
    color: string
    IconComponent: any
    loading?: boolean
}

export const Icon = ({ color, IconComponent, loading = false }: IconProps) => {
    if (loading) {
        return (
            <CircularStatic size={40}>
                <IconComponent sx={{ color: { color } }} fontSize='large' />
            </CircularStatic>
        )
    }
    return <IconComponent sx={{ color: { color } }} fontSize='large' />
}
