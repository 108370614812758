import { useFetchWrapper } from '../_helpers'
import { CreateTaskRequest, Task } from '../types'
export { useTaskActions }

function useTaskActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`
    const fetchWrapper = useFetchWrapper()

    return {
        create,
        getById,
    }

    function create(req: CreateTaskRequest): Promise<Task> {
        return fetchWrapper.post(`${baseUrl}/tasks`, req)
    }

    function getById(id: number): Promise<Task> {
        return fetchWrapper.get(`${baseUrl}/tasks/${id}`)
    }
}
