import { useFetchWrapper } from '../_helpers'
import { Ticket } from '../types'

export { useGitlabActions }

function useGitlabActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`
    const fetchWrapper = useFetchWrapper()

    return {
        getByIID,
        getByLabel,
        closeIssue,
    }

    async function getByIID(iid: number): Promise<Ticket> {
        return fetchWrapper.get(`${baseUrl}/gitlab/issues/iid/${iid}`)
    }

    async function getByLabel(label: string): Promise<Ticket[]> {
        return fetchWrapper.get(`${baseUrl}/gitlab/issues/${label}`)
    }

    async function closeIssue(iid: number, comment: string) {
        return fetchWrapper.post(`${baseUrl}/gitlab/issues/iid/${iid}`, {
            comment: comment,
        })
    }
}
