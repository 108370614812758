import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled'
import StopCircleIcon from '@mui/icons-material/StopCircle'

export const GetBtnIcon = (machineTaskState: string) => {
    if (machineTaskState === 'start') {
        return <PlayCircleFilledIcon />
    } else if (machineTaskState === 'stop') {
        return <StopCircleIcon />
    } else if (machineTaskState === 'resume') {
        return <PlayCircleFilledIcon />
    } else if (machineTaskState === 'loading') {
        return <StopCircleIcon />
    } else if (machineTaskState === 'select a task') {
        return <PlayCircleFilledIcon />
    }
}
