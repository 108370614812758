import { useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { alertAtom } from '../_state'
import { useAlertActions } from '../_actions'
import { history } from '../_helpers'
import { SnackBarMessage } from './'

export { Alert }

function Alert() {
    const alert = useRecoilValue(alertAtom)
    const alertActions = useAlertActions()

    useEffect(() => {
        // clear alert on location change
        const unlisten = history.listen(alertActions.clear)

        // stop the listener when component unmounts
        return unlisten
    }, [])

    if (!alert) return null

    return <SnackBarMessage message={alert['message']} severity={alert['type']} />
}
