/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import { Stack, Typography, Divider, Button, LinearProgress } from '@mui/material'
import { TreatmentSafetyRangeSlider } from './TreatmentSafetyRangeSlider'
import { useAlertActions, useFluidSafetyActions } from '../../../_actions'
import {
    machineNameAtom,
    fluidInstrumentationSettingsIDSelector,
    fluidInstrumentationDispenseFlowSelector,
    fluidInstrumentationDetectionDensitySelector,
    fluidInstrumentationTankLevelSelector,
    fluidInstrumentationDispenseVolumeSelector,
} from '../../../_state'
import { InfoPopover } from '../../InfoPopover'
import WaterDropIcon from '@mui/icons-material/WaterDrop'
import CircleIcon from '@mui/icons-material/Circle'
import { TreatmentSafetySettings } from '../../../types'
import { useRecoilValue } from 'recoil'
import { blue, amber } from '@mui/material/colors'
import { SplashIcon } from '../../../_components/icons/SplashIcon'
import { CustomSwitch } from '../../CustomSwitch'
import { SingleSprayControl } from '../../../_components/low_level_controls/SingleSprayControl'
import { useNavigate } from 'react-router-dom'
import { sleep } from '../../../_helpers'
import { useTheme } from '@mui/material/styles'
import Slider from '@mui/material/Slider'
import { ThreeTulipsIcon } from '../../../_components/icons/ThreeTulipsIcon'

const TankLevelMeter = () => {
    const theme = useTheme()
    const tankLevelVal = useRecoilValue(fluidInstrumentationTankLevelSelector)
    if (tankLevelVal === null) return null
    return (
        <Stack spacing={1}>
            <Typography variant='caption' sx={{ textAlign: 'center', mb: '-10px' }}>
                Tankniveau:{' '}
                <span
                    style={{
                        color:
                            tankLevelVal < 1.5
                                ? 'red'
                                : tankLevelVal >= 1.5 && tankLevelVal < 3
                                  ? 'yellow'
                                  : tankLevelVal >= 3 && tankLevelVal < 10
                                    ? 'white'
                                    : 'red',
                    }}
                >
                    {tankLevelVal} L
                </span>
            </Typography>
            <Stack direction={'row'} alignItems={'center'}>
                <Typography variant='caption' sx={{ ml: 3 }}>
                    {'0'}
                </Typography>
                <Slider
                    min={0}
                    max={10}
                    marks={tankLevelVal <= 10 ? [{ value: tankLevelVal }] : [{ value: 10 }]}
                    sx={{
                        '& .MuiSlider-rail': {
                            opacity: 1,
                            background: 'linear-gradient(to right, red 0% 15%, yellow 15% 30%, green 30% 100%)',
                        },
                        pointerEvents: 'none !important',
                        '& .MuiSlider-rail, & .MuiSlider-track': {
                            pointerEvents: 'none',
                        },
                        '& .MuiSlider-thumb': {
                            display: 'none',
                        },
                        '& .MuiSlider-mark': {
                            height: '35px',
                            width: '5px',
                            backgroundColor: theme.palette.success.main,
                            opacity: 1,
                        },
                        mr: 2,
                        ml: 2,
                    }}
                />
                <Typography variant='caption' sx={{ mr: 5.5 }}>
                    {'10'}
                </Typography>
            </Stack>
        </Stack>
    )
}

interface AlarmToggleProps {
    primaryText: string
    alarmEnabled: boolean
    secondaryText?: string
    settingDisabled: boolean
    infoText: string[]
    handleToggleSwitch: any
}
const AlarmToggle = ({
    primaryText,
    alarmEnabled,
    settingDisabled,
    infoText,
    handleToggleSwitch,
}: AlarmToggleProps) => {
    if (primaryText === 'Tankniveau alarm') {
        const tankLevelVal = useRecoilValue(fluidInstrumentationTankLevelSelector)
        if (tankLevelVal === null) return null
    }
    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Stack direction={'row'} spacing={1}>
                    <Typography variant='h6' sx={{ fontWeight: 500 }}>
                        {primaryText} {alarmEnabled ? 'AAN' : 'UIT'}
                    </Typography>
                </Stack>
                <Stack direction={'row'} alignItems={'center'} spacing={-1}>
                    <CustomSwitch
                        sx={{ m: 1 }}
                        checked={alarmEnabled}
                        disabled={settingDisabled}
                        onChange={() => {
                            handleToggleSwitch()
                        }}
                    />
                    <InfoPopover content={infoText} />
                </Stack>
            </Stack>
            {primaryText === 'Tankniveau alarm' && (
                <>
                    <TankLevelMeter />
                    <Divider />
                </>
            )}
        </>
    )
}

interface TreatmentSafetySettingProps {
    primaryText: string
    alarmEnabled: boolean
    secondaryText?: string
    settingDisabled: boolean
    metricUnit: string
    infoText: string[]
    sliderMin: number
    sliderMax: number
    sliderStep: number
    sliderValues: number[]
    socketAttr?: string
    leftIcon?: any
    rightIcon?: any
    handleToggleSwitch: any
    handleUpdateSettings: any
}
const TreatmentSafetySetting = ({
    primaryText,
    alarmEnabled,
    secondaryText,
    settingDisabled,
    metricUnit,
    infoText,
    sliderMin,
    sliderMax,
    sliderStep,
    sliderValues,
    socketAttr,
    leftIcon,
    rightIcon,
    handleToggleSwitch,
    handleUpdateSettings,
}: TreatmentSafetySettingProps) => {
    const selector =
        socketAttr === 'dispense_volume'
            ? fluidInstrumentationDispenseVolumeSelector
            : socketAttr === 'dispense_flow'
              ? fluidInstrumentationDispenseFlowSelector
              : socketAttr === 'detection_density'
                ? fluidInstrumentationDetectionDensitySelector
                : socketAttr === 'tank_level'
                  ? fluidInstrumentationTankLevelSelector
                  : undefined

    const secondaryTextvalue = selector ? useRecoilValue(selector) : null
    return (
        <Stack>
            <>
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Stack direction={'row'} spacing={1}>
                        <Typography variant='h6' sx={{ fontWeight: 500 }}>
                            {primaryText} {alarmEnabled ? 'AAN' : 'UIT'}
                        </Typography>
                    </Stack>
                    <Stack direction={'row'} alignItems={'center'} spacing={-1}>
                        <CustomSwitch
                            sx={{ m: 1 }}
                            checked={alarmEnabled}
                            disabled={settingDisabled}
                            onChange={() => {
                                handleToggleSwitch()
                            }}
                        />
                        <InfoPopover content={infoText} />
                    </Stack>
                </Stack>
                <Stack spacing={1}>
                    <Stack
                        direction={'row'}
                        sx={{
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        {secondaryText && secondaryTextvalue !== null && (
                            <Typography variant='subtitle1' sx={{ width: '100%' }}>
                                {`${secondaryText} ${secondaryTextvalue} ${metricUnit}`}
                            </Typography>
                        )}
                    </Stack>
                    <TreatmentSafetyRangeSlider
                        value={sliderValues}
                        disabled={!alarmEnabled || settingDisabled}
                        step={sliderStep}
                        min={sliderMin}
                        max={sliderMax}
                        metricUnit={metricUnit}
                        socketAttr={socketAttr}
                        leftIcon={leftIcon}
                        rightIcon={rightIcon}
                        handleUpdateSettings={handleUpdateSettings}
                    />
                </Stack>
            </>
        </Stack>
    )
}

export const TreatmentSafety = () => {
    const navigate = useNavigate()
    const machineName = useRecoilValue(machineNameAtom)
    const socketSettingsID = useRecoilValue(fluidInstrumentationSettingsIDSelector)
    const fluidSafetyActions = useFluidSafetyActions()
    const [settings, setSettings] = useState<TreatmentSafetySettings | null>(null)
    const [loading, setLoading] = useState(false)
    const alert = useAlertActions()

    if (!machineName) {
        console.error('Machine name is not available')
        return
    }
    const fetchSettings = async () => {
        setLoading(true)
        await fluidSafetyActions
            .get(machineName)
            .then((res: TreatmentSafetySettings) => {
                setSettings(res)
            })
            .catch(() => {
                alert.error('Instellingen konden niet worden opgehaald')
                sleep(1500).then(() => {
                    navigate(-1)
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleUpdateSettings = async (v) => {
        await fluidSafetyActions
            .update(machineName, { ...settings, ...v })
            .then((res: TreatmentSafetySettings) => {
                setSettings(res)
                alert.success('Instellingen bijgewerkt')
            })
            .catch(() => alert.error('Instellingen konden niet worden bijgewerkt'))
    }

    const handleResetSettings = async () => {
        setLoading(true)
        await fluidSafetyActions
            .reset(machineName)
            .then((res) => alert.success(res['detail']))
            .catch((res) => alert.error(res))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        if (settings?.settings_id != socketSettingsID) {
            fetchSettings()
        }
    }, [socketSettingsID])

    if (!settings) {
        return <LinearProgress color='secondary' />
    }
    return (
        <>
            {!settings.is_default && (
                <Typography variant='h6' color='error' fontWeight={500} gutterBottom>
                    Waarschuwing: alarm instellingen zijn aangepast.
                </Typography>
            )}
            {loading && <LinearProgress color='secondary' />}
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{ mt: 4, mb: 1 }}>
                <Button variant={'contained'} onClick={handleResetSettings} color='secondary' disabled={loading}>
                    Reset defaults
                </Button>
                <SingleSprayControl />
            </Stack>
            <TreatmentSafetySetting
                primaryText='Doseervolume alarm'
                alarmEnabled={settings.dispense_volume_alarm_enable}
                secondaryText='Doseervolume'
                settingDisabled={loading}
                metricUnit='μL'
                infoText={[
                    '🇳🇱 Het doseeralarm wordt geactiveerd wanneer het doseervolume te laag of te hoog is. Als het alarm afgaat, wordt de machine stilgezet. Verleng eerst de doseringsduur als een hogere dosis nodig is. Verlaag eerst de doseerdruk als een lagere dosis nodig is. Let daarbij goed op het spuitbeeld.',
                    '🇬🇧 The dose alarm activates when the dispense volume is too low or too high. This decreases the risk of dispensing too less or too much treatment fluid. First increase the dispense duration if a higher dose is needed. First decrease the dispense pressure if a lower dose is needed.',
                ]}
                sliderMin={0}
                sliderMax={500}
                sliderStep={5}
                sliderValues={[settings.dispense_volume_limit_low, settings.dispense_volume_limit_high]}
                socketAttr='dispense_volume'
                leftIcon={<WaterDropIcon sx={{ color: blue[300] }} fontSize='small' />}
                rightIcon={<WaterDropIcon sx={{ color: blue[300] }} fontSize='large' />}
                // only company users must be able to run this
                handleToggleSwitch={() =>
                    handleUpdateSettings({ dispense_volume_alarm_enable: !settings.dispense_volume_alarm_enable })
                }
                handleUpdateSettings={(v) => {
                    handleUpdateSettings({
                        dispense_volume_limit_low: v[0],
                        dispense_volume_limit_high: v[1],
                    })
                }}
            />
            <Divider sx={{ mt: 2, mb: 1 }} />
            <TreatmentSafetySetting
                primaryText='Debietalarm'
                alarmEnabled={settings.dispense_flow_alarm_enable}
                secondaryText='Debietlimiet'
                settingDisabled={loading}
                metricUnit='mL/min'
                infoText={[
                    '🇳🇱 Het debietalarm wordt geactiveerd wanneer het debiet te hoog of te laag is. Als het alarm afgaat, wordt de machine stilgezet. Een te hoog debiet geeft risico op spetteren. Een te laag debiet vergroot de kans op een druppelende uitstroom en geeft verhoogde windgevoeligheid. Een verlaging van de doseerdruk resulteert in een lager debiet en omgekeerd.',
                    '🇬🇧 The flow rate alarm activates when the flow rate is too high or too low. A higher flow rate results in more splashing and only the dispense pressure affects the flow rate. An decrease in dispense pressure results in a lower flow rate and vice versa.',
                ]}
                sliderMin={0}
                sliderMax={300}
                sliderStep={5}
                sliderValues={[settings.dispense_flow_limit_low, settings.dispense_flow_limit_high]}
                socketAttr='dispense_flow'
                leftIcon={<CircleIcon sx={{ color: amber[300] }} fontSize='small' />}
                rightIcon={<SplashIcon sx={{ color: amber[300] }} fontSize='large' />}
                // only company users must be able to run this
                handleToggleSwitch={() =>
                    handleUpdateSettings({ dispense_flow_alarm_enable: !settings.dispense_flow_alarm_enable })
                }
                handleUpdateSettings={(v: number[]) => {
                    handleUpdateSettings({
                        dispense_flow_limit_low: v[0],
                        dispense_flow_limit_high: v[1],
                    })
                }}
            />
            <Divider sx={{ mt: 2, mb: 1 }} />
            <TreatmentSafetySetting
                primaryText='Detectiedichtheidsalarm'
                alarmEnabled={settings.detection_density_alarm_enable}
                settingDisabled={loading}
                metricUnit='detecties/meter'
                infoText={[
                    '🇳🇱 Het detectiedichtheidsalarm wordt geactiveerd wanneer de machine te veel of te weinig zieke tulpen detecteert. Als het alarm afgaat, wordt de machine stilgezet. Als de machine te weinig ziek detecteert, kunnen er zieke tulpen worden overgeslagen. Wanneer de machine te veel detecteert, kunnen gezonde tulpen worden behandeld. Verhoog de threshold waarde als de machine te veel tulpen detecteert en andersom.',
                    '🇬🇧 The detection density alarm activates when the machine sprays too much or too little tulips in a given area. When the machine sprays to little tulips there are too much sick tulips left untreated. When the machine sprays to much tulips there are too much healthy tulips that are treated. Increase the threshold when the machine sprays too much tulips and vice versa.',
                ]}
                sliderMin={0}
                sliderMax={40}
                sliderStep={1}
                sliderValues={[settings.detection_density_limit_low, settings.detection_density_limit_high]}
                socketAttr='detection_density'
                leftIcon={'🌷'}
                rightIcon={<ThreeTulipsIcon />}
                // only company users must be able to run this
                handleToggleSwitch={() =>
                    handleUpdateSettings({ detection_density_alarm_enable: !settings.detection_density_alarm_enable })
                }
                handleUpdateSettings={(v: number[]) => {
                    handleUpdateSettings({
                        detection_density_limit_low: v[0],
                        detection_density_limit_high: v[1],
                    })
                }}
            />
            <Divider sx={{ mt: 2, mb: 1 }} />
            <Stack spacing={1}>
                <AlarmToggle
                    primaryText='Tankniveau alarm'
                    alarmEnabled={settings.tank_level_alarm_enable}
                    settingDisabled={loading}
                    infoText={[
                        '🇳🇱 Het tankniveau-alarm wordt geactiveerd wanneer het vloeistofniveau in de tank te laag is. Als het alarm afgaat, wordt de machine stilgezet. Bij een laag vloeistofniveau kan lucht en schuim aangezogen worden, wat tot nadruppelen en sproeien kan leiden.',
                        '🇬🇧 The tank level alarm activates when the tank level is too low. A low tank level results in air and foam in the fluid system which results in dripping for a short time after the dispenser is closed.',
                    ]}
                    handleToggleSwitch={() =>
                        handleUpdateSettings({ tank_level_alarm_enable: !settings.tank_level_alarm_enable })
                    }
                />
                <AlarmToggle
                    primaryText='Lek detectie alarm'
                    alarmEnabled={settings.fluid_leak_alarm_enable}
                    settingDisabled={loading}
                    infoText={[
                        '🇳🇱 Het lekdetectie alarm zet de machine stil wanneer er een vloeistof lek in de behandelrobot wordt gedetecteerd. Bovendien wordt de druk van de vloeistof tank afgeblazen. Lekdetectie werkt voor lekken vanaf ca. 5 mL/min.',
                        '🇬🇧 The leak detection alarm will stop the machine and depressurize the fluid tank if a fluid leak is detected.  Leak detection can detect leaks rates down to approximately 5 mL/min.',
                    ]}
                    handleToggleSwitch={() =>
                        handleUpdateSettings({ fluid_leak_alarm_enable: !settings.fluid_leak_alarm_enable })
                    }
                />
                <AlarmToggle
                    primaryText='Luchtbel detectie alarm'
                    alarmEnabled={settings.air_in_line_alarm_enable}
                    settingDisabled={loading}
                    infoText={[
                        `🇳🇱 Het luchtbel detectie alarm zet de machine als er langere tijd luchtbellen detecteeerd worden in de vloeistof leiding. Luchtbellen kunnen leiden tot spetteren en nadruppelen.
                    Luchtbellen kunnen voorkomen bij:
                       -laag tankniveau
                       -onvoldoende doorspoelen 
                       -schuimende vloeistoffen`,
                        `🇬🇧 The bubble detection alarm will stop the machine if air bubbles are detected in the fluid line. Bubbles can cause dripping and spray atomization.  Bubbles can be formed by:
                        -low fluid levels
                        -insufficient fluid line flushing
                        -foaming fluids`,
                    ]}
                    handleToggleSwitch={() =>
                        handleUpdateSettings({ air_in_line_alarm_enable: !settings.air_in_line_alarm_enable })
                    }
                />
                <AlarmToggle
                    primaryText='Perslucht alarm'
                    alarmEnabled={settings.supply_pressure_alarm_enable}
                    settingDisabled={loading}
                    infoText={[
                        '🇳🇱 Het perslucht alarm zet de machine stil als de compressor uitgangsdruk te laag of the hoog is. De nominale druk is 5 bar.  Bij een te lage druk werk de behandelkop niet. Bij een te hoge druk kan de compressor overbelast raken.',
                        '🇬🇧 The supply pressure alarm will stop the machine if the compressor output deviates too far from the nominal value (5 bar).  A low pressure will prevent the dispense head from operating. A high ouput pressure can lead to compressor damage.',
                    ]}
                    handleToggleSwitch={() =>
                        handleUpdateSettings({ supply_pressure_alarm_enable: !settings.supply_pressure_alarm_enable })
                    }
                />
                <AlarmToggle
                    primaryText='Z cilinder alarm'
                    alarmEnabled={settings.z_cylinder_alarm_enable}
                    settingDisabled={loading}
                    infoText={[
                        '🇳🇱 Het Z cilinder alarm zet de machine stil as de doseernaald te snel of te langzaam op en neer gaat, of niet functioneert. Bij een te snelle opwaartse beweging kunnen druppels opwaarts gelanceerd worden. Bij een te langzame beweging kan de cilinder krom gereden worden.',
                        '🇬🇧 The Z cylinder alarm stops the machine if the movement of the dosing needle is too fast or too slow.  A fast upward movement can launch droplets away from the correct plant.  A slow upward movement kan cause damage to the Z cylinder.',
                    ]}
                    handleToggleSwitch={() =>
                        handleUpdateSettings({ z_cylinder_alarm_enable: !settings.z_cylinder_alarm_enable })
                    }
                />
                <AlarmToggle
                    primaryText='Kantel alarm'
                    alarmEnabled={settings.machine_tilt_alarm_enable}
                    settingDisabled={loading}
                    infoText={[
                        '🇳🇱 Het kantel alarm detecteert wanneer de machine een te hoge hellingshoek maakt tijdens bedrijf. Als dit gebeurt, wordt de machine stilgezet en wordt de compressor afgeblazen, om te voorkomen dat er vloeistof in de omgeving wordt gelekt.',
                        '🇬🇧 The tilt alarm detects the machine tilt angle. When a high tilt angle is detected, the machine is stopped and the compressor is depressurized.',
                    ]}
                    handleToggleSwitch={() =>
                        handleUpdateSettings({ machine_tilt_alarm_enable: !settings.machine_tilt_alarm_enable })
                    }
                />
            </Stack>
        </>
    )
}
