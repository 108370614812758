import { atom } from 'recoil'

interface AlertState {
    message: string;
    type: 'info' | 'success' | 'warning' | 'error';
}

const alertAtom = atom<AlertState | null>({
    key: 'alert',
    default: null,
})

export { alertAtom }
