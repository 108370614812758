import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

interface MSULinearProgressBarProps {
    value: string
}
export default function MSULinearProgressBar({ value }: MSULinearProgressBarProps) {
    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress />
                    <Typography variant='body2' color='text.secondary'>
                        <span dangerouslySetInnerHTML={{ __html: value }} />
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}
