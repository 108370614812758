import { useFetchWrapper } from '../_helpers'
import { SurveyResponse } from '../survey/types'
export { useSurveyActions }

function useSurveyActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`
    const fetchWrapper = useFetchWrapper()

    return {
        getAll,
        toCSV,
        create,
    }

    async function getAll(): Promise<SurveyResponse[] | []> {
        return fetchWrapper.get(`${baseUrl}/survey_responses`)
    }

    async function toCSV(year: string) {
        return fetchWrapper.get(`${baseUrl}/survey_responses/${year}/to_csv`)
    }

    async function create(surveyAnswers: object): Promise<SurveyResponse> {
        return fetchWrapper.post(`${baseUrl}/survey_responses`, surveyAnswers)
    }
}
