import React from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { useTaskActions, useAlertActions, useTaskRequestActions } from '../../_actions'
import { CreateTaskButton, CultivarList, BedExtensionSlider, RadiusSlider } from '..'
import RepeatOneIcon from '@mui/icons-material/RepeatOne'
import RepeatOneOnIcon from '@mui/icons-material/RepeatOneOn'
import {
    taskQuery,
    h2lFieldJsonAtom,
    taskBarOpenAtom,
    taskRequestsAtom,
    taskManagerAtom,
    drawSettingsAtom,
    taskSettingsState,
} from '../../_state'
import { Box, Stack, IconButton, Typography, Chip, Button } from '@mui/material'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { H2LFieldJson, Task } from '../../types'
import UserService from '../../services/UserService'

interface CultivarsListMobileProps {
    closeIcon: React.ReactNode
}

export const CultivarsListMobile = ({ closeIcon }: CultivarsListMobileProps) => {
    // Actions
    const alertActions = useAlertActions()
    const taskRequestActions = useTaskRequestActions()
    const taskActions = useTaskActions()

    // State
    const [btnLoading, setBtnLoading] = React.useState(false)

    const setTaskSettings = useSetRecoilState(taskSettingsState)
    const { bed_extension, radius, method, turn, direction, repeat } = useRecoilValue(taskSettingsState)
    const setTaskManager = useSetRecoilState(taskManagerAtom)

    // Recoil
    const setTask = useSetRecoilState(taskQuery)
    const h2lFieldJson = useRecoilValue<H2LFieldJson | null>(h2lFieldJsonAtom)
    const setTaskBarOpen = useSetRecoilState(taskBarOpenAtom)
    const setTaskRequests = useSetRecoilState(taskRequestsAtom)
    const setDrawSettings = useSetRecoilState(drawSettingsAtom)

    React.useEffect(() => {
        setTaskBarOpen(false)
        return () => setTaskBarOpen(true)
    }, [])

    const handleCreateTask = (selectedBedIDs: number[]) => {
        if (selectedBedIDs && h2lFieldJson) {
            setBtnLoading(true)
            return taskActions
                .create({
                    crop_id: h2lFieldJson.crop_id,
                    tulip_bed_ids: selectedBedIDs,
                    bed_extension: bed_extension,
                    radius: radius,
                    method: method,
                    turn: turn,
                    direction: direction,
                    repeat: repeat,
                })
                .then((task: Task) => {
                    setTask({ data: task, isLoading: false })
                    alertActions.success('Task created successfully')
                    taskRequestActions.getByCompany().then((taskRequests) => {
                        setTaskRequests(taskRequests)
                        setBtnLoading(false)
                        setTaskManager('select')
                    })
                })
                .catch(() => {
                    alertActions.error('Error creating task')
                    setBtnLoading(false)
                })
        }
    }

    const render = () => {
        if (h2lFieldJson === null) return <></>
        return (
            <>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex' }}>
                        <IconButton onClick={() => setTaskManager('select')} sx={{ cursor: 'pointer' }} size='large'>
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography variant='h6' sx={{ mt: 0.5 }}>
                            {`Taak maken voor ${h2lFieldJson.name}`}
                        </Typography>
                    </Box>
                    {closeIcon}
                </Box>
                <Stack spacing={1}>
                    <Stack direction={'row'} display={'flex'} justifyContent={'center'} spacing={0.5}>
                        <Stack spacing={1}>
                            <Chip
                                label='planted-area'
                                color={method === 'planted-area' ? 'secondary' : 'primary'}
                                onClick={() => {
                                    setTaskSettings((prev) => ({
                                        ...prev,
                                        method: 'planted-area',
                                    }))
                                    setDrawSettings({ plantedArea: true })
                                }}
                            />
                            <Chip
                                label='bed-extension'
                                color={method == 'bed-extension' ? 'secondary' : 'primary'}
                                onClick={() => {
                                    setTaskSettings((prev) => ({
                                        ...prev,
                                        method: 'bed-extension',
                                    }))
                                    setDrawSettings({ plantedArea: false })
                                }}
                            />
                        </Stack>
                        <Stack spacing={1}>
                            <Chip
                                label='three-point'
                                color={turn === 'three-point' ? 'secondary' : 'primary'}
                                onClick={() => {
                                    setTaskSettings((prev) => ({
                                        ...prev,
                                        turn: 'three-point',
                                    }))
                                    setDrawSettings({ plantedArea: true })
                                }}
                            />
                            <Chip
                                label='omega'
                                color={turn == 'omega' ? 'secondary' : 'primary'}
                                onClick={() => {
                                    setTaskSettings((prev) => ({
                                        ...prev,
                                        turn: 'omega',
                                    }))
                                    setDrawSettings({ plantedArea: false })
                                }}
                            />
                        </Stack>
                        <Button
                            onClick={() => {
                                setTaskSettings((prev) => ({
                                    ...prev,
                                    direction: prev.direction === 'down' ? 'up' : 'down',
                                }))
                            }}
                        >
                            <SwapVertIcon fontSize='large' color={'secondary'} sx={{}} />
                        </Button>
                        {UserService.hasRole(['tester']) && (
                            <Button
                                onClick={() => {
                                    setTaskSettings((prev) => ({
                                        ...prev,
                                        repeat: prev.repeat === 0 ? 1 : 0,
                                    }))
                                }}
                            >
                                {repeat === 0 ? (
                                    <RepeatOneIcon fontSize='large' color='secondary' />
                                ) : (
                                    <RepeatOneOnIcon fontSize='large' color='secondary' />
                                )}
                            </Button>
                        )}
                    </Stack>
                    <CreateTaskButton handleCreateTask={handleCreateTask} btnLoading={btnLoading} />
                </Stack>
                {method === 'bed-extension' && <BedExtensionSlider />}
                {/*{UserService.hasRole(['admin']) && <RadiusSlider />}*/}
                <CultivarList />
            </>
        )
    }
    return render()
}
