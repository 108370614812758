import React, { useState } from 'react'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Document, Page, pdfjs } from 'react-pdf'
import LinearProgress from '@mui/material/LinearProgress'
import { Box } from '@mui/material'

export { PdfView }

interface PdfViewProps {
    pdf: string
}

function PdfView(props: PdfViewProps) {
    const [numPages, setNumPages] = useState<number | null>(null)

    function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
        setNumPages(numPages)
    }

    const { pdf } = props

    // Load globally else is not fetching from the correct folder path
    pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.js`

    const render = () => {
        return (
            <Box sx={{ maxWidth: '100%' }}>
                <Document
                    file={pdf}
                    options={{
                        workerSrc: `${process.env.PUBLIC_URL}/pdf.worker.js`,
                        maxImageSize: -1,
                        useSystemFonts: true,
                    }}
                    onLoadSuccess={onDocumentLoadSuccess}
                    loading={<LinearProgress color='success' />}
                >
                    {Array.from(new Array(numPages), (el, index) => (
                        <Page key={`page_${index + 1}`} pageNumber={index + 1} width={window.innerWidth - 29} />
                    ))}
                </Document>
            </Box>
        )
    }
    return render()
}
