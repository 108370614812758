import { Stack } from '@mui/material'

export const ThreeTulipsIcon: React.FC = () => (
    <div>
        <Stack direction={'row'}>
            <span role='img' style={{ fontSize: '1em' }}>
                🌷
            </span>
            <span role='img' style={{ fontSize: '1em' }}>
                🌷
            </span>
        </Stack>
        <div
            role='img'
            style={{
                marginLeft: '10px',
                marginTop: '-10px',
                fontSize: '1em',
            }}
        >
            🌷
        </div>
    </div>
)
