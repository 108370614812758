import React, { useState } from 'react'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import CheckIcon from '@mui/icons-material/Check'
import NotInterestedIcon from '@mui/icons-material/NotInterested'

const StandaloneToggleButton = () => {
    const [selected, setSelected] = useState(false)

    const handleToggle = () => {
        setSelected(!selected)
    }

    return (
        <ToggleButtonGroup value={selected ? 'checked' : 'notChecked'} exclusive onChange={handleToggle}>
            <ToggleButton value='checked' aria-label='checked'>
                {selected ? <CheckIcon /> : <NotInterestedIcon />}
            </ToggleButton>
        </ToggleButtonGroup>
    )
}

export { StandaloneToggleButton }
