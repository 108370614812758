// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as React from 'react'
import { Snackbar } from '@mui/material'
import Stack from '@mui/material/Stack'
import MuiAlert from '@mui/material/Alert'
export { SnackBarMessage }

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

interface SnackBarMessageProps {
    message: string
    severity: string
}

function SnackBarMessage({ message, severity }: SnackBarMessageProps) {
    const [state, setState] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    })
    const { vertical, horizontal } = state
    const handleClose = () => {
        setState({ ...state, open: false })
    }

    React.useEffect(() => {
        if (alert) {
            setState({ ...state, open: true })
        }
        return () => {
            setState(null)
        }
    }, [message, severity])

    const render = (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                key={vertical + horizontal}
                open={state.open}
                autoHideDuration={5000}
                onClose={handleClose}
                sx={{ top: '70px' }}
            >
                <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </Stack>
    )
    return render
}
