import React from 'react'
import { fluidInstrumentationAlarmSelector } from '../../../_state'
import { useRecoilValue } from 'recoil'
import { FluidSafetyIcon } from '../../icons/FluidSafetyIcon'
import { IconButton } from '@mui/material'
import { useNavigate, useResolvedPath } from 'react-router-dom'

export const TreatmentDoseAlert = () => {
    const machineDoseAlarmMsg = useRecoilValue(fluidInstrumentationAlarmSelector)
    const navigate = useNavigate()
    const url = useResolvedPath('').pathname

    if (Array.isArray(machineDoseAlarmMsg) && machineDoseAlarmMsg.length > 0) {
        return (
            <IconButton
                disableRipple
                sx={{
                    borderRadius: '50%',
                    transform: 'scale(0.6)',
                    animation: 'pulse-red 1s infinite',
                    cursor: 'pointer',
                }}
                onClick={() => navigate(`${url}/fluid-safety`)}
            >
                <FluidSafetyIcon color='error' />
            </IconButton>
        )
    }
}
