import { useState, useEffect } from 'react'
import { ws, socket } from '.'
import UserService from '../services/UserService'
import { FleetStatusEntry } from '../types'

export const useFleetState = (): { fleetState: Record<string, FleetStatusEntry> } => {
    const [fleetState, setFleetState] = useState<Record<string, FleetStatusEntry>>({})
    const [reconnect, setReconnect] = useState(false)

    const setupSocketListeners = () => {
        if (socket) {
            socket.on('fleetStatus', handleFleetStatus)
            socket.on('UNAUTHORIZED', handleUnauthorized)
            socket.on('TRANSFERED', handleTransfered)
        }
    }

    const handleFleetStatus = (data) => setFleetState((prev) => ({ ...prev, ...data }))
    const handleUnauthorized = () => UserService.doLogout()
    const handleTransfered = () => setReconnect(true)

    useEffect(() => {
        ws('fss', 'fleet_status')
        setupSocketListeners()

        return () => {
            if (socket) {
                socket.off('fleetStatus', handleFleetStatus)
                socket.off('UNAUTHORIZED', handleUnauthorized)
                socket.off('TRANSFERED', handleTransfered)
                socket.close()
            }
            setReconnect(false)
        }
    }, [reconnect])

    return { fleetState }
}
