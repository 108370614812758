import { useRecoilValue } from 'recoil'
import { machineControlAtom, treatmentCounterSelector, treatmentsPer100mSelector } from '../../_state'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

export { TreatmentCounterIndicator }
function TreatmentCounterIndicator() {
    const treatmentCounter = useRecoilValue(treatmentCounterSelector)
    const treatmentsPer100m = useRecoilValue(treatmentsPer100mSelector)
    const machineControl = useRecoilValue(machineControlAtom)

    if (!machineControl || !treatmentsPer100m) return <>Data not available</>

    let indicator = <></>
    if (machineControl?.state > 0) {
        indicator = (
            <Grid container>
                🌷
                <Typography variant='body2' sx={{ mr: 1 }}>
                    {treatmentCounter}
                </Typography>
                <Typography variant='body2'>{treatmentsPer100m} / 100m</Typography>
            </Grid>
        )
    }

    return <>{indicator}</>
}
