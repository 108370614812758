import { useFetchWrapper } from '../_helpers'

export { useCultivarActions }

function useCultivarActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`
    const fetchWrapper = useFetchWrapper()

    return {
        getCultivars,
    }

    function getCultivars(): Promise<string[] | []> {
        return fetchWrapper.get(`${baseUrl}/cultivars`)
    }
}
