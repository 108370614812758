import { useRecoilState, useRecoilValue } from 'recoil'
import { Map } from '..'
import { machineTaskStatus, taskManagerAtom } from '../../_state'
import { TaskManager } from './TaskManager'
import { Box, Fab } from '@mui/material'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import { styles } from '../../styles'
import { CropTask } from '../../gmap/CropTask'
import { RobotIcon } from '../RobotIcon'

export const NavigationTab = () => {
    const [taskManagerOpen, setTaskManager] = useRecoilState(taskManagerAtom)
    const machineTaskState = useRecoilValue(machineTaskStatus)
    return (
        <Box sx={[styles.NavigationTab.OuterBox]}>
            <Box sx={[styles.NavigationTab.MapBox]}>
                <Map>
                    <CropTask />
                    <RobotIcon />
                </Map>
                {!taskManagerOpen && (
                    <Fab
                        variant='extended'
                        onClick={() => setTaskManager('select')}
                        disabled={machineTaskState === 'stop'}
                        sx={[styles.NavigationTab.Fab]}
                    >
                        <FormatListBulletedIcon sx={{ m: 1 }} />
                        {taskManagerOpen ? 'Close' : 'Tasks'}
                    </Fab>
                )}
            </Box>
            {taskManagerOpen && (
                <Box sx={[styles.NavigationTab.TaskManagerBox]}>
                    <TaskManager />
                </Box>
            )}
        </Box>
    )
}
