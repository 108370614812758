import { Grid, Avatar } from '@mui/material'
import { useRecoilValue } from 'recoil'
import { useTheme } from '@mui/material/styles'
import { xaviersStatusSelector } from '../../_state'
import { VideoIcon } from '../icons/VideoIcon'
import { Icon } from '../Icon'

interface GridItemProps {
    state: number
}
export const CameraStatus = () => {
    const theme = useTheme()
    const xaviersStatus = useRecoilValue(xaviersStatusSelector)

    const GridItem = ({ state }: GridItemProps) => (
        <Icon
            color={
                state === 0 || state === null || state === undefined
                    ? theme.palette.error.main
                    : state === 3
                    ? theme.palette.success.main
                    : theme.palette.secondary.main
            }
            loading={state === 1}
            IconComponent={VideoIcon}
        />
    )

    return (
        <Grid container>
            {Object.keys(xaviersStatus).map((xavier) => (
                <Grid key={xavier} item xs={3}>
                    <Avatar>
                        <GridItem state={xaviersStatus[xavier]} />
                    </Avatar>
                    {'Camera' + xavier.slice(-1)}
                </Grid>
            ))}
        </Grid>
    )
}
