import React, { useState, SyntheticEvent, ReactNode } from 'react'
import TaskBar from './TaskBar'
import IconTabs from './IconTabs'
import TreatmentTab from './TreatmentTab'
import CamerasTab from './CamerasTab'
import { NavigationTab } from './NavigationTab'
import Box from '@mui/material/Box'
import { styles } from '../../styles'
interface TabPanelProps {
    children?: ReactNode
    index: number
    value: number
}
export const TabManager = () => {
    const [selectedTab, setSelectedTab] = useState(0)

    const handleChangeTabIndex = (event: SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue)
    }

    const TabPanel = (props: TabPanelProps) => {
        const { children, value, index, ...other } = props

        return (
            <Box
                sx={[styles.TabPanelBox]}
                role='tabpanel'
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && children}
            </Box>
        )
    }

    const renderBody = (value: number) => {
        if (selectedTab === 0) {
            return (
                <TabPanel value={value} index={0}>
                    <NavigationTab />
                </TabPanel>
            )
        } else if (selectedTab === 1) {
            return (
                <TabPanel value={value} index={1}>
                    <TreatmentTab />
                </TabPanel>
            )
        } else if (selectedTab === 2) {
            return (
                <TabPanel value={value} index={2}>
                    <CamerasTab />
                </TabPanel>
            )
        }
    }
    return (
        <>
            <Box sx={{ flex: 1, position: 'relative' }}>{renderBody(selectedTab)}</Box>
            <Box>
                <TaskBar />
                <IconTabs value={selectedTab} handleChangeTabIndex={handleChangeTabIndex} />
            </Box>
        </>
    )
}
