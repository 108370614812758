import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import { ReactComponent as Icon } from '../../assets/images/syringe.svg'
export { SyringeIcon }
function SyringeIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <Icon />
        </SvgIcon>
    )
}
