import React, { useState } from 'react'
import ScrollDialog from '../_components/ScrollDialog'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorIcon from '@mui/icons-material/Error'
import { formatToDutchTimezone } from '../_helpers'

interface MSUInstallHistoryTableProps {
    data: Array<{
        created_at: string
        stdout: any
        stderr: any
        target_version: string
        name: string
        surname: string
        started_at: string
        ended_at: string
    }>
}
interface STDStatusProps {
    stdout: any
    stderr: any
}

const StdStatus = ({ stdout, stderr }: STDStatusProps) => {
    const [open, setOpen] = useState(false)
    const handleClose = () => setOpen(false)
    if (Object.keys(stderr).length !== 0) {
        return (
            <>
                <IconButton color='error' onClick={() => setOpen(true)} size='large'>
                    <ErrorIcon />
                </IconButton>
                {open && (
                    <ScrollDialog title='Status: stderr' handleClose={handleClose}>
                        <pre>{JSON.stringify(stderr, null, 2)}</pre>
                    </ScrollDialog>
                )}
            </>
        )
    } else {
        return (
            <>
                <IconButton color='success' onClick={() => setOpen(true)} size='large'>
                    <CheckCircleOutlineIcon />
                </IconButton>
                {open && (
                    <ScrollDialog title='Status: stdout' handleClose={handleClose}>
                        <pre>{JSON.stringify(stdout, null, 2)}</pre>
                    </ScrollDialog>
                )}
            </>
        )
    }
}

export const MSUInstallHistoryTable = ({ data }: MSUInstallHistoryTableProps) => (
    <TableContainer component={Paper}>
        <Table sx={{ minWidth: 100 }} size='small' aria-label='simple table'>
            <TableHead>
                <TableRow>
                    {['status', 'target_version', 'started_by', 'started_at', 'ended_at'].map((col) => {
                        return (
                            <TableCell key={col} align='right'>
                                {col}
                            </TableCell>
                        )
                    })}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((row) => (
                    <TableRow key={row.created_at} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell>
                            <StdStatus stdout={row.stdout} stderr={row.stderr} />
                        </TableCell>
                        <TableCell align='right'>{row.target_version}</TableCell>
                        <TableCell align='right'>
                            {row.name} {row.surname}
                        </TableCell>
                        <TableCell align='right'>{formatToDutchTimezone(row.started_at)}</TableCell>
                        <TableCell align='right'>{formatToDutchTimezone(row.ended_at)}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
)
