import React from 'react'
import { useRecoilValue } from 'recoil'
import { Box, Divider, Button, List, Typography, ListItemButton, ListItemText, Stack } from '@mui/material'
import SpaIcon from '@mui/icons-material/Spa'
import { useGroupIDsSelector } from '../_helpers'
import { cultivarListSelector, selectedGroupIDsAtom } from '../_state'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { CultivarGroup } from '../types'
import { useNavigate, useResolvedPath } from 'react-router'
export { CultivarList }

interface IconTooltipProps {
    cGroup: CultivarGroup
}

const IconTooltip = ({ cGroup }: IconTooltipProps): JSX.Element | null => {
    if (cGroup?.cultivar?.toLowerCase()) {
        return (
            <CheckCircleOutlineIcon
                sx={{
                    position: 'absolute',
                    top: -2,
                    left: 22,
                    fontSize: 18,
                    color: 'green',
                }}
            />
        )
    } else {
        return (
            <ErrorOutlineIcon
                sx={{
                    position: 'absolute',
                    top: -2,
                    left: 22,
                    fontSize: 18,
                    color: 'red',
                }}
            />
        )
    }
}

interface MatchinHelpProps {
    cGroup: CultivarGroup
    onClick: () => void
}

const MatchingHelp = ({ cGroup, onClick }: MatchinHelpProps): JSX.Element | null => {
    const navigate = useNavigate()
    const url = useResolvedPath('').pathname

    const YesNoRedirect = () => (
        <Stack direction={'row'} spacing={1} justifyContent={'end'} sx={{ mt: 1 }}>
            <Button
                size='small'
                variant='contained'
                color='secondary'
                onClick={(event) => {
                    onClick()
                    event.stopPropagation()
                }}
            >
                No
            </Button>
            <Button
                onClick={() => navigate(url.replace('/view', '/edit/cultivar-matching'))}
                variant='contained'
                size='small'
            >
                Yes
            </Button>
        </Stack>
    )

    if (cGroup?.cultivar?.toLowerCase()) {
        return (
            <Stack>
                <Stack direction={'row'} spacing={1}>
                    <CheckCircleOutlineIcon
                        sx={{
                            color: 'green',
                        }}
                    />
                    <Stack>
                        <Typography variant='caption'>{`Cultivar is recognized as ${cGroup.cultivar}.`}</Typography>
                        <Typography variant='caption'>{'Do you want to change it?'}</Typography>
                    </Stack>
                </Stack>
                <YesNoRedirect />
            </Stack>
        )
    } else {
        return (
            <Stack>
                <Stack direction={'row'} spacing={1}>
                    <ErrorOutlineIcon
                        sx={{
                            color: 'red',
                        }}
                    />
                    <Stack>
                        <Typography variant='caption'>{`No cultivar named ${cGroup.name} found.`}</Typography>
                        <Typography variant='caption'>{'Do you want to choose the cultivar name?'}</Typography>
                    </Stack>
                </Stack>
                <YesNoRedirect />
            </Stack>
        )
    }
}

function CultivarList() {
    const [clickedItem, setClickedItem] = React.useState<number | null>(null)

    const groupIDsSelector = useGroupIDsSelector()
    const cultivarList = useRecoilValue(cultivarListSelector)
    const selectedGroupIDs = useRecoilValue(selectedGroupIDsAtom)

    const handleItemClick = (group_id: number) => {
        groupIDsSelector.add(group_id)
    }
    const handleTulipClick = (group_id: number) => {
        if (clickedItem === group_id) {
            setClickedItem(null)
        } else {
            setClickedItem(group_id)
        }
    }

    return (
        <Box
            id='tulip-bed-list-box'
            sx={{
                width: '100%',
            }}
        >
            {cultivarList !== null ? (
                <List aria-label='secondary mailbox folder'>
                    {Object.keys(cultivarList).map((index) => (
                        <div key={index}>
                            {clickedItem === cultivarList[index].group_id ? (
                                <ListItemButton alignItems='flex-start'>
                                    <MatchingHelp
                                        cGroup={cultivarList[index]}
                                        onClick={() => handleTulipClick(cultivarList[index].group_id)}
                                    />
                                </ListItemButton>
                            ) : (
                                <ListItemButton
                                    sx={{
                                        '&.Mui-selected': {
                                            backgroundColor: '#1976d291',
                                            '&:hover': {
                                                backgroundColor: '#1976d291',
                                            },
                                        },
                                    }}
                                    onClick={() => handleItemClick(cultivarList[index].group_id)}
                                    selected={selectedGroupIDs.has(cultivarList[index].group_id)}
                                    alignItems='flex-start'
                                >
                                    <Stack direction={'row'} style={{ position: 'relative' }}>
                                        <Stack
                                            justifyContent={'center'}
                                            sx={{
                                                mr: 0.5,
                                            }}
                                            onClick={(event) => {
                                                event.stopPropagation()
                                                handleTulipClick(cultivarList[index].group_id)
                                            }}
                                        >
                                            <Button sx={{ minWidth: '38px' }}>
                                                <SpaIcon sx={{ color: cultivarList[index].strokeColor }} />
                                                <IconTooltip cGroup={cultivarList[index]} />
                                            </Button>
                                        </Stack>
                                        <Divider orientation='vertical' variant='middle' flexItem />
                                        <Box sx={{ ml: 1 }}>
                                            {cultivarList[index].cultivar ? (
                                                <ListItemText
                                                    primary={`${cultivarList[index].cultivar} 📏 ${cultivarList[index].size}`}
                                                    secondary={`${cultivarList[index].name}`}
                                                />
                                            ) : (
                                                <ListItemText
                                                    primary={`${cultivarList[index].name} 📏 ${cultivarList[index].size}`}
                                                    sx={{ color: 'gray' }}
                                                />
                                            )}
                                        </Box>
                                    </Stack>
                                </ListItemButton>
                            )}
                        </div>
                    ))}
                </List>
            ) : (
                <List component='nav' aria-label='secondary mailbox folder'>
                    <ListItemButton>
                        <ListItemText primary='No tulip beds found for this field.' />
                    </ListItemButton>
                </List>
            )}
        </Box>
    )
}
