import { useFetchWrapper } from '../_helpers'
import { NotificationMarkAsReadRequest } from '../types'
export { useNotificationActions }

function useNotificationActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`
    const fetchWrapper = useFetchWrapper()

    return {
        markAsRead,
    }

    function markAsRead(unread: Set<number>) {
        const markAsReadRequest: NotificationMarkAsReadRequest = { ids: Array.from(unread) }
        return fetchWrapper.put(`${baseUrl}/notifications`, markAsReadRequest)
    }
}
