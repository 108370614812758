import React from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { useTaskActions, useAlertActions } from '../_actions'
import { CreateTaskButton, CultivarList, BedExtensionSlider, RadiusSlider, FieldMenu } from '.'
import { taskQuery, h2lFieldJsonAtom, drawSettingsAtom, taskSettingsState } from '../_state'
import { Box, Drawer, Toolbar, Typography, Divider, Stack, Chip } from '@mui/material'
import { H2LFieldJson, Task } from '../types'
import UserService from '../services/UserService'

export { CultivarsDrawer }

function CultivarsDrawer() {
    const h2lFieldJson = useRecoilValue<H2LFieldJson | null>(h2lFieldJsonAtom)
    const setDrawSettings = useSetRecoilState(drawSettingsAtom)

    const setTaskSettings = useSetRecoilState(taskSettingsState)
    const { bed_extension, radius, method, turn, direction, repeat } = useRecoilValue(taskSettingsState)

    const taskActions = useTaskActions()
    const alertActions = useAlertActions()
    const [btnLoading, setBtnLoading] = React.useState(false)
    const setTask = useSetRecoilState(taskQuery)

    React.useEffect(() => {
        setDrawSettings({ plantedArea: true })
        return () => {
            setDrawSettings({ plantedArea: false })
        }
    }, [])

    const handleCreateTask = (selectedBedIDs: number[]) => {
        if (selectedBedIDs && h2lFieldJson) {
            setBtnLoading(true)
            return taskActions
                .create({
                    crop_id: h2lFieldJson.crop_id,
                    tulip_bed_ids: selectedBedIDs,
                    bed_extension: bed_extension,
                    radius: radius,
                    method: method,
                    turn: turn,
                    direction: direction,
                    repeat: repeat,
                })
                .then((res: Task) => {
                    setTask({ data: res, isLoading: false })
                    alertActions.success('Task created successfully')
                    setBtnLoading(false)
                })
                .catch(() => {
                    alertActions.error('Error creating task')
                    setBtnLoading(false)
                })
        }
        console.log('No beds selected or data is missing')
    }

    const render = () => {
        if (h2lFieldJson === null) return <></>
        return (
            <>
                <Box sx={{ position: 'relative' }}>
                    <Drawer
                        sx={{
                            width: 240,
                            flexShrink: 0,
                            '& .MuiDrawer-paper': {
                                width: 240,
                                boxSizing: 'border-box',
                            },
                        }}
                        variant='persistent'
                        open={true}
                    >
                        <Toolbar />
                        <Box sx={{ m: 2 }}>
                            <Stack direction={'row'}>
                                <Typography
                                    variant='h5'
                                    gutterBottom
                                    component='div'
                                    sx={{
                                        overflow: 'hidden',
                                    }}
                                >
                                    {`${h2lFieldJson.name} ${h2lFieldJson.test_pass ? '✅ ' : '❌'} `}
                                </Typography>
                                <FieldMenu
                                    fieldID={h2lFieldJson.field_id}
                                    cropID={h2lFieldJson.crop_id}
                                    fieldName={h2lFieldJson.name}
                                    fieldLat={h2lFieldJson.center.coordinates[1]}
                                    fieldLon={h2lFieldJson.center.coordinates[0]}
                                />
                            </Stack>
                        </Box>
                        <Divider variant='middle' />
                        <Box sx={{ mt: 1, overflow: 'auto', height: '100%' }}>
                            <CultivarList />
                        </Box>
                        <Stack spacing={0.2}>
                            <Stack direction={'row'} spacing={1}>
                                <Chip
                                    label='three-point'
                                    color={turn === 'three-point' ? 'primary' : 'secondary'}
                                    onClick={() => {
                                        setTaskSettings((prev) => ({
                                            ...prev,
                                            turn: 'three-point',
                                            radius: 2.5,
                                        }))
                                    }}
                                />
                                <Chip
                                    label='omega'
                                    color={turn === 'omega' ? 'primary' : 'secondary'}
                                    onClick={() => {
                                        setTaskSettings((prev) => ({
                                            ...prev,
                                            turn: 'omega',
                                            radius: 1.5,
                                        }))
                                    }}
                                />
                            </Stack>
                            <Stack direction={'row'} spacing={1}>
                                <Chip
                                    label='planted-area'
                                    color={method === 'planted-area' ? 'primary' : 'secondary'}
                                    onClick={() => {
                                        setTaskSettings((prev) => ({
                                            ...prev,
                                            method: 'planted-area',
                                        }))
                                        setDrawSettings({ plantedArea: true })
                                    }}
                                />
                                <Chip
                                    label='bed-extension'
                                    color={method === 'bed-extension' ? 'primary' : 'secondary'}
                                    onClick={() => {
                                        setTaskSettings((prev) => ({
                                            ...prev,
                                            method: 'bed-extension',
                                        }))
                                        setDrawSettings({ plantedArea: false })
                                    }}
                                />
                            </Stack>
                            {method === 'bed-extension' && <BedExtensionSlider />}
                            {UserService.hasRole(['admin']) && <RadiusSlider />}
                            <CreateTaskButton handleCreateTask={handleCreateTask} btnLoading={btnLoading} />
                        </Stack>
                    </Drawer>
                </Box>
            </>
        )
    }
    return render()
}
