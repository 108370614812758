import { useFetchWrapper } from '../_helpers'
import { useRecoilValue } from 'recoil'
import { machineNameAtom } from '../_state'
import { MachineSettings } from '../types'

export { useMachineSettingsActions }

function useMachineSettingsActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`
    const fetchWrapper = useFetchWrapper()

    const machineName = useRecoilValue(machineNameAtom)
    return {
        getLastMachineSettingsByMachineName,
    }

    function getLastMachineSettingsByMachineName(): Promise<MachineSettings> {
        return fetchWrapper.get(`${baseUrl}/machine_settings/${machineName}/last`)
    }
}
