import React, { useCallback, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { clusteringTreatmentModeSelector, machineSocketAtom } from '../../_state'
import { Box, Stack, Chip, Typography, CircularProgress } from '@mui/material'
import { DensityThresholdSlider } from './DensityThresholdSlider'
import { ScoreThresholdSlider } from './ScoreThresholdSlider'
import { InfoPopover } from '../InfoPopover'
import { TreatmentMode } from '../../types'

export function ThresholdSetting(): JSX.Element {
    const socket = useRecoilValue(machineSocketAtom)
    const mode = useRecoilValue(clusteringTreatmentModeSelector)
    const [isLoading, setIsLoading] = useState(false)

    if (!socket) {
        console.error('Socket not available')
        return <></>
    }

    const handleSetTreatmentMode = useCallback(
        (newMode: TreatmentMode) => {
            if (newMode === null) return
            setIsLoading(true)
            socket.emit(
                'send_command',
                {
                    service: 'clustering',
                    command: 'set_treatment_mode',
                    kwargs: { treatment_mode: newMode === 'threshold' ? 0 : 1 },
                },
                (_) => {
                    setIsLoading(false)
                },
            )
        },
        [socket],
    )

    if (!mode) return <ScoreThresholdSlider />

    const isThresholdMode = mode === 'threshold'

    return (
        <>
            <Stack direction='row' spacing={1} alignItems='center' justifyContent='space-between'>
                <Typography variant='h6'>Gevoeligheid</Typography>
                <Chip
                    sx={{
                        height: 40,
                        '& .MuiChip-label': {
                            fontSize: '1rem',
                            padding: '0 10px',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                        },
                        '&.MuiChip-clickableColorSecondary': {
                            backgroundColor: 'white', // Color when disabled
                        },
                    }}
                    label={
                        <>
                            {isThresholdMode ? 'Threshold' : 'Behandeldichtheid'}
                            {isLoading && <CircularProgress size={16} color='inherit' />}
                        </>
                    }
                    color='secondary'
                    onClick={() => !isLoading && handleSetTreatmentMode(isThresholdMode ? 'density' : 'threshold')}
                    disabled={isLoading}
                />
            </Stack>
            <Box sx={{ mt: 1, ml: 2, mr: 2, mb: 2 }}>
                {isThresholdMode ? <ScoreThresholdSlider /> : <DensityThresholdSlider />}
            </Box>
        </>
    )
}

export const InfoBox = () => (
    <InfoPopover
        content={
            <>
                <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line' }}>
                    {'De selectie gevoeligheid kan op twee manieren ingesteld worden.'}
                </Typography>
                <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                    {'Threshold'}
                </Typography>
                <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line' }}>
                    {
                        'In deze modus wordt de "threshold" rechtstreeks ingesteld. Elke gedetecteerde zieke plant krijgt een score, tussen 0 en 1.  Hoe zekerder de plant ziek is, hoe hoger de score. De threshold is de benodigde minimum score om te gaan behandelen. Alle planten met een score hoger dan de ingestelde waarde worden behandeld.'
                    }
                </Typography>
                <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                    {'Behandeldichtheid'}
                </Typography>
                <Typography
                    variant='body2'
                    sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line' }}
                >{`In deze modus zal de machine automatisch de threshold continu aanpassen om de ingestelde behandeldichtheid te halen. De optimale threshold wordt berekend op basis van de afgelopen 50 meter bedlengte.  De werkelijke behandeldichtheid zal rond de ingestelde waarde schommelen.

Voor beide modi geldt dat de werkelijk behaalde behandeldichtheid beperkt wordt door de capaciteit van de behandelrobot. Bij te lage threshold, of te hoog ingestelde behandeldichtheid, kunnen er planten worden overgeslagen.
                        `}</Typography>
            </>
        }
    />
)
