import { useResetRecoilState } from 'recoil'
import { useFetchWrapper } from '../_helpers'
import { taskRequestsAtom } from '../_state'
import { TaskListItem } from '../types'

export { useTaskRequestActions }

function useTaskRequestActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`
    const fetchWrapper = useFetchWrapper()

    return {
        getByCompany: getByCompany,
        rename,
        softDelete,
        resetTaskRequests: useResetRecoilState(taskRequestsAtom),
    }

    function getByCompany(fieldID?: number): Promise<TaskListItem[]> {
        let params = 'skip=0&'
        if (fieldID) {
            params = `fieldID=${fieldID}&`
        }
        return fetchWrapper.get(`${baseUrl}/task_requests/company/?${params}`)
    }

    function rename(id: number, name: string): Promise<TaskListItem> {
        // eslint-disable-next-line camelcase
        return fetchWrapper.post(`${baseUrl}/task_requests/${id}/rename`, { name: name })
    }

    function softDelete(id: number): Promise<object> {
        return fetchWrapper.put(`${baseUrl}/task_requests/${id}`)
    }
}
