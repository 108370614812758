import * as React from 'react'
import { styled } from '@mui/material/styles'
import Switch, { SwitchProps } from '@mui/material/Switch'
import { green, grey } from '@mui/material/colors'

export const CustomSwitch = styled((props: SwitchProps) => <Switch disableRipple {...props} />)(({ disabled }) => ({
    width: 72,
    height: 35,
    padding: 10,
    '& .MuiSwitch-switchBase': {
        color: disabled ? grey[300] : grey[500],
        paddingLeft: 4,
        paddingTop: 4,
        transitionDuration: '200ms',
        '&.Mui-checked': {
            transform: 'translateX(34px)',
            color: disabled ? grey[300] : green[300],
            '& + .MuiSwitch-track': {
                backgroundColor: disabled ? grey[300] : green[500],
            },
        },
    },
    '& .MuiSwitch-thumb': {
        width: 28,
        height: 28,
        color: disabled ? grey[300] : undefined,
    },
    '& .MuiSwitch-track': {
        backgroundColor: '#fff',
        opacity: 1,
    },
}))
