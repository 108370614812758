import React, { useState } from 'react'
import { Socket } from 'socket.io-client'
import { useRecoilValue } from 'recoil'
import { Box, Stack, Button, Typography } from '@mui/material'
import { machineMSUAtom, machineTaskStatus } from '../_state'
import MSULinearProgressBar from './LinearProgressBar'

interface MSUUserViewProps {
    socket: Socket
    handleClose: () => void
}
export const MSUUserView = ({ socket, handleClose }: MSUUserViewProps) => {
    // When the component is rendered from the app bar get the machine from the url
    const machineTaskState = useRecoilValue(machineTaskStatus)
    const machineMSU = useRecoilValue(machineMSUAtom)
    const [progress, setProgress] = useState(null)

    React.useEffect(() => {
        socket.on('progress', (val) => {
            setProgress(val)
        })
        socket.on('TRANSFERED', () => handleClose())
        return () => {
            if (socket) {
                socket.off('progress')
            }
        }
    }, [])

    const render: React.ReactElement[] = []
    if (progress == null) {
        if (machineTaskState === 'stop') {
            render.push(
                <Box key={'update-warn1'}>
                    <Typography variant='h6'>Er is een nieuwe versie beschikbaar!</Typography>
                    <Typography variant='body2' gutterBottom>
                        Om de machine bij te werken, moet u de taak stoppen.
                    </Typography>
                    <Typography variant='h6'>A new version is available!</Typography>
                    <Typography variant='body2' gutterBottom>
                        To update the machine you need to stop the task.
                    </Typography>
                </Box>,
            )
        } else if (machineTaskState !== 'stop') {
            render.push(
                <Box key={'update-warn2'}>
                    <Typography variant='h6'>Er is een nieuwe versie beschikbaar!</Typography>
                    <Typography variant='body2'>
                        Het updaten van de machine duurt ongeveer 2 minuten, waarbij de machine aan moet staan.
                    </Typography>
                    <Typography variant='h6'>A new version is available!</Typography>
                    <Typography variant='body2'>
                        Updating the machine takes around 2 minutes, during which the machine should be powered on.
                    </Typography>
                </Box>,
            )
        }
    } else if (progress) {
        render.push(
            <Box key={'progress'}>
                <Typography variant='body2' gutterBottom>
                    Machine wordt geüpdatet naar de nieuwste versie, laat de generator draaien!
                </Typography>
                <Typography variant='body2' gutterBottom>
                    Machine is updating to the latest version, keep the generator running!
                </Typography>
                <MSULinearProgressBar value={progress} />
            </Box>,
        )
    }
    render.push(
        <Stack key={'buttons'} spacing={2} direction='row' justifyContent={'space-around'} sx={{ mt: 2 }}>
            <Button
                variant='contained'
                color='secondary'
                disabled={progress != null}
                onClick={() => handleClose()}
                fullWidth
            >
                Cancel
            </Button>
            <Button
                variant='contained'
                color='success'
                disabled={machineTaskState === 'stop' || progress != null}
                fullWidth
                onClick={() =>
                    socket.emit('send_command', {
                        service: 'msu',
                        command: 'update',
                        kwargs: {},
                    })
                }
            >
                Update
            </Button>
        </Stack>,
    )
    return <div> {render} </div>
}
