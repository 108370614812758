import { useState, MouseEvent } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import DeleteIcon from '@mui/icons-material/Delete'
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material'
import { User } from '../types'
import { ConfirmDialog, RoleIcon } from '../_components'
import { ChangeRoleDialog } from './ChangeRoleDialog'
import UserService from '../services/UserService'

interface UserMenuProps {
    user: User
    onDeleteUser: (userId: number) => Promise<void>
    onUpdateRole: (userId: number, role: string) => void
}

function UserMenu({ user, onDeleteUser, onUpdateRole }: UserMenuProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [discardDialogOpen, setDiscardDialogOpen] = useState(false)
    const [roleDialogOpen, setRoleDialogOpen] = useState(false)

    const open = Boolean(anchorEl)

    const handleClick = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)
    const handleClose = () => setAnchorEl(null)

    const handleDelete = async () => {
        try {
            await onDeleteUser(user.id)
        } catch (error) {
            console.error('Error deleting user:', error)
        } finally {
            setDiscardDialogOpen(false)
            handleClose()
        }
    }

    const handleSaveRoleChange = async (role: string) => {
        try {
            await onUpdateRole(user.id, role)
        } catch (error) {
            console.error('Error updating role:', error)
        } finally {
            setRoleDialogOpen(false)
            handleClose()
        }
    }

    const actions = [
        {
            type: 'delete',
            label: 'Delete',
            icon: <DeleteIcon />,
            onClick: () => setDiscardDialogOpen(true),
        },
    ]

    if (UserService.hasRole(['dev'])) {
        actions.unshift({
            type: 'changeRole',
            label: 'Change Role',
            icon: <RoleIcon role={user.role} />,
            onClick: () => {
                setRoleDialogOpen(true)
            },
        })
    }

    return (
        <div>
            <IconButton
                size='small'
                aria-label='more'
                id='long-button'
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup='true'
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id='long-menu'
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                onClose={handleClose}
            >
                {actions.map((action, index) => (
                    <MenuItem key={index} onClick={action.onClick}>
                        <ListItemIcon>{action.icon}</ListItemIcon>
                        <ListItemText>{action.label}</ListItemText>
                    </MenuItem>
                ))}
            </Menu>
            <ConfirmDialog
                title={'Delete user'}
                open={discardDialogOpen}
                setOpen={setDiscardDialogOpen}
                onConfirm={handleDelete}
                onCancel={handleClose}
                type={{
                    dialogType: 'delete-user',
                    title: 'Delete user',
                    message: `Are you sure you want to delete '${user.name} ${user.surname}'?`,
                }}
            >
                {`Are you sure you want to delete '${user.name} ${user.surname}'?`}
            </ConfirmDialog>
            <ChangeRoleDialog
                open={roleDialogOpen}
                user={user}
                onClose={() => {
                    setRoleDialogOpen(false)
                    handleClose()
                }}
                onSave={handleSaveRoleChange}
            />
        </div>
    )
}

export { UserMenu }
