import { useRecoilValue, useSetRecoilState } from 'recoil'
import { Fab, Box, Stack } from '@mui/material'
import { taskQuery, machineControlAtom, taskAtom } from '../_state'
import { useTaskActions, useAlertActions } from '../_actions'
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat'

export { ReverseTaskButton }

function ReverseTaskButton() {
    const setTask = useSetRecoilState(taskQuery)
    const task = useRecoilValue(taskAtom)
    if (!task) return
    const machineControl = useRecoilValue(machineControlAtom)

    const taskActions = useTaskActions()
    const alertActions = useAlertActions()

    const disabled = machineControl?.status !== 2

    const handleBoxClick = () => {
        if (!disabled) {
            setTask({ data: null, isLoading: true })
            taskActions
                .getById(task.opposite_task_id)
                .then((res) => setTask({ data: res, isLoading: false })) // TODO make this use cache as well
                .catch(() => {
                    alertActions.error('Error fetching task')
                })
        }
    }

    return (
        <Box>
            <Fab color={'info'} onClick={handleBoxClick} disabled={disabled}>
                <Stack>
                    <TrendingFlatIcon
                        color={task?.reversed ? 'secondary' : 'success'}
                        sx={{
                            p: 0,
                            m: 0,
                        }}
                    />
                    <TrendingFlatIcon
                        color={!task?.reversed ? 'secondary' : 'success'}
                        sx={{
                            p: 0,
                            m: 0,
                            transform: 'scaleX(-1)',
                        }}
                    />
                </Stack>
            </Fab>
        </Box>
    )
}
