// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import { Controller } from 'react-hook-form'
import { formatBytes } from '../_helpers'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { styled } from '@mui/system'
import { useTheme } from '@mui/material/styles'

export { FileInput }

interface UploadContainerProps {
    accepted: number
    disabled: boolean
}

const UploadContainer = styled('div')(({ accepted, disabled }: UploadContainerProps) => {
    const theme = useTheme()
    const getColor = () => {
        switch (true) {
            case Boolean(accepted):
                return theme.palette.success.main
            case disabled:
                return theme.palette.grey[600]
            default:
                return theme.palette.primary.main
        }
    }

    return {
        color: getColor(),
        borderColor: getColor(),
        cursor: disabled ? 'default' : 'pointer',
        transition: 'border 0.24s ease-in-out',
    }
})

interface FileInputProps {
    control: PropTypes.any
    name: PropTypes.any
    onDropAccepted: PropTypes.func
    onDropRejected: PropTypes.func
    acceptedFiles: string[]
    textDescription: string
}

function FileInput({ control, name, onDropAccepted, onDropRejected, acceptedFiles, textDescription }: FileInputProps) {
    const disabled = false

    // const acceptedFileItems = acceptedFiles.map((file) => (
    //   <>
    //     <li key={file.path}>
    //       {file.path} - {file.size} bytes
    //     </li>
    //   </>
    // ))

    return (
        <Controller
            control={control}
            name={name}
            defaultValue={[]}
            render={({ field: { onChange, onBlur, value } }) => (
                <>
                    <Dropzone
                        multiple={true}
                        onDrop={onChange}
                        onDropAccepted={onDropAccepted}
                        onDropRejected={onDropRejected}
                        accept={acceptedFiles}
                    >
                        {({ getRootProps, getInputProps, isDragAccept }) => (
                            <UploadContainer
                                {...getRootProps({
                                    accepted: +isDragAccept,
                                    disabled,
                                })}
                            >
                                <Box
                                    variant='outlined'
                                    sx={{
                                        backgroundColor: '#eee',
                                        textAlign: 'center',
                                        cursor: 'pointer',
                                        color: '#333',
                                        padding: '10px',
                                        marginTop: '20px',
                                    }}
                                >
                                    <CloudUploadIcon
                                        sx={{
                                            marginTop: '16px',
                                            color: '#888888',
                                            fontSize: '42px',
                                        }}
                                    />
                                    <input {...getInputProps()} name={name} onBlur={onBlur} />
                                    <p>{textDescription}</p>
                                </Box>
                            </UploadContainer>
                        )}
                    </Dropzone>
                    <List
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            padding: 0,
                            overflow: 'auto',
                        }}
                    >
                        {value.map((f, index) => (
                            <ListItem key={index}>
                                <ListItemIcon>
                                    <InsertDriveFileIcon color='secondary' />
                                </ListItemIcon>
                                <ListItemText primary={f.name} secondary={formatBytes(parseFloat(f.size))} />
                            </ListItem>
                        ))}
                    </List>
                </>
            )}
        />
    )
}
