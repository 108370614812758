
import { atom } from 'recoil'
import { RobotZHeightStatus } from '../types'

const robotZheightAtom = atom<RobotZHeightStatus | null>({
    key: 'robot_z_height',
    default: null,
})

export { robotZheightAtom }
