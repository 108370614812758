import React, { useState, useEffect } from 'react'
import { AppBar, Toolbar, Box, Dialog, DialogContent, IconButton, Button, Stack, Typography } from '@mui/material'
import { useMachineTransfersActions } from '../../_actions'
import CloseIcon from '@mui/icons-material/Close'
import { TranfersOverview } from '../types'
import { useRecoilValue } from 'recoil'
import { machineNameAtom, treatmentRunningSelector } from '../../_state'
import { CollapsibleTable, TransferMachineModal } from './TransferMachineModal'
import { TransferMachineIcon } from '../../_components'
import { useNavigate } from 'react-router-dom'

function Transfer() {
    const navigate = useNavigate()
    const machineName = useRecoilValue(machineNameAtom)
    if (!machineName) return

    const [modalOpen, setModalOpen] = useState(false)
    const [machineTransfers, setMachineTransfers] = useState<TranfersOverview | null>(null)
    const treatmentRunning = useRecoilValue(treatmentRunningSelector)

    const machineTransfersActions = useMachineTransfersActions()
    const fetchTransfers = () => machineTransfersActions.getAll(machineName).then(setMachineTransfers)
    const handleClose = () => navigate(location.pathname.replace('/transfer', ''))

    useEffect(() => {
        fetchTransfers()
    }, [modalOpen]) // to fetch when the transfer is complete and the modal is closed

    if (!machineTransfers) return null
    return (
        <Dialog fullScreen open={true} onClose={handleClose}>
            <Stack direction={'row'}>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <TransferMachineIcon color='secondary' />
                        <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
                            {`Transfer ${machineName}`}
                        </Typography>
                        <IconButton color='inherit' onClick={handleClose} aria-label='close' size='large'>
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </Stack>
            <DialogContent>
                <Stack spacing={1}>
                    <Typography variant='h5'>Owner Company: {machineTransfers.owner_company}</Typography>
                    <Typography variant='h5'>
                        Borrowing Company:{' '}
                        {machineTransfers.borrowing_company &&
                        machineTransfers.borrowing_company !== machineTransfers.owner_company
                            ? machineTransfers.borrowing_company
                            : 'None'}
                    </Typography>
                    <Box sx={{ pt: 2, pb: 2 }}>
                        <Box sx={{ maxHeight: '400px', overflow: 'auto' }}>
                            <CollapsibleTable transfers={machineTransfers} />
                        </Box>
                    </Box>
                    {treatmentRunning && (
                        <Typography variant='body1' color='error'>
                            Machine is currently treating
                        </Typography>
                    )}
                    <Button
                        variant='contained'
                        color='success'
                        disabled={treatmentRunning}
                        onClick={() => setModalOpen(!modalOpen)}
                    >
                        Transfer {machineName}
                    </Button>
                    {modalOpen && (
                        <TransferMachineModal
                            machineName={machineName}
                            machineTransfers={machineTransfers} // last transfer comp name
                            handleClose={() => setModalOpen(false)}
                        />
                    )}
                </Stack>
            </DialogContent>
        </Dialog>
    )
}

export { Transfer }
