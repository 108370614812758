import { atom, selector, useRecoilValue } from 'recoil'
import { machineControlAtom } from './machineControl'
import { taskAtom } from '../_state'

const START = 'start'
const STOP = 'stop'
const DISABLED = 'select a task'
const LOADING = 'loading'
const RESUME = 'resume'

const startStopTaskButtonLoadingState = atom({
    key: 'startStopTaskButtonLoadingState',
    default: false,
})

const machineTaskStatus = selector<string>({
    key: 'machine_task_status',
    get: ({ get }) => {
        const task = get(taskAtom)
        const machineControl = get(machineControlAtom)
        const btnLoading = get(startStopTaskButtonLoadingState)
        const taskLoadedInMachineSameAsOneDisplayed =
            machineControl?.task_id && task?.id && machineControl?.task_id === task?.id

        if (btnLoading) return LOADING

        if (machineControl?.state === 0 && taskLoadedInMachineSameAsOneDisplayed) {
            return RESUME
        } else if (machineControl?.state === 0 && task?.id) {
            return START
        } else if (machineControl && machineControl.state > 0) {
            return STOP
        } else {
            return DISABLED
        }
    },
})

export { startStopTaskButtonLoadingState, machineTaskStatus }
