export { BoundaryIcon }
interface BoundaryIconProps {
    backgroundColor: string
    pathColor: string
}

function BoundaryIcon({ backgroundColor, pathColor }: BoundaryIconProps) {
    const borderRadius = 6

    return (
        <svg
            viewBox='-15 -15 98 100'
            width='28px'
            height='28px'
            style={{
                backgroundColor: backgroundColor,
                borderRadius: `${borderRadius}px`,
            }}
        >
            <g transform='scale(5)'>
                <path
                    d='m8.8,13.76H.5V.5h13.26v7.7h-3.44c-.83,0-1.51.68-1.51,1.51v4.04Zm-7.82-.48h7.35v-3.56c0-1.1.89-1.99,1.99-1.99h2.97V.98H.98v12.3Z'
                    // ='#000'
                    stroke='#fff'
                    strokeMiterlimit='10'
                    strokeWidth='0.5'
                />
            </g>
            <g transform='scale(5)'>
                <path
                    d='m.74.06C.36.06.06.36.06.74s.3.68.68.68.68-.3.68-.68S1.11.06.74.06Z'
                    // ='#000'
                    stroke={pathColor}
                    strokeWidth='1.8'
                />
                <path
                    d='m13.52,1.42c.37,0,.68-.3.68-.68s-.3-.68-.68-.68-.68.3-.68.68.3.68.68.68Z'
                    // ='#000'
                    stroke={pathColor}
                    strokeWidth='1.8'
                />
                <path
                    d='m.74,12.84c-.37,0-.68.3-.68.68s.3.68.68.68.68-.3.68-.68-.3-.68-.68-.68Z'
                    // ='#000'
                    stroke={pathColor}
                    strokeWidth='1.8'
                />
                <path
                    d='m8.56,12.84c-.37,0-.68.3-.68.68s.3.68.68.68.68-.3.68-.68-.3-.68-.68-.68Z'
                    // ='#000'
                    stroke={pathColor}
                    strokeWidth='1.8'
                />
                <path
                    d='m13.52,7.29c-.37,0-.68.3-.68.68s.3.68.68.68.68-.3.68-.68-.3-.68-.68-.68Z'
                    // ='#000'
                    stroke={pathColor}
                    strokeWidth='1.8'
                />
            </g>
        </svg>
    )
}
