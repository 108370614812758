import { Stack, Box, Button } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

export function MenuEditorChoice() {
    return (
        <Box
            sx={{
                position: 'absolute',
                bottom: '2%',
                right: '2%',
            }}
        >
            <Stack
                component='nav'
                aria-label='Device settings'
                sx={{ bgcolor: 'background.paper', borderRadius: '8px', padding: '16px' }}
            >
                <Button
                    component={RouterLink}
                    to='boundary-draw'
                    variant='contained'
                    color='primary'
                    sx={{ marginBottom: '8px' }}
                >
                    Draw New Boundary
                </Button>
                <Button
                    component={RouterLink}
                    to='boundary-edit'
                    variant='contained'
                    color='primary'
                    sx={{ marginBottom: '8px' }}
                >
                    Edit Boundary
                </Button>
                <Button
                    component={RouterLink}
                    to='planted-area-edit'
                    variant='contained'
                    color='primary'
                    sx={{ marginBottom: '8px' }}
                >
                    Edit planted-area
                </Button>
                <Button
                    component={RouterLink}
                    to='skip-rows'
                    variant='contained'
                    color='primary'
                    sx={{ marginBottom: '8px' }}
                >
                    Skip Rows
                </Button>
                <Button component={RouterLink} to='cultivar-matching' variant='contained' color='primary'>
                    Cultivar Matching
                </Button>
            </Stack>
        </Box>
    )
}
