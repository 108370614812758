import { atom, selector } from 'recoil'
import { FluidInstrumentationStatus } from '../types'
import { arraysHaveSameContent } from '../_helpers'

const fluidInstrumentationAtom = atom<FluidInstrumentationStatus | null>({
    key: 'fluid_instrumentation',
    default: null,
})

const fluidInstrumentationDetectionDensitySelector = selector<number | null>({
    key: 'fluid_instrumentation_detection_density_selector',
    get: ({ get }) => {
        const fi = get(fluidInstrumentationAtom);
        // Return null if fi is undefined or if detection_density is undefined
        return fi ? fi.detection_density ?? null : null
    },
})
const fluidInstrumentationDispenseFlowSelector = selector<number | null>({
    key: 'fluid_instrumentation_dispense_flow_selector',
    get: ({ get }) => {
        const fi = get(fluidInstrumentationAtom)
        return fi ? fi.dispense_flow ?? null : null
    },
})
const fluidInstrumentationPressureSetpointSelector = selector<number | null>({
    key: 'fluid_instrumentation_pressure_setpoint_selector',
    get: ({ get }) => {
        const fi = get(fluidInstrumentationAtom)
        return fi ? fi.pressure_setpoint ?? null : null
    },
})
const fluidInstrumentationSettingsIDSelector = selector<number | null>({
    key: 'fluid_instrumentation_settings_id_selector',
    get: ({ get }) => {
        const fi = get(fluidInstrumentationAtom)
        return fi ? fi.settings_id ?? null : null
    },
})
const fluidInstrumentationTankLevelSelector = selector<number | null>({
    key: 'fluid_instrumentation_tank_level_selector',
    get: ({ get }) => {
        const fi = get(fluidInstrumentationAtom)
        return fi ? fi.tank_level ?? null : null
    },
})

const fluidInstrumentationTankPressureSelector = selector<number | null>({
    key: 'fluid_instrumentation_tank_pressure_selector',
    get: ({ get }) => {
        const fi = get(fluidInstrumentationAtom)
        return fi ? fi.tank_pressure ?? null : null
    },
})

const fluidInstrumentationDispenseVolumeSelector = selector<number | null>({
    key: 'fluid_instrumentation_dispense_volume_selector',
    get: ({ get }) => {
        const fi = get(fluidInstrumentationAtom)
        return fi ? fi.dispense_volume ?? null : null
    },
})

let prevAlarms: string[] = [];
const fluidInstrumentationAlarmSelector = selector<string[]>({
    key: 'fluid_instrumentation_alarm_selector',
    get: ({ get }) => {
        const fi = get(fluidInstrumentationAtom);

        if (fi) {
            if (arraysHaveSameContent(prevAlarms, fi.alarm)) {
                return prevAlarms;
            } else {
                prevAlarms = fi.alarm;
                return fi.alarm;
            }
        }
        // Return an empty array if `fi` is undefined or does not have alarms
        return [];
    },
});

export {
    fluidInstrumentationAtom,
    fluidInstrumentationAlarmSelector,
    fluidInstrumentationDetectionDensitySelector,
    fluidInstrumentationDispenseFlowSelector,
    fluidInstrumentationPressureSetpointSelector,
    fluidInstrumentationTankLevelSelector,
    fluidInstrumentationSettingsIDSelector,
    fluidInstrumentationDispenseVolumeSelector,
    fluidInstrumentationTankPressureSelector,
}
