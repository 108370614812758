import UserService from '../services/UserService'
import { useAlertActions } from '../_actions'

export { useFetchWrapper }

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE'
interface RequestOptions {
    method: HttpMethod
    headers: Record<string, string>
    // eslint-disable-next-line
    body?: any
}

// eslint-disable-next-line
type ResponseData = any
// eslint-disable-next-line
type RequestFunction = (url: string, body?: any) => Promise<ResponseData>

function useFetchWrapper(): {
    get: RequestFunction
    post: RequestFunction
    put: RequestFunction
    delete: RequestFunction
} {
    const alertActions = useAlertActions()

    return {
        get: request('GET'),
        post: request('POST'),
        put: request('PUT'),
        delete: request('DELETE'),
    }

    function request(method: HttpMethod) {
        // eslint-disable-next-line
        return async (url: string, body?: any) => {
            const requestOptions: RequestOptions = {
                method,
                headers: await authHeader(url),
                body,
            }

            if (body && url.match(/\w+\/\d+\?name=/g)) {
                requestOptions.headers.Accept = 'application/json'
                requestOptions.body = body
            } else if (body && url.endsWith('crops')) {
                requestOptions.headers.Accept = 'application/json'
                requestOptions.body = body
            } else if (body && url.endsWith('boundaries')) {
                requestOptions.headers.Accept = 'application/json'
                requestOptions.body = body
            } else if (body && url.endsWith('updatelogbook')) {
                requestOptions.headers.Accept = 'application/json'
                requestOptions.body = body
            } else if (body && url.endsWith('fields')) {
                requestOptions.headers.Accept = 'application/json'
                requestOptions.headers['Content-Type'] = 'application/json'
                requestOptions.body = JSON.stringify(body)
            } else if (body && url.endsWith('users/open')) {
                requestOptions.headers.Accept = 'application/json'
                requestOptions.headers['Content-Type'] = 'application/json'
                requestOptions.body = JSON.stringify(body)
            } else if (url.endsWith('files')) {
                requestOptions.headers.Accept = 'application/zip'
                requestOptions.headers['Content-Type'] = 'application/zip'
                return fetch(url, requestOptions)
                    .then(async (response) => {
                        const elm = document.createElement('a')
                        elm.href = URL.createObjectURL(await response.blob())
                        elm.setAttribute('download', getFilename(response.headers) || url)
                        elm.click()
                    })
                    .catch((error) => {
                        console.log(error)
                        alertActions.error('Error while getting files')
                    })
            } else if (url.endsWith('generate_pdf') || url.endsWith('download_pdf')) {
                requestOptions.headers.Accept = 'application/pdf'
                requestOptions.headers['Content-Type'] = 'application/pdf'

                return fetch(url, requestOptions)
                    .then(async (response) => {
                        const elm = document.createElement('a')
                        elm.href = URL.createObjectURL(await response.blob())
                        elm.setAttribute('download', getFilename(response.headers) || 'example.pdf')
                        elm.click()
                    })
                    .catch((error) => {
                        console.log(error)
                        alertActions.error('Error while generating pdf')
                    })
            } else {
                requestOptions.headers.Accept = 'application/json'
                requestOptions.headers['Content-Type'] = 'application/json'
                requestOptions.body = JSON.stringify(body)
            }

            // for serving CIS camera images and logbook images
            if (url.includes('cabinets_assembly/') || url.includes('messages/') || url.includes('images/images/') || url.includes('latest')) {
                return fetch(url, requestOptions).then((res) => res.blob())
            } else if (url.includes('to_csv')) {
                fetch(url, requestOptions)
                    .then((res) => {
                        // Extract the filename from the Content-Disposition header
                        const contentDisposition = res.headers.get('content-disposition');
                        if (!contentDisposition) {
                            throw new Error('Content-Disposition header is missing');
                        }

                        const filenameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
                        if (!filenameMatch) {
                            throw new Error('Filename is missing in Content-Disposition header');
                        }
                        const filename = filenameMatch[1];
                        // Resolve the promise returned by res.blob() to get the actual Blob
                        res.blob().then((myBlob) => {
                            // Create a link element with the file data and filename
                            const link = document.createElement('a')
                            link.href = window.URL.createObjectURL(new Blob([myBlob], { type: 'text/csv' }))
                            link.download = filename

                            // Click the link to trigger the download
                            link.click()

                            // Clean up the URL object
                            window.URL.revokeObjectURL(link.href)
                        })
                    })
                    .catch((error) => console.error(error))
            } else {
                return fetch(url, requestOptions).then(handleResponse)
            }
        }
    }
    function getFilename(headers: Headers): string | null {
        const contentDisposition = headers.get('Content-Disposition')
        if (contentDisposition) {
            const matches = contentDisposition.match(/filename="(.*?)"/)
            if (matches && matches.length === 2) {
                return matches[1]
            }
        }
        return null
    }
    async function authHeader(url: string): Promise<Record<string, string>> {
        if (UserService.isTokenExpired()) {
            await UserService.updateToken()
        }
        const token = UserService.getToken()
        const apiUrl = process.env.REACT_APP_API_URL;
        if (apiUrl && url.includes(apiUrl)) {
            return { Authorization: `Bearer ${token}` }
        } else {
            return {}
        }
    }

    function handleResponse(response: Response): Promise<ResponseData> {
        return response.text().then((text) => {
            const data = text && JSON.parse(text)
            if (!response.ok) {
                const error = (data && data.detail) || response.statusText
                alertActions.error(error)
                return Promise.reject(error)
            }

            return data
        })
    }
}
