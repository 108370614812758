import React, { lazy, Suspense, useEffect, useRef } from 'react'
import { zoomMapLayer } from '../_helpers'
import { H2LFieldJson } from '../types'
import { useNavigate, Route, Routes, useResolvedPath } from 'react-router-dom'
import { ArrowBackDiscard } from './ArrowBackDiscard'
import { SaveButton } from './SaveButton'
import { BoundaryDraw } from './BoundaryDraw'
import { BoundaryEdit } from './BoundaryEdit'
import { PlantedAreaEdit } from './PlantedAreaEdit'
import { SkipRows } from './SkipRows'

const LazyCultivarMatching = lazy(() => import('../cultivar_matching'))

interface GMapFieldEditorProps {
    field: H2LFieldJson
    map: google.maps.Map
}

function setGeoJSONStyle(feature) {
    return {
        strokeColor: feature.getProperty('skip') ? '#000000' : feature.getProperty('strokeColor'),
        strokeWeight: feature.getProperty('strokeWeight'),
        strokeOpacity: feature.getProperty('strokeOpacity'),
        fillColor: feature.getProperty('fillColor'),
        fillOpacity: feature.getProperty('fillOpacity'),
        zIndex: feature.getProperty('row_number') ? 3 : 1, //beds on top of boundary
    }
}

export function GMapFieldEditor(props: GMapFieldEditorProps) {
    const url = useResolvedPath('').pathname
    const navigate = useNavigate()

    useEffect(() => {
        if (url) {
            if (props.field?.boundary) {
                navigate('boundary-edit', { replace: true })
            } else {
                navigate('boundary-draw', { replace: true })
            }
        }
    }, [url])

    const bound_data = useRef(new google.maps.Data())
    const bed_data = useRef(new google.maps.Data())
    const planted_area_data = useRef(new google.maps.Data())

    useEffect(() => {
        props.map.data.setMap(null) // TODO improve solution
        props.map.setHeading(props.field.heading + 90)
        bound_data.current.addGeoJson(props.field?.boundary)
        planted_area_data.current.addGeoJson(props.field.planted_area)

        bound_data.current.setStyle(setGeoJSONStyle)
        planted_area_data.current.setStyle(setGeoJSONStyle)

        bound_data.current.setMap(props.map)
        planted_area_data.current.setMap(props.map)

        bed_data.current.addGeoJson({
            type: 'FeatureCollection',
            features: props.field.beds,
        })
        bed_data.current.setStyle(setGeoJSONStyle)
        bed_data.current.setMap(props.map)
        zoomMapLayer(props.map, planted_area_data.current)

        return () => {
            bed_data.current.setMap(null)
            bound_data.current.setMap(null)
            planted_area_data.current.setMap(null)
            // revert to original data when map closes
            if (props.map) {
                props.map.data.setMap(props.map)
            }
        }
    }, [])

    return (
        <>
            <SaveButton
                field={props.field}
                boundary={bound_data.current}
                beds={bed_data.current}
                planted_area={planted_area_data.current}
            />
            <ArrowBackDiscard field={props.field} />
            <Routes>
                <Route
                    path={'boundary-draw'}
                    element={<BoundaryDraw map={props.map} boundary={bound_data.current} />}
                />
                <Route path={'boundary-edit'} element={<BoundaryEdit boundary={bound_data.current} />} />
                <Route
                    path={'planted-area-edit'}
                    element={<PlantedAreaEdit plantedArea={planted_area_data.current} />}
                />
                <Route path={'skip-rows'} element={<SkipRows beds={bed_data.current} />} />
                <Route
                    path={'cultivar-matching'}
                    element={
                        <Suspense fallback={<div>Loading Cultivar Matching...</div>}>
                            <LazyCultivarMatching bed_data={bed_data.current} />
                        </Suspense>
                    }
                />
            </Routes>
        </>
    )
}
