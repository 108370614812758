import { createTheme } from '@mui/material/styles'
const primary = '#263238'
const secondary = '#fff'
const info = '#4f5b62'

export const lightTheme = createTheme({
    palette: {
        mode: 'light',
        secondary: {
            main: secondary,
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#343a40',
                },
            },
        },
    },
})

export const mobileDarkTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 400,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    palette: {
        mode: 'dark',
        primary: {
            main: primary,
        },
        secondary: {
            main: secondary,
        },
        info: {
            main: info,
        },
    },
    components: {
        MuiToolbar: {
            styleOverrides: {
                dense: {
                    height: 64,
                    minHeight: 64,
                },
                root: {
                    backgroundColor: primary,
                },
            },
        },
        MuiSwitch: {
            styleOverrides: {
                switchBase: {
                    // Controls default (unchecked) color for the thumb
                    color: primary,
                },
                colorPrimary: {
                    '&.Mui-checked': {
                        // Controls checked color for the thumb
                        color: secondary,
                    },
                },
                track: {
                    // Controls default (unchecked) color for the track
                    opacity: 0.2,
                    backgroundColor: info,
                    '.Mui-checked.Mui-checked + &': {
                        // Controls checked color for the track
                        opacity: 0.7,
                        backgroundColor: info,
                    },
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    backgroundColor: info,
                    // '&.Mui-selected': {
                    //   backgroundColor: secondary,
                    // },
                },
            },
        },
    },
})
