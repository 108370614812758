import React from 'react'
import UserService from '../../services/UserService'
import { CameraStatus } from './CameraStatus'
import { Skeleton, Divider, Typography, Box, Stack } from '@mui/material'
import { MachineSettings } from '../../types'
import { useTheme } from '@mui/material/styles'
import { useRecoilValue } from 'recoil'
import { machineControlAtom } from '../../_state'
import { CISController, CISView } from '../../cis'
import { useMachineSettingsActions } from '../../_actions'
import { ModelInfo } from '../../inference_models'
import InfoIcon from '@mui/icons-material/Info'
import SecurityIcon from '@mui/icons-material/Security'

const CamerasSettingsView = () => {
    const isPrivileged = UserService.hasRole(['admin', 'manufacturer', 'dev'])
    const theme = useTheme()
    const [lastMachineSettings, setLastMachineSettings] = React.useState<MachineSettings | null>(null)
    const machineControl = useRecoilValue(machineControlAtom)
    const machineSettingsActions = useMachineSettingsActions()

    // Fetch when other user starts a treatment
    React.useEffect(() => {
        if (machineControl?.job_id && machineControl?.state > 0) {
            machineSettingsActions
                .getLastMachineSettingsByMachineName()
                .then((lastMachineSettings: MachineSettings) => {
                    setLastMachineSettings(lastMachineSettings)
                })
        }
    }, [machineControl?.job_id])

    if (machineControl?.state === 0 || !lastMachineSettings) return null
    return (
        <>
            <Divider sx={{ mt: 1, mb: 1 }} />
            <Typography variant='h6'>Herkennings Model: {lastMachineSettings.cameras_model}</Typography>
            <Stack direction={'row'} spacing={1} sx={{ mt: 1 }} alignItems={'center'}>
                <InfoIcon fontSize='small' />
                <Typography variant='h6'>Beschrijving</Typography>
            </Stack>
            <Box sx={{ mb: 2 }}>{<ModelInfo modelName={lastMachineSettings.cameras_model} />}</Box>
            {isPrivileged && (
                <Stack>
                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                        <SecurityIcon fontSize='small' />
                        <Typography variant='h6'>Admin Settings</Typography>
                    </Stack>
                    <Typography variant='h6' gutterBottom>
                        Robot model: {lastMachineSettings.robot_model}
                    </Typography>
                    <Stack direction={'row'} spacing={1}>
                        <Typography variant='body2' sx={{ mr: 2 }} noWrap>
                            Cameras recording: {lastMachineSettings.cameras_rec_enabled ? 'enabled' : 'disabled'}
                        </Typography>
                        {lastMachineSettings.cameras_rec_enabled && (
                            <Skeleton
                                key={'skeleton'}
                                variant='circular'
                                sx={{ mt: 1, bgcolor: theme.palette.error.dark }}
                                width={20}
                                height={20}
                            />
                        )}
                    </Stack>
                    <Stack direction={'row'} spacing={1}>
                        <Typography variant='body2' sx={{ mr: 2 }} noWrap>
                            Robot recording: {lastMachineSettings.robot_rec_enabled ? 'enabled' : 'disabled'}
                        </Typography>
                        {lastMachineSettings.robot_rec_enabled && (
                            <Skeleton
                                key={'skeleton'}
                                variant='circular'
                                sx={{ bgcolor: theme.palette.error.dark }}
                                width={20}
                                height={20}
                            />
                        )}
                    </Stack>
                    <Typography variant='body2' sx={{ mr: 2 }} noWrap>
                        Cluster Z height: {lastMachineSettings.cluster_z_height_guess}
                    </Typography>
                </Stack>
            )}
        </>
    )
}

export default function CamerasTab() {
    return (
        <Box sx={{ p: 2 }}>
            <Typography variant='h5' gutterBottom>
                Cameras status
            </Typography>
            <Divider />
            <Box sx={{ mt: 1 }}>
                <CameraStatus />
                <CISController />
            </Box>
            <br></br>
            <CamerasSettingsView />
        </Box>
    )
}
