import React, { useState } from 'react'
import { useRecoilValue } from 'recoil'
import { machineControlAtom, machineSocketAtom } from '../_state'
import { ModalBox, IconButtonWithStateIcon } from '.'
import { Box, Button, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import WarningIcon from '@mui/icons-material/Warning'
import BlockIcon from '@mui/icons-material/Block'
import FastForwardIcon from '@mui/icons-material/FastForward'

interface StateIconProps {
    styles?: React.CSSProperties
}

const ServiceStoppedIndicator = () => {
    const [modalOpen, setModalOpen] = useState(false)
    const handleOpenModal = () => setModalOpen(true)
    const handleCloseModal = () => setModalOpen(false)
    const machineControl = useRecoilValue(machineControlAtom)
    const socket = useRecoilValue(machineSocketAtom)
    const theme = useTheme()

    const state = machineControl?.state || 0

    const StateIcon = ({ styles }: StateIconProps) => {
        switch (state) {
            case 2:
                return <BlockIcon color={'secondary'} sx={styles} fontSize='small' />
            case 3:
                return <FastForwardIcon color={'secondary'} sx={styles} fontSize='small' />
            default:
                return null
        }
    }
    const StateIconInverse = ({ styles }: StateIconProps) => {
        switch (state) {
            case 3:
                return <BlockIcon color={'secondary'} sx={styles} fontSize='small' />
            case 2:
                return <FastForwardIcon color={'secondary'} sx={styles} fontSize='small' />
            default:
                return null
        }
    }

    if (!socket) return null
    if (state < 2) return null
    return (
        <>
            <IconButtonWithStateIcon
                baseIcon={<WarningIcon fontSize='large' color='warning' />}
                stateIcon={
                    <StateIcon
                        styles={{
                            background: state === 3 ? theme.palette.success.main : theme.palette.error.main,
                            position: 'absolute',
                            top: '30px',
                            borderRadius: '16px',
                        }}
                    />
                }
                onClick={handleOpenModal}
            />
            {modalOpen && (
                <ModalBox
                    title={'Behandelsysteem niet gereed'}
                    titleIcon={<WarningIcon fontSize='large' color='warning' />}
                    open={modalOpen}
                    handleDialogClose={handleCloseModal}
                >
                    <Typography variant='body1' gutterBottom sx={{ pt: 2 }}>
                        {state === 2 ? 'Taak onderbroken.' : 'Taak wordt voortgezet.'}
                    </Typography>
                    <Typography variant='body1' gutterBottom sx={{ pb: 2 }}>
                        {state === 2
                            ? 'Negeren kan leiden tot verminderde prestaties of schade aan het behandelsysteem.'
                            : 'Er is kans op verminderde prestaties of schade.'}
                    </Typography>
                    <Box textAlign={'end'}>
                        <Button
                            variant='contained'
                            color={state === 3 ? 'secondary' : 'warning'}
                            endIcon={
                                <StateIconInverse
                                    styles={{
                                        background:
                                            state === 2 ? theme.palette.success.main : theme.palette.error.main,
                                        borderRadius: '16px',
                                    }}
                                />
                            }
                            onClick={() => {
                                socket.emit('send_command', {
                                    service: 'machine_control',
                                    command: state === 3 ? 'block' : 'override',
                                    kwargs: {},
                                })
                            }}
                        >
                            {state === 2 ? 'Negeren' : 'Taak Onderbreken'}
                        </Button>
                    </Box>
                </ModalBox>
            )}
        </>
    )
}

export { ServiceStoppedIndicator }
