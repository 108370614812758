import { useFetchWrapper } from '../_helpers'
import { TranferMachineRequest, TranfersOverview } from '../machines/types'
export { useMachineTransfersActions }

function useMachineTransfersActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`
    const fetchWrapper = useFetchWrapper()

    return {
        getAll,
        transfer,
    }

    function getAll(machineName: string): Promise<TranfersOverview | null> {
        return fetchWrapper.get(`${baseUrl}/machine-transfers/${machineName}`)
    }

    function transfer(form_data: TranferMachineRequest) {
        return fetchWrapper.post(`${baseUrl}/machine-transfers`, form_data)
    }
}
