import { ThresholdSetting, LowLevelControls, TulipMemory } from '..'
import { FluidSafetySettings } from '../treatment/FluidSafety/FluidSafetySettings'
import OffsetAdjustment from '../OffsetAdjustment'
import { SpeedControl } from '../treatment/SpeedControl'
import { Box, Divider } from '@mui/material'

export default function TreatmentTab() {
    // console.log('[TreatmentTab] Render')
    const render = () => {
        return (
            <Box sx={{ p: 2 }}>
                <ThresholdSetting />
                <FluidSafetySettings />
                <Divider sx={{ mt: 1, mb: 1 }} />
                <TulipMemory />
                <Divider sx={{ mt: 1 }} />
                <SpeedControl />
                <LowLevelControls />
                <Divider sx={{ mt: 1, mb: 1 }} />
                <OffsetAdjustment />
            </Box>
        )
    }
    return render()
}
