import UserService from '../services/UserService'
import { useRecoilState } from 'recoil'
import { impersonateUsersAtom } from '../_state'

export const useImpersonateUser = () => {
    const [impersonateUsers, setImpersonateUsers] = useRecoilState(impersonateUsersAtom)

    function isImpersonating(): boolean {
        return impersonateUsers
    }

    function impersonate(): void {
        UserService.toggleImpersonateUser()
        setImpersonateUsers((prevState) => !prevState)
    }

    return {
        isImpersonating,
        toggle: impersonate
    }
};