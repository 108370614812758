import * as React from 'react'
import { machineSocketAtom } from '../../_state'
import { useRecoilValue } from 'recoil'
import { CircularProgress, Button } from '@mui/material'
import { SprayIcon } from '../icons/SprayIcon'
import { sleep } from '../../_helpers'

export { SingleSprayControl }

function SingleSprayControl() {
    const socket = useRecoilValue(machineSocketAtom)
    const [loading, setLoading] = React.useState(false)
    if (!socket) return
    return (
        <>
            <Button
                endIcon={loading ? <CircularProgress size={20} /> : <SprayIcon />}
                sx={{ ml: 2, mb: 1, mt: 1 }}
                color='secondary'
                variant='contained'
                onClick={() => {
                    setLoading(true)
                    socket.emit('send_command', {
                        service: 'treatment',
                        command: 'single_dispense_action',
                        // eslint-disable-next-line camelcase
                        kwargs: { treatment_control: { dispense_duration: 0.03 } },
                    })
                    sleep(1000).then(() => {
                        setLoading(false)
                    })
                }}
            >
                Spray
            </Button>
        </>
    )
}
