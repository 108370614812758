import { useFetchWrapper } from '../_helpers'

export { useParserActions }

function useParserActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`
    const fetchWrapper = useFetchWrapper()

    return {
        parseById,
    }

    function parseById(fieldID: number) {
        return fetchWrapper.get(`${baseUrl}/parser/${fieldID}`)
    }
}
