import React from 'react'
import UserService from '../services/UserService'
import { formatToDutchTimezone } from '../_helpers'
import { useNavigate } from 'react-router-dom'
import { io, Socket } from 'socket.io-client'
import { Container, Typography, Fab, Backdrop, Stack } from '@mui/material'
import { CircularStatic } from '../_components'
import { b64 } from '../_helpers/utils'
import ArrowBack from '@mui/icons-material/ArrowBack'
import NoFlashIcon from '@mui/icons-material/NoFlash'
import NoPhotographyIcon from '@mui/icons-material/NoPhotography'
import { ImgData } from '../types'

export const Slideshow = () => {
    const [image, setImage] = React.useState<ImgData | null>(null)
    const [slideshowSocket, setMachineSlideshowSocket] = React.useState<Socket>(null)
    const navigate = useNavigate()

    React.useEffect(() => {
        const socket = io(`${process.env.REACT_APP_CIS_URL}/slideshow`, {
            auth: {
                token: UserService.getToken(),
                client_type: 'ui-client',
            },
        })
        socket.on('img-event', function (data: ImgData) {
            setImage(data)
        })
        socket.on('UNAUTHORIZED', () => {
            UserService.doLogout()
        })
        setMachineSlideshowSocket(socket)
        return () => {
            socket.off('img-event')
            socket.close()
            setMachineSlideshowSocket(null)
        }
    }, [])

    if (!slideshowSocket?.connected || !image) {
        return (
            <Backdrop sx={{ color: '#fff' }} open={true}>
                <Stack direction='column' alignItems='center' justifyContent='center'>
                    <CircularStatic size={60}>
                        {!slideshowSocket?.connected ? (
                            <NoFlashIcon fontSize='large' />
                        ) : (
                            <NoPhotographyIcon fontSize='large' />
                        )}
                    </CircularStatic>
                </Stack>
            </Backdrop>
        )
    }

    return (
        <Container sx={{ height: '100%' }}>
            <Fab
                sx={{ position: 'absolute', top: '2%', left: '2%' }}
                size='medium'
                onClick={() => navigate('/machines')}
            >
                <ArrowBack />
            </Fab>
            {image?.meta?.datetime && image?.image_data?.camera && (
                <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'flex-end'}>
                    <Typography variant='h4' sx={{ fontWeight: 600 }}>
                        {formatToDutchTimezone(image?.meta?.datetime)}
                    </Typography>
                    <Typography variant='h4' sx={{ fontWeight: 600 }}>
                        {image?.image_data?.machine} camera {image?.image_data?.camera}
                    </Typography>
                </Stack>
            )}
            {image?.image_data?.data ? (
                <img
                    style={{ width: '100%', height: '100%', objectFit: 'none' }}
                    src={'data:image/png;base64,' + b64(image?.image_data?.data)}
                    alt={'...loading'}
                />
            ) : (
                <img
                    style={{ width: '100%', height: '100%', objectFit: 'none' }}
                    src={'data:image/png;base64,' + b64(image?.image_data)}
                    alt={'...loading'}
                />
            )}
        </Container>
    )
}
