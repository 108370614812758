import { Box, Stack, Modal, Typography, IconButton } from '@mui/material/'
import CloseIcon from '@mui/icons-material/Close'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    marginTop: '2%',
    maxHeight: '90%',
    overflowY: 'auto',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
        width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
        background: '#888',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb:hover': {
        background: '#555',
    },
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
    borderRadius: '5px',
}

const closeButtonStyle = {
    cursor: 'pointer',
    float: 'right',
}

export { ModalBox }

type ModalBoxProps = {
    title: string
    titleIcon?: JSX.Element
    open: boolean
    handleDialogClose: () => void
    children: React.ReactNode
}

function ModalBox({ title, titleIcon, open, handleDialogClose, children }: ModalBoxProps) {
    return (
        <Modal
            keepMounted
            open={open}
            onClose={handleDialogClose}
            aria-labelledby='keep-mounted-modal-title'
            aria-describedby='keep-mounted-modal-description'
        >
            <Box sx={style}>
                <IconButton onClick={handleDialogClose} sx={closeButtonStyle} size='large'>
                    <CloseIcon />
                </IconButton>
                {titleIcon ? (
                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                        {titleIcon}
                        <Typography id='keep-mounted-modal-title' variant='h6' component='h2' sx={{ mb: 1 }}>
                            {title}
                        </Typography>
                    </Stack>
                ) : (
                    <Typography id='keep-mounted-modal-title' variant='h6' component='h2' sx={{ mb: 1 }}>
                        {title}
                    </Typography>
                )}
                {/* <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography> */}
                {children}
            </Box>
        </Modal>
    )
}
