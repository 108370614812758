import { atom, selector } from 'recoil'
import { TreatmentStatus } from '../types'

const treatmentAtom = atom<TreatmentStatus | null>({
    key: 'treatment',
    default: null,
})

const treatmentRunningSelector = selector<boolean>({
    key: 'treatment_running',
    get: ({ get }) => {
        //return get(treatmentAtom)?.running
        return get(treatmentAtom)?.status === 3
    },
})

const treatmentCounterSelector = selector<number>({
    key: 'treatment_counter',
    get: ({ get }) => {
        const treatmentCounter = get(treatmentAtom)?.treatment_counter
        if (treatmentCounter) {
            return treatmentCounter
        } else {
            return 0
        }
    },
})

const treatmentsPer100mSelector = selector<number>({
    key: 'treatments_per_100m',
    get: ({ get }) => {
        const treatmentsPer100m = get(treatmentAtom)?.treatments_per_100m
        if (treatmentsPer100m) {
            return treatmentsPer100m
        } else {
            return 0
        }
    },
})

const treatmentDispenseDuration = selector<number>({
    key: 'dispense_duration',
    get: ({ get }) => {
        const dd = get(treatmentAtom)?.dispense_duration
        if (dd) {
            return dd
        } else {
            return 0
        }
    },
})

const treatmentSpeedControlSelector = selector<number | null>({
    key: 'treatment_speed_control_selector',
    get: ({ get }) => {
        const sc = get(treatmentAtom)?.speed_control
        if (!sc) return null
        return sc
    },
})

export {
    treatmentAtom,
    treatmentDispenseDuration,
    treatmentRunningSelector,
    treatmentCounterSelector,
    treatmentsPer100mSelector,
    treatmentSpeedControlSelector,
}
