import { useFetchWrapper } from '../_helpers'
import { AcceptanceTestType } from '../types'
export { useAcceptanceTestActions }

function useAcceptanceTestActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`
    const fetchWrapper = useFetchWrapper()

    return {
        create,
        getByID,
        getByMachineName,
        downloadPDF,
    }

    function create(machineName: string) {
        return fetchWrapper.post(`${baseUrl}/acceptance_test/${machineName}`)
    }

    function getByID(id: number): Promise<AcceptanceTestType> {
        return fetchWrapper.get(`${baseUrl}/acceptance_test/${id}`)
    }

    function getByMachineName(machineName: string): Promise<AcceptanceTestType[] | []> {
        return fetchWrapper.get(`${baseUrl}/acceptance_test/machine_name/${machineName}`)
    }

    async function downloadPDF(id: number): Promise<void> {
        return fetchWrapper.get(`${baseUrl}/acceptance_test/id/${id}/generate_pdf`)
    }
}
