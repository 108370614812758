import { Batch } from '../types'
import { useFetchWrapper } from '../_helpers'

export { useTulipMemoryActions }

function useTulipMemoryActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1/tulip_memory`
    const fetchWrapper = useFetchWrapper()

    return {
        get_batch_by_id,
    }

    function get_batch_by_id(id: number): Promise<Batch | null> {
        return fetchWrapper.get(`${baseUrl}/batch/${id}`)
    }
}
