import React, { forwardRef, useState, useImperativeHandle, useRef, ChangeEvent, useEffect } from 'react'
import { io } from 'socket.io-client'
import UserService from '../services/UserService'
import { ModalBox, SelectBox } from '.'
import { Box, Button, Typography } from '@mui/material'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { SystemCommandRequest, TerminalWindowRef } from '../types'
import { styles } from '../styles'
import { useRecoilValue } from 'recoil'
import { machineNameAtom } from '../_state'
import { useNavigate } from 'react-router-dom'

interface TerminalWindowProps {
    terminalMinHeight?: string
    terminalMaxHeight?: string
}

export const TerminalWindow = forwardRef<TerminalWindowRef, TerminalWindowProps>((props, ref) => {
    const [commandOutput, setCommandOutput] = useState('')
    const commandOutputEndRef = useRef<HTMLSpanElement | null>(null)
    const [textColor, setTextColor] = useState('#ffffff')

    useImperativeHandle(ref, () => ({
        // ERROR OUTPUT
        // setOutput: (msg, isError = false) => {
        //   const styledMsg = isError ? `<span style="color: red;">${msg}</span>` : msg
        //   setCommandOutput((commandOutput) => commandOutput + styledMsg)
        // },
        setOutput: (msg: string) => setCommandOutput((commandOutput) => commandOutput + msg),
    }))
    if (commandOutput.search('FAILED') > -1 && textColor != '#ff0000') {
        setTextColor('#ff0000')
    }

    // Handlers
    const scrollToBottom = () => {
        commandOutputEndRef.current?.scrollIntoView({ behavior: 'smooth' })
    }

    // Effects
    useEffect(() => {
        scrollToBottom()
    }, [])

    useEffect(() => {
        scrollToBottom()
    }, [commandOutput])

    return (
        <>
            <Box
                sx={{
                    ...styles.TerminalWindow.Box,
                    minHeight: props.terminalMinHeight || '400px',
                    maxHeight: props.terminalMaxHeight || '400px',
                    overflowY: 'auto',
                }}
            >
                {/* ERROR OUTPUT */}
                {/* <Typography
          sx={{ ...styles.TerminalWindow.Typography }}
          dangerouslySetInnerHTML={{ __html: commandOutput }}
          style={{ color: textColor }}
          ref={commandOutputEndRef}
        /> */}
                <Typography sx={{ ...styles.TerminalWindow.Typography, ...{ color: textColor } }}>
                    {commandOutput}
                    <span ref={commandOutputEndRef} />
                </Typography>
            </Box>
        </>
    )
})
TerminalWindow.displayName = 'TerminalWindow'

interface SystemCommandsManagerProps {
    terminalMinHeight?: string
    terminalMaxHeight?: string
}
export function SystemCommandsManager({ terminalMinHeight, terminalMaxHeight }: SystemCommandsManagerProps) {
    // console.log('[Render SystemCommandsManager]')
    const machineName = useRecoilValue(machineNameAtom)
    if (!machineName) {
        // Handle the case where machineName is null
        return <>Machine name is not available.</>
    }

    const socket = io(`${process.env.REACT_APP_FSS_URL}/${machineName}/cmd`, {
        auth: {
            token: UserService.getToken(),
            client_type: 'ui-client',
        },
    })

    // React State
    const [commandList, setCommandList] = useState<any[]>([])
    const [selectedCommand, setSelectedCommand] = useState('beep')
    const [commandRunning, setCommandRunning] = useState(false)
    const terminalWindowRef = useRef<TerminalWindowRef | null>(null)

    // Handlers
    const handleSendCommand = (command: SystemCommandRequest) => {
        console.log(`Sending command: ${command['name']} args: ${command['args']}`)
        terminalWindowRef.current?.setOutput(`[${command['name']}]: Running...\n`)
        socket.emit('system_command', command)
    }
    const handleChangeSelectedCommand = (event: ChangeEvent<HTMLSelectElement>) => {
        setSelectedCommand(event.target.value)
    }

    useEffect(() => {
        socket.on('CONNECTED', (c) => setCommandList(c['commands']))
        socket.on('UNAUTHORIZED', () => UserService.doLogout())

        socket.on('SC_STRM', (msg) => {
            if (msg['count'] === 0) {
                setCommandRunning(true)
            }
            terminalWindowRef.current?.setOutput(msg['data'])
            if (msg['count'] === -1) {
                terminalWindowRef.current?.setOutput(`[${msg['name']}]: Finished\n\n`)
                setCommandRunning(false)
            }
        })
        return () => {
            socket.off('CONNECTED')
            terminalWindowRef.current?.setOutput('')
            socket.off('SC_STRM')
            socket.close()
        }
    }, [])

    return (
        <>
            {commandList.length > 0 && (
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'no-wrap',
                        }}
                    >
                        <SelectBox
                            inputLabel='Select a command'
                            value={selectedCommand}
                            options={commandList}
                            width={'100%'}
                            disabled={false}
                            handleChange={handleChangeSelectedCommand}
                        />
                        <Button
                            variant='contained'
                            sx={{ m: 1 }}
                            disabled={commandRunning || selectedCommand === null}
                            onClick={() => {
                                if (selectedCommand) {
                                    handleSendCommand({
                                        name: selectedCommand,
                                        args: [machineName],
                                    })
                                }
                            }}
                            startIcon={<KeyboardArrowRightIcon />}
                        >
                            Run
                        </Button>
                    </Box>
                    <TerminalWindow
                        terminalMinHeight={terminalMinHeight}
                        terminalMaxHeight={terminalMaxHeight}
                        ref={terminalWindowRef}
                    />
                </>
            )}
        </>
    )
}

const SystemCommands = () => {
    const navigate = useNavigate()
    const handleClose = () => navigate(location.pathname.replace('/system-commands', ''))
    return (
        <ModalBox title={'System Commands'} open={true} handleDialogClose={handleClose}>
            <SystemCommandsManager />
        </ModalBox>
    )
}
export { SystemCommands }
